export const CONVERSATION_COMPLEXITY_LIST = [
  "",
  "Beginner",
  "Intermediate",
  "Advanced",
];

export const CONVERSATION_RELEVANCE_LIST = ["", "Low", "Medium", "High"];

export const DEFAULT_TAGS_LIST = [
  "Retirement",
  "Strategy",
  "Income",
  "Education",
];

export const LIST_COMPANY_TYPE = [
  {
    label: "LevelWealth",
    value: "LevelWealth",
  },
  {
    label: "Pro Portal",
    value: "Pro Portal",
  },
  {
    label: "Affiliate",
    value: "Affiliate",
  },
  {
    label: "Pro Portal/Affiliate",
    value: "Pro Portal/Affiliate",
  },
];
