import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import State from "../../../../context/user/State";
import dayjs from "dayjs";
import { Button, Flex } from "antd";

import { NewsCard } from "./style";

import { ReactComponent as BlueArrow } from "../../../../images/blue_arrow_down.svg";

const PageNewsList = () => {
  const navigate = useNavigate();
  const [state] = useContext(State);

  const handleClose = () => navigate("/mmi/dashboard");

  return (
    <Flex gap={16} vertical>
      <Button
        block
        onClick={handleClose}
        shape="round"
        style={{
          fontSize: 16,
          background: "#001141",
          color: "#4EABE9",
        }}
      >
        <Flex align="center" gap={6} justify="center">
          <BlueArrow style={{ rotate: "90deg" }} />
          Back to Dashboard
        </Flex>
      </Button>
      <Flex gap={20} vertical>
        {state.newsList?.map(it => (
          <NewsCard key={it.id} vertical>
            <h5 className="title">{it.title}</h5>
            <p className="date">
              {dayjs(it.published).format("MMM DD, YYYY h:mma")}
            </p>
            <p className="description">{it.summary}</p>
            <p className="source">Source: {it.sourceName}</p>
            <Button
              className="button"
              onClick={() => window.open(it.url, "_target")}
              type="text"
            >
              Read More
            </Button>
          </NewsCard>
        ))}
      </Flex>
    </Flex>
  );
};

export default PageNewsList;
