import { useContext } from "react";
import State from "../../../../context";
import ModalMilestonesNote from "./components/ModalMilestonesNote";
import SectionMilestonesQuestionnaire from "./components/SectionMilestonesQuestionnaire";
import SectionPerspectiveCoupleResults from "./components/SectionPerspectiveCoupleResults";
import SectionPerspectiveIndividualResults from "./components/SectionPerspectiveIndividualResults";
import SectionPrioritiesCoupleResults from "./components/SectionPrioritiesCoupleResults";
import SectionWorkspace from "./components/SectionWorkspace";

import StylesContainer from "./style";

const PageProposalDashboard = () => {
  const [state] = useContext(State);

  const handleNoteSave = noteCopy => {
    state.setKeyValue("openModalMilestonesNote", false);
    state.setKeyValue("loading", true);

    state
      .setPreferenceValue("milestonesNote", noteCopy)
      .then(() => state.showSuccess("Note updated"))
      .catch(error => state.showError(error))
      .finally(() => state.setKeyValue("loading", false));
  };

  const closeMilestonesView = () => {
    state.setKeyValue("viewMilestonesQuestionnaire", false);
    state.setKeyValue("showButtonBackToDashboard", false);
  };

  const handleAddNoteButton = () => {
    state.setKeyValue("openModalMilestonesNote", true);
  };

  const saveMilestonesQuestions = milestonesQuestions => {
    state.setKeyValue("loading", true);

    state
      .setPreferenceValue("milestonesQuestions", milestonesQuestions)
      .then(() => {
        state.showSuccess("Milestones has been saved.");
        closeMilestonesView();
      })
      .catch(error => state.showError(error))
      .finally(() => state.setKeyValue("loading", false));
  };

  return (
    <StylesContainer>
      {state.viewPerspectiveIndividualResults ? (
        <SectionPerspectiveIndividualResults />
      ) : state.viewPerspectiveCoupleResults ? (
        <SectionPerspectiveCoupleResults />
      ) : state.viewPrioritiesCoupleResults ? (
        <SectionPrioritiesCoupleResults />
      ) : state.viewMilestonesQuestionnaire ? (
        <SectionMilestonesQuestionnaire
          closeMilestonesView={closeMilestonesView}
          handleAddNoteButton={handleAddNoteButton}
          saveMilestonesQuestions={saveMilestonesQuestions}
          state={state}
        />
      ) : (
        <SectionWorkspace />
      )}

      <ModalMilestonesNote
        loading={state.loading}
        markdown={state.getPreferenceValue("milestonesNote")}
        open={state.openModalMilestonesNote}
        onCancel={() => state.setKeyValue("openModalMilestonesNote", false)}
        onSuccess={handleNoteSave}
      />
    </StylesContainer>
  );
};

export default PageProposalDashboard;
