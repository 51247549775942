import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  &.ant-modal {
    .ant-modal-header {
      margin-bottom: 32px;
    }

    .ant-modal-content {
      padding: 32px;
    }

    .ant-modal-footer {
      margin-top: 32px;
    }

    .container-primary-contact {
      background: #fafafa;
      border: 1px solid #c9c8c8;
      border-radius: 12px;
      margin-bottom: 24px;
      padding: 24px;

      h5 {
        color: #1e1919;
        font-size: 14px;
        margin: 0 0 17px;
      }
    }

    .ant-form-item {
      width: 100%;
    }

    .ant-input {
      height: 35px;
    }

    .ant-input-number {
      width: 100%;
    }

    .ant-form-vertical
      .ant-form-item:not(.ant-form-item-horizontal)
      .ant-form-item-label {
      padding-bottom: 2px;
    }
  }
`;

export default StyledModal;
