import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  header {
    display: none;
  }

  main {
    padding: unset;
  }

  &.ant-modal {
    .ant-modal-header {
      margin-bottom: 18px;
    }

    .ant-modal-title {
      color: #052c2b;
      font-family: Inter, sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

    .ant-modal-body {
      height: 75vh;
      overflow: auto;
    }

    .ant-modal-content {
      padding: 24px;
    }
  }
`;

export default StyledModal;
