import { useEffect, useState } from "react";
import { Button, Col, Flex, Row, Space } from "antd";
import CardProduct from "../../../../molecule/CardProduct";
import FiltersContainer from "./components/FiltersContainer";

import { AllProductsContainer } from "./style";

const AstorProductsDashboard = ({
  comparedProducts,
  handleCardClick,
  productActions,
  productsList,
  setCompareMode,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const [riskToleranceFilter, setRiskToleranceFilter] = useState([]);

  const [productFiltersApplied, setProductFiltersApplied] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);

  // Set filtersApplied to true if any filters are applied
  useEffect(() => {
    if (searchTerm === "" && riskToleranceFilter.length === 0) {
      setProductFiltersApplied(false);
    } else {
      setProductFiltersApplied(true);
    }
    setFilteredProducts(getFilteredProducts());
  }, [productsList, searchTerm, riskToleranceFilter]);

  const handleRiskToleranceFilterChange = checkedValues => {
    setRiskToleranceFilter(checkedValues);
  };

  const applySearchFilter = products => {
    if (searchTerm === "") return products;
    return products?.filter(
      product =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) || ""
    );
  };

  const applyFilter = (products, key, filters) => {
    if (filters.length === 0) return products;
    return products?.filter(product => filters.includes(product[key]));
  };

  const getFilteredProducts = () =>
    applyFilter(
      applySearchFilter(productsList),
      "riskTolerance",
      riskToleranceFilter
    );

  return (
    !!productsList?.length && (
      <Space direction="vertical" size={20} style={{ width: "100%" }}>
        <Row justify="space-between" gutter={20}>
          <Col xs={24} sm={24} md={24} lg={6}>
            <Row justify="space-between">
              <FiltersContainer
                filteredProducts={filteredProducts}
                handleRiskToleranceFilterChange={
                  handleRiskToleranceFilterChange
                }
                productFiltersApplied={productFiltersApplied}
                productsList={productsList}
                riskToleranceFilter={riskToleranceFilter}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
            </Row>
          </Col>
          <Col flex="auto" xs={24} sm={24} md={24} lg={18}>
            <Flex vertical>
              <Row
                align="bottom"
                justify="space-between"
                style={{
                  borderBottom: "1px solid #7ebaba50",
                  marginBottom: 10,
                  paddingBottom: 8,
                }}
              >
                <span
                  style={{
                    color: "#3e5377",
                    fontSize: 18,
                  }}
                >
                  Filtered Products ({filteredProducts?.length})
                </span>
                <Button
                  disabled={!comparedProducts?.length}
                  onClick={() => setCompareMode(true)}
                  shape="round"
                  size="small"
                  style={{ background: "#DADFEC", color: "#354A5F" }}
                  type="primary"
                >
                  Compare Mode ({comparedProducts?.length} of 3)
                </Button>
              </Row>
              <Row>
                <AllProductsContainer>
                  {filteredProducts?.map(product => (
                    <CardProduct
                      actions={productActions(product)}
                      handleCardClick={handleCardClick}
                      key={`filtered-list-${product._id}`}
                      labels={[product.riskTolerance, product.allocationType]}
                      product={product}
                    />
                  ))}
                </AllProductsContainer>
              </Row>
            </Flex>
          </Col>
        </Row>
      </Space>
    )
  );
};

export default AstorProductsDashboard;
