import styled from "styled-components";

const StylesContainer = styled.div`
  h3 {
    font-size: 19px;
    line-height: 140%;
    margin: 0 0 8px;
  }

  p {
    line-height: 140%;
    margin: 0;
  }
`;

export default StylesContainer;
