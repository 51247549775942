import PageCompanies from "../page/PageCompanies";
import PageConversations from "../page/PageConversations";
import PageLevelProducts from "../../level/pages/PageLevelProducts";
import PageNextAdvisors from "../page/PageNextAdvisors";
import PageNextLeads from "../page/PageNextLeads";

export default [
  {
    path: "/super-admin/next-advisors",
    Component: PageNextAdvisors,
  },
  {
    path: "/super-admin/next-leads",
    Component: PageNextLeads,
  },
  {
    path: "/super-admin/conversations",
    Component: PageConversations,
  },
  {
    path: "/super-admin/companies",
    Component: PageCompanies,
  },
  {
    path: "/super-admin/products",
    Component: PageLevelProducts,
  },
  {
    path: "/super-admin/users",
    Component: () => <div>Users</div>,
  },
];
