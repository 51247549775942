import html2pdf from "html2pdf.js";

export const getPdfConverterOptions = config => ({
  margin: config?.margin ?? 0,
  filename: config?.filename ?? "report.pdf",
  image: { type: "jpeg", quality: 0.98 },
  html2canvas: {
    allowTaint: true,
  },
  pagebreak: { mode: ["avoid-all", "css", "legacy"] },
  // avoid cutting content between pages
  jsPDF: {
    unit: "px",
    format: [1230, 1560],
    // scaled "letter" format
    // sourcecode link: https://github.com/parallax/jsPDF/blob/ddbfc0f0250ca908f8061a72fa057116b7613e78/jspdf.js#L791
    orientation: "portrait",
  },
});

export const getClonedNode = element => {
  if (element) {
    const clonedElement = element.cloneNode(true);
    clonedElement.style.display = "block";
    return clonedElement;
  }
};

export const getHtml2pdfPromiseList = (pageList, pdfConfig) => {
  let pdf = html2pdf();

  pageList.map((pageData, index) => {
    if (pageData.nodeIdCluster?.length) {
      pageData.nodeIdCluster.map((nodeId, nodeClusterIndex) => {
        const pageNode = getClonedNode(document.getElementById(nodeId));

        if (!pageNode) {
          console.log(`Page '${nodeId}' do not added`);
          return;
        }

        if (index === 0 && nodeClusterIndex === 0) {
          pdf = pdf
            .set(getPdfConverterOptions(pdfConfig))
            .from(pageNode)
            .toContainer()
            .toCanvas()
            .toPdf();
        } else {
          pdf = pdf
            .get("pdf")
            .then(pdf => pdf.addPage())
            .set(getPdfConverterOptions(pdfConfig))
            .from(pageNode)
            .toContainer()
            .toCanvas()
            .toPdf();
        }
      });
    } else {
      const pageNode = getClonedNode(document.getElementById(pageData.nodeId));

      if (!pageNode) {
        console.log(`Page '${pageData.nodeId}' do not added`);
        return;
      }

      if (index === 0) {
        pdf = pdf
          .set(getPdfConverterOptions(pdfConfig))
          .from(pageNode)
          .toContainer()
          .toCanvas()
          .toPdf();
      } else {
        pdf = pdf
          .get("pdf")
          .then(pdf => pdf.addPage())
          .set(getPdfConverterOptions(pdfConfig))
          .from(pageNode)
          .toContainer()
          .toCanvas()
          .toPdf();
      }
    }
  });

  return pdf;
};
