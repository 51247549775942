import { useContext, useEffect, useState } from "react";
import State from "../../../../context/admin/State";
import { Button, Flex, Input, Upload } from "antd";
import ModalPrimary from "../ModalPrimary";
import InputContainer from "../../../../../../atom/InputContainer";

import { StyledSwitch } from "./style";

import {
  addSponsor,
  updateSponsor,
  uploadFile,
} from "../../../../requests/global";
import { MAX_FEATURED_SPONSORS_AMOUNT } from "../../../../constants";

import { ReactComponent as UploadIcon } from "../../../../images/upload.svg";

const defaultSponsorData = {
  featured: false,
};

const ModalSponsor = ({ open }) => {
  const [state] = useContext(State);
  const [sponsorData, setSponsorData] = useState({});
  const [loading, setLoading] = useState(false);
  const isEditSponsorView = !!state.activeSponsorId;

  useEffect(() => {
    if (open) {
      if (state.activeSponsorId) {
        setSponsorData(
          state.sponsors.find(it => it._id === state.activeSponsorId)
        );
      } else {
        setSponsorData(defaultSponsorData);
      }
    }
  }, [open]);

  const updateSponsorData = (key, value) =>
    setSponsorData(lastState => ({
      ...lastState,
      [key]: value,
    }));

  const handleClose = () => {
    state.setKeyValue("activeSponsorId", null);
    state.closeModal("sponsorModal");
    setSponsorData({});
  };

  const handleSave = () => {
    if (
      sponsorData.featured &&
      state.sponsors.filter(it => it.featured)?.length >=
        MAX_FEATURED_SPONSORS_AMOUNT
    ) {
      state.showWarning(
        `A maximum of ${MAX_FEATURED_SPONSORS_AMOUNT} sponsors can be featured at once. Please disable a sponsor.`
      );
      return;
    }

    setLoading(true);

    if (isEditSponsorView) {
      if (sponsorData.file) {
        // added new file to the form
        uploadFile({
          file: sponsorData.file,
          folderName: "sponsors",
        }).then(uploadedFile => {
          updateSponsor({
            sponsorId: state.activeSponsorId,
            payloadData: {
              ...sponsorData,
              logo: {
                filename: uploadedFile.filename,
                key: uploadedFile.key,
                url: uploadedFile.url,
              },
            },
          })
            .then(() => {
              state.reFetch.sponsors().then(() => {
                state.showSuccess("Sponsor data updated.");
                handleClose();
              });
            })
            .finally(() => setLoading(false));
        });
      } else {
        updateSponsor({
          sponsorId: state.activeSponsorId,
          payloadData: sponsorData,
        })
          .then(() => {
            state.reFetch.sponsors().then(() => {
              state.showSuccess("Sponsor data updated.");
              handleClose();
            });
          })
          .finally(() => setLoading(false));
      }
    } else {
      uploadFile({
        file: sponsorData.file,
        folderName: "sponsors",
      })
        .then(uploadedFile => {
          addSponsor({
            ...sponsorData,
            logo: {
              filename: uploadedFile.filename,
              key: uploadedFile.key,
              url: uploadedFile.url,
            },
          })
            .then(() => {
              state.reFetch.sponsors().then(() => {
                state.showSuccess("Sponsor added.");
                handleClose();
              });
            })
            .catch(error => {
              setLoading(false);
              state.showWarning(
                (error?.response?.data?.error ?? [])[0].message
              );
            });
        })
        .catch(error => {
          state.showWarning(error?.response?.data?.message);
          //TODO remove uploaded file after 'add new sponsor' error
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <ModalPrimary
      destroyOnClose={true}
      footer={
        <Flex justify="space-between">
          <Button onClick={handleClose} shape="round" size="large" type="text">
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={handleSave}
            shape="round"
            size="large"
            style={{ width: 134, background: "#001141", color: "#FFFFFF" }}
          >
            Save
          </Button>
        </Flex>
      }
      onCancel={handleClose}
      open={open}
      title={isEditSponsorView ? "Edit Sponsor" : "Add New Sponsor"}
      width={492}
    >
      <Flex gap={16} vertical>
        <Flex gap={16}>
          <InputContainer labelColor="#8396A6" labelText="Sponsor Name">
            <Input
              onChange={e => updateSponsorData("name", e.target.value)}
              value={sponsorData.name}
            />
          </InputContainer>
          <InputContainer labelColor="#8396A6" labelText="URL">
            <Input
              onChange={e => updateSponsorData("url", e.target.value)}
              value={sponsorData.url}
            />
          </InputContainer>
        </Flex>
        <Flex gap={16}>
          <InputContainer labelColor="#8396A6" labelText="Category">
            <Input
              onChange={e => updateSponsorData("category", e.target.value)}
              value={sponsorData.category}
            />
          </InputContainer>
          <InputContainer labelColor="#8396A6" labelText="MMI Referral">
            <Input
              onChange={e => updateSponsorData("referral", e.target.value)}
              value={sponsorData.referral}
            />
          </InputContainer>
        </Flex>
        <InputContainer labelColor="#8396A6" labelText="Featured">
          <StyledSwitch
            size="small"
            onChange={checked => updateSponsorData("featured", checked)}
            checked={sponsorData.featured}
          />
        </InputContainer>
        <InputContainer labelColor="#8396A6" labelText="Upload Logo">
          <Upload.Dragger
            name="file"
            maxCount={1}
            beforeUpload={() => false}
            onChange={file => updateSponsorData("file", file)}
            onRemove={() => updateSponsorData("file", null)}
            style={{ paddingLeft: 30 }}
          >
            <Flex
              align="center"
              justify="center"
              gap={15}
              style={{ height: 70 }}
            >
              <span className="ant-upload-drag-icon">
                <UploadIcon />
              </span>
              <span className="ant-upload-text" style={{ color: "#105DAE" }}>
                Drag here or <u>Browse</u>
              </span>
            </Flex>
          </Upload.Dragger>
        </InputContainer>
      </Flex>
    </ModalPrimary>
  );
};

export default ModalSponsor;
