import dayjs from "dayjs";
import { Button, Flex } from "antd";

import StylesContainer from "./style";

import { openProposal } from "../../../../../../../../utils/request/manager";
import {
  getLastSavedProposalProps,
  isGrowthTypeRiskTolerance,
} from "../../../../../../../../utils/helper/specialized";
import { getCurrencyValueWithRoundSign } from "../../../../../../../../utils/helper/general";

const RecentClientCard = ({ organization, userData }) => {
  const latestGoalData = getLastSavedProposalProps(
    userData?.valueMap?.productMap
  );

  const getDetails = () => {
    if (isGrowthTypeRiskTolerance(latestGoalData?.investmentObjective)) {
      return `${getCurrencyValueWithRoundSign(
        latestGoalData.calculated?.endingInvestmentBalance
      )} over ${
        latestGoalData.lengthOfInvestment ?? latestGoalData.investmentDuration
      } yrs`;
    } else if (latestGoalData?.calculated?.withdrawalAmount) {
      return getCurrencyValueWithRoundSign(
        latestGoalData.calculated.withdrawalAmount
      );
    }
  };

  const handleViewProposal = () => {
    openProposal({
      userEmail: userData?.email,
      organization,
    });
  };

  return (
    <StylesContainer gap={26} justify="space-between" wrap>
      <Flex gap={60}>
        <Flex gap={11} vertical>
          <div className="data-container">
            <span className="label">Client</span>
            <p className="value">
              {userData?.firstName} {userData?.lastName}
            </p>
          </div>
          <div className="data-container">
            <span className="label">Details</span>
            <p className="value">{getDetails()}</p>
          </div>
        </Flex>

        <Flex gap={11} vertical>
          <div className="data-container">
            <span className="label">Saved</span>
            <p className="value">
              {latestGoalData?.timestamp &&
                dayjs(latestGoalData.timestamp).format("MMM D, YYYY")}
            </p>
          </div>
          <div className="data-container">
            <span className="label">Product</span>
            <p className="value">
              {latestGoalData?.productName ?? "Nothing selected"}
            </p>
          </div>
        </Flex>
      </Flex>

      <Button onClick={handleViewProposal} shape="round" size="small">
        View Proposal
      </Button>
    </StylesContainer>
  );
};

export default RecentClientCard;
