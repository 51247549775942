import logo_oneascent from "../image/logo.svg";

export default {
  chart_colors: ["#4E96D1", "#1A2849", "#64C981", "#CCB681", "#00BDC4"],
  colorPrimary: "#009BDE",
  logo_src: logo_oneascent,
  modal_financial_product_details_header_background: "#E7EBEE",
  page_login_button_submit_background: "#192849",
  proposal_copy_investment_amount: "Initial Investment",
  proposal_copy_length_of_investment: "Length of Investment",
  proposal_header_background:
    "radial-gradient(41.33% 85.87% at 81.47% 36.73%, #1799D5 0%, #E5F2F9 100%)",
  proposal_header_padding: "30px 52px 30px 43px",
  sidebar_background: "linear-gradient(180deg, #15284B 0, #009BDE 100%)",
  sidebar_icon_color: "#fff",
  sidebar_icon_layout_vertical: true,
  sidebar_menu_item_font_size: "12px",
  sidebar_menu_item_hover_background: "#152A4E",
  sidebar_menu_item_padding: "9px",
  sidebar_width: 100,
  tenant_color_primary: "#009BDE",
};
