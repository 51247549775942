import { useEffect } from "react";
import { Button, Checkbox, Form, Input } from "antd";

import StylesContainer from "./style";

import { ReactComponent as ArrowRight } from "./images/icon_arrow_right.svg";

const showHubspotForm = true;

const TabAskAdvisor = ({ hubspotFormId, isModalView, onSubmit }) => {
  const [form] = Form.useForm();

  const formId = isModalView ? "formModalAskAnAdvisor" : "formAskAnAdvisor";

  useEffect(() => {
    if (showHubspotForm) {
      if (isModalView) {
        hbspt.forms.create({
          region: "na1",
          portalId: "22416949",
          formId: hubspotFormId,
          target: "#hubspotFormModalAskAnAdvisorContainer",
        });
      } else {
        hbspt.forms.create({
          region: "na1",
          portalId: "22416949",
          formId: hubspotFormId,
          target: "#hubspotFormAskAnAdvisorContainer",
        });
      }
    }
  }, [isModalView]);

  return showHubspotForm ? (
    isModalView ? (
      <div
        id="hubspotFormModalAskAnAdvisorContainer"
        style={{ overflow: "auto" }}
      />
    ) : (
      <div id="hubspotFormAskAnAdvisorContainer" style={{ overflow: "auto" }} />
    )
  ) : (
    <StylesContainer>
      <header>
        <h3 className="title">Ask an advisor...</h3>
        <p className="description">
          Within 48 hours we will have a licensed professional answer your
          question. No obligation, no strings attached.
        </p>
      </header>
      <Form id={formId} form={form} onFinish={onSubmit}>
        <Form.Item name="questionBody">
          <Input.TextArea
            placeholder="Type your question..."
            style={{ height: 245 }}
          />
        </Form.Item>
        <Form.Item name="giveAdvisorAccess" valuePropName="checked">
          <Checkbox>
            Giving the answering advisor access to your information lets them
            answer your question more accurately for your financial situation
            and give you the chance to accept their suggestions with one click.
          </Checkbox>
        </Form.Item>
      </Form>
      <footer>
        <Button
          block
          form={formId}
          htmlType="submit"
          shape="round"
          size="large"
          style={{ fontSize: 14 }}
          type="primary"
        >
          Send to Advisor <ArrowRight />
        </Button>
      </footer>
    </StylesContainer>
  );
};

export default TabAskAdvisor;
