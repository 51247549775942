import { Button, Flex, Image } from "antd";

import StylesContainer from "./style";

import demo from "./image/demo.svg";
import instagram from "./image/instagram.png";

const SectionSocial = () => {
  const handleButtonClick = () => {
    window.open("https://www.instagram.com/ngi_solutions/", "_blank");
  };

  return (
    <StylesContainer gap={24} vertical>
      <Flex gap={34}>
        <Image preview={false} src={demo} />
        <Flex flex="178px" gap={4} vertical>
          <h4>NGI on social</h4>
          <p>Visit our Instagram account for financial tips & insights.</p>
        </Flex>
      </Flex>
      <Flex justify="center">
        <Button
          icon={<Image preview={false} src={instagram} />}
          onClick={handleButtonClick}
          shape="round"
          type="primary"
        >
          @ngi_solutions
        </Button>
      </Flex>
    </StylesContainer>
  );
};

export default SectionSocial;
