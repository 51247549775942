import LogoRivershares from "../images/rivershares_logo_dark.svg";
import RiversharesNavBarMask from "../images/mask.svg";

export default {
  admin_pie_chart_color_primary: "#F36835",
  admin_pie_chart_color_secondary: "#BEC8CE",
  admin_pie_chart_color_tertiary: "#354A5F",
  admin_pie_chart_color_rest: "#72C0C7",
  chart_colors: ["#4E96D1", "#1A2849", "#64C981", "#CCB681", "#00BDC4"],
  color_admin_analytic_value: "#131523",
  colorPrimary: "#4897D1",
  button_primary_background: "#428A16",
  color_secondary_button: "#4897D1",
  color_secondary_button_text: "#FFFFFF",
  color_tertiary_button: "#E7EBEE",
  color_tertiary_button_text: "#516474",
  comparison_products_colors: ["#E48361", "#83C390", "#679BCE"],
  logo_src: LogoRivershares,
  proposal_guide_font_primary: "Barlow, sans-serif",
  sidebar_background: `url(${RiversharesNavBarMask}) #006395 right`,
  sidebar_icon_color: "#BEC8CE",
  tenant_color_primary: "#4897D1",
};
