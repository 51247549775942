import { Flex, Image, Typography } from "antd";

import StylesContainer from "./style";

const CardSponsor = ({ sponsorData }) => (
  <StylesContainer justify="space-between">
    <div>
      {sponsorData.logo?.url && (
        <Image
          alt={sponsorData.name}
          preview={false}
          src={sponsorData.logo.url}
          width={100}
        />
      )}
    </div>
    <Flex gap={8} style={{ width: 150 }} vertical>
      <b style={{ fontSize: 16, color: "#FFFFFF" }}>{sponsorData.name}</b>
      <Typography.Link
        href={sponsorData.url}
        target="_blank"
        style={{
          lineHeight: "16px",
          fontSize: 16,
          color: "#4EABE9",
          textDecoration: "underline",
        }}
      >
        {sponsorData.url?.replace("https://", "")}
      </Typography.Link>
      <Flex vertical>
        <span className="label">MMI REFERRAL</span>
        <span className="value">{sponsorData.referral}</span>
      </Flex>
      <Flex vertical>
        <span className="label">CATEGORY</span>
        <span className="value">{sponsorData.category}</span>
      </Flex>
    </Flex>
  </StylesContainer>
);

export default CardSponsor;
