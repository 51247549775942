import logo_src from "../image/logo_clevercx.svg";

export default {
  color_black: "#1E1919",
  colorPrimary: "#215054",
  logo_src,
  sidebar_background: "#D5E1E2",
  sidebar_menu_item_hover_background: "#ffffff60",
  sidebar_width: 200,
  sidebar_profile_color: "#052C2B",
};
