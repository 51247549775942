import { lazy } from "react";

const PageNotFound = lazy(() => import("../../../page/PageNotFound"));
const TemplatePic = lazy(() => import("../template/TemplatePic"));
const TemplateRila = lazy(() => import("../template/TemplateRila"));

export default {
  "/": TemplateRila,
  "/pic": TemplatePic,
  "*": PageNotFound,
};
