import styled from "styled-components";

const StylesContainer = styled.div`
  .ant-table-wrapper tr > td.assets-column,
  .ant-table-wrapper tr > th.assets-column {
    background: #6dc1c730;
  }

  a {
    font-weight: 500;
  }
`;

export default StylesContainer;
