import styled from "styled-components";

const StylesContainer = styled.div`
  header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 16px 32px 8px;

    h4 {
      color: #215054;
      font-size: 18px;
      font-weight: 400;
      line-height: 15px;
      margin: unset;
    }

    .ant-btn {
      height: 36px;
    }

    .ant-btn.ant-btn-icon-only.ant-btn-round {
      width: 36px;
    }
  }

  main {
    margin: 0 auto;
    max-width: 1650px;
    overflow: auto;
    padding: 32px 32px 90px;
  }
`;

export default StylesContainer;
