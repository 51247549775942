import dayjs from "dayjs";
import { Button, Table, ConfigProvider } from "antd";

import StyledModal from "./style";

import { getCurrencyValue } from "../../../../../../utils/helper/general";
import { openProposal } from "../../../../../../utils/request/manager";

import Close from "../../../../../../icon/Close";

const ModalLatestProposals = ({
  listLatestProposals,
  onCancel,
  open,
  organizationName,
}) => {
  const columns = [
    {
      dataIndex: "advisorName",
      render: (value, allValues) => (
        <a
          onClick={() =>
            openProposal({
              organization: organizationName,
              userEmail: allValues.email,
              windowName: "adminView",
            })
          }
        >
          {value}
        </a>
      ),
      sorter: (a, b) => a.advisorName.localeCompare(b.advisorName),
      title: "Advisor Name",
    },
    {
      render: allValues =>
        `${allValues?.user?.personalInfo?.firstName} ${allValues?.user?.personalInfo?.lastName}`,
      sorter: (a, b) => {
        const aName = `${a?.user?.personalInfo?.firstName} ${a?.user?.personalInfo?.lastName}`;
        const bName = `${b?.user?.personalInfo?.firstName} ${b?.user?.personalInfo?.lastName}`;

        return aName.localeCompare(bName);
      },
      title: "Client Name",
    },
    {
      render: allValues =>
        allValues.userType === "ConvertedProspect" ||
        allValues.userType === "Registered"
          ? "Client"
          : "Prospect",
      sorter: (a, b) => {
        const aUserType =
          a.userType === "ConvertedProspect" || a.userType === "Registered"
            ? "Client"
            : "Prospect";

        const bUserType =
          a.userType === "ConvertedProspect" ||
          allValues.userType === "Registered"
            ? "Client"
            : "Prospect";

        return aUserType.localeCompare(bUserType);
      },
      title: "Client or Prospect",
    },
    {
      render: allValues => allValues.latestProposalData?.productName,
      sorter: (a, b) => {
        const aProduct = a.latestProposalData?.productName;
        const bProduct = b.latestProposalData?.productName;

        return aProduct?.localeCompare(bProduct);
      },
      title: "Product",
    },
    {
      className: "cell-proposed-aum",
      render: allValues =>
        getCurrencyValue(allValues.latestProposalData?.investmentAmount),
      sorter: (a, b) => {
        const aProposalAum = a.latestProposalData?.investmentAmount;
        const bProposalAum = b.latestProposalData?.investmentAmount;

        return aProposalAum - bProposalAum;
      },
      title: "Proposal AUM",
    },
    {
      render: allValues =>
        dayjs(allValues.latestProposalData?.timestamp).format("MM/DD/YYYY"),
      sorter: (a, b) => {
        const aProposalCreated = a.latestProposalData?.timestamp;
        const bProposalCreated = b.latestProposalData?.timestamp;

        return dayjs(aProposalCreated) - dayjs(bProposalCreated);
      },
      title: "Proposal Created",
    },
  ];

  return (
    <StyledModal
      closeIcon={<Close />}
      footer={
        <Button onClick={onCancel} shape="round" type="text">
          Close
        </Button>
      }
      onCancel={onCancel}
      open={open}
      title={
        <div>
          Latest Proposals <span style={{ fontSize: 14 }}>(Past 7 days)</span>
        </div>
      }
      width={1214}
    >
      <ConfigProvider
        theme={{
          components: {
            Table: {
              borderColor: "#BEC8CE",
              colorText: "#516474",
              headerBg: "#fff",
              headerColor: "#8093A3",
              headerSplitColor: "transparent",
            },
          },
        }}
      >
        <Table
          columns={columns}
          dataSource={listLatestProposals}
          pagination={false}
          rowHoverable={false}
          scroll={{ y: "50vh" }}
        />
      </ConfigProvider>
    </StyledModal>
  );
};

export default ModalLatestProposals;
