import styled from "styled-components";

const StyledContainer = styled.div`
  .ant-menu {
    display: flex;
    flex-direction: column;
    gap: 48px;

    &::before {
      display: none;
    }

    .ant-menu-item {
      align-items: center;
      display: flex;
      height: 51px;
      justify-content: center;
      margin: 0;
      width: 55px;

      .ant-menu-title-content {
        display: flex;
      }
    }
  }
`;

export default StyledContainer;
