import styled from "styled-components";

const StyledContainer = styled.div`
  .container-analytic-value {
    p {
      color: #516474;
      margin: unset;
    }

    h5 {
      color: #354a5f;
      font-size: 22px;
      margin: unset;
    }
  }

  .cell-proposed-aum,
  .ant-table-wrapper .ant-table-thead > tr > .cell-proposed-aum {
    background: #6dc1c730;
  }

  .container-filter-by {
    color: #354a5f;
    cursor: pointer;
    width: 160px;
  }
`;

export default StyledContainer;
