import styled from "styled-components";

const StyledContainer = styled.article`
  border-top: 1px solid #ebebeb;
  padding: 16px 24px;

  h4 {
    color: #4b4747;
    font-size: 18px;
    line-height: 140%;
    margin: 0;
  }

  ol {
    padding-left: 16px;
  }

  p {
    color: #4b4747;
    line-height: 140%;
    margin: 0;
  }

  .meta-label {
    color: #4b4747;
    font-size: 12px;
  }
`;

export default StyledContainer;
