import { useContext, useEffect, useState } from "react";
import State from "../../../../context";
import { Button, Flex, Input, Table, Tag, theme } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FiltersContainer from "./components/FiltersContainer";
import ModalLevelProductDetails from "../../components/ModalLevelProductDetails";
import SecurityGate from "../../../../provider/SecurityGate";

import StylesContainer from "./style";

import {
  DEFAULT_FILTER_ASSET_MANAGERS_LEVEL,
  FILTER_TAG_COLOR_LIST,
} from "../../constants";

import { getPercentValue } from "../../../../utils/helper/general";
import {
  addToFavoriteFinancialProducts,
  removeFromFavoriteFinancialProducts,
} from "../../../../utils/request/manager";
import { getEtfAll } from "../../request";

import { SearchOutlined } from "@ant-design/icons";
import { faBookmark as faBookmarkSolid } from "@fortawesome/free-solid-svg-icons";
import { faBookmark as faBookmarkRegular } from "@fortawesome/free-regular-svg-icons";
import { ReactComponent as ActionMenu } from "./images/action_menu.svg";

const PageLevelProducts = ({ handleActionClick, proposalData }) => {
  const { token } = theme.useToken();
  const [state] = useContext(State);
  const [searchTerm, setSearchTerm] = useState("");
  const [assetManagerFilters, setAssetManagerFilters] = useState(
    DEFAULT_FILTER_ASSET_MANAGERS_LEVEL
  );
  const [customFilters, setCustomFilters] = useState([]);
  const [typeCategoryFilters, setTypeCategoryFilters] = useState([]);
  const [broadCategoryFilters, setBroadCategoryFilters] = useState([]);
  const [globalCategoryFilters, setGlobalCategoryFilters] = useState([]);
  const [productFiltersApplied, setProductFiltersApplied] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const productsList = state.productsList?.map(it => ({
    ...it,
    customFilter: state.favoriteFinancialProducts?.includes(it._id) // flag for custom filter
      ? "My Favorites"
      : null,
  }));

  useEffect(() => {
    if (state.productsList?.length && !state.fetchedEtf) {
      state.setKeyValue("fetchedEtf", true);
      state.setKeyValue("loading", true);

      getEtfAll()
        .then(
          data =>
            data?.length &&
            state.setKeyValue("productsList", [
              ...state.productsList,
              ...data
                .filter(it => it.Code !== "XMV")
                .map(it => ({
                  ...it,
                  assetManager: it.CompanyName,
                  broadCategory: it.AssetClass,
                  globalCategory: it.Category,
                  name: it.Name,
                  ticker: it.Code,
                  type: "ETF",
                })),
            ])
        )
        .catch(state.showError)
        .finally(() => state.setKeyValue("loading", false));
    }
  }, [state.productsList]);

  // Set filtersApplied to true if any filters are applied
  useEffect(() => {
    if (
      searchTerm === "" &&
      customFilters.length === 0 &&
      typeCategoryFilters.length === 0 &&
      assetManagerFilters.length === 0 &&
      broadCategoryFilters.length === 0 &&
      globalCategoryFilters.length === 0
    ) {
      setProductFiltersApplied(false);
    } else {
      setProductFiltersApplied(true);
    }
    setFilteredProducts(getFilteredProducts());
  }, [
    state.productsList,
    searchTerm,
    typeCategoryFilters,
    assetManagerFilters,
    broadCategoryFilters,
    globalCategoryFilters,
    customFilters,
  ]);

  const handleAssetManagerFilterChange = checkedValues => {
    setAssetManagerFilters(checkedValues);
  };

  const handleCustomFilterChange = checkedValues => {
    setCustomFilters(checkedValues);
  };

  const handleBroadCategoryFilterChange = checkedValues => {
    setBroadCategoryFilters(checkedValues);
  };

  const handleGlobalCategoryFilterChange = checkedValues => {
    setGlobalCategoryFilters(checkedValues);
  };

  const handleTypeCategoryFilterChange = checkedValues => {
    setTypeCategoryFilters(checkedValues);
  };

  const handleCardClick = cardId => {
    state.setKeyValue("productDetailsId", cardId);
    state.openModal("productDetailsModal");
  };

  const handleToggleFavorite = productId => {
    if (state.favoriteFinancialProducts?.includes(productId)) {
      removeFromFavoriteFinancialProducts(productId)
        .then(() =>
          state.setAdminData().then(adminData => {
            !adminData.favoriteFinancialProducts &&
              state.setKeyValue("favoriteFinancialProducts", []);
            state.showSuccess("Product removed from Favorite");
          })
        )
        .catch(state.showError);
    } else {
      addToFavoriteFinancialProducts(productId)
        .then(() =>
          state
            .setAdminData()
            .then(() => state.showSuccess("Product marked as Favorite"))
        )
        .catch(state.showError);
    }
  };

  const applySearchFilter = products => {
    if (!searchTerm) return products;

    return products?.filter(
      product =>
        product.name?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        product.ticker?.toLowerCase()?.includes(searchTerm.toLowerCase())
    );
  };

  const applyFilter = (products, key, filters) => {
    if (filters.length === 0) return products;
    return products?.filter(product => filters.includes(product[key]));
  };

  const getFilteredProducts = () =>
    applyFilter(
      applyFilter(
        applyFilter(
          applyFilter(
            applyFilter(
              applySearchFilter(productsList),
              "assetManager",
              assetManagerFilters
            ),
            "broadCategory",
            broadCategoryFilters
          ),
          "globalCategory",
          globalCategoryFilters
        ),
        "type",
        typeCategoryFilters
      ),
      "customFilter",
      customFilters
    );

  const columns = [
    {
      render: allValues => (
        <a onClick={() => handleCardClick(allValues._id)}>
          {allValues.name === "N/A" ? allValues.ticker : allValues.name}
        </a>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
      title: "Holding",
    },
    {
      dataIndex: "ticker",
      sorter: (a, b) => (a.ticker ?? "").localeCompare(b.ticker ?? ""),
      title: "Ticker",
    },
    {
      align: "right",
      render: allValues =>
        getPercentValue(
          allValues.properties?.mcExpectedReturn ??
            Math.round(allValues.Return * 1000) / 10
        ),
      sorter: (a, b) =>
        (a.properties?.mcExpectedReturn ?? a.Return) -
        (b.properties?.mcExpectedReturn ?? b.Return),
      title: "Return",
      width: 120,
    },
    {
      align: "right",
      render: allValues =>
        getPercentValue(
          allValues.properties?.mcVolatilityReturn ??
            Math.round(allValues.RiskNumber * 1000) / 10
        ),
      sorter: (a, b) =>
        (a.properties?.mcVolatilityReturn ?? a.RiskNumber) -
        (b.properties?.mcVolatilityReturn ?? b.RiskNumber),
      title: "Risk Number",
      width: 140,
    },
    {
      dataIndex: "broadCategory",
      render: broadCategory =>
        broadCategory && (
          <Tag
            color={
              FILTER_TAG_COLOR_LIST.find(it => it.label === broadCategory)
                ?.color
            }
            style={{ height: 24 }}
          >
            {broadCategory}
          </Tag>
        ),
      sorter: (a, b) =>
        (a.broadCategory ?? "").localeCompare(b.broadCategory ?? ""),
      title: "Category",
    },
    {
      align: "right",
      render: allValues => (
        <Flex align="center" gap={16} justify="end">
          <Button
            icon={
              <FontAwesomeIcon
                icon={
                  (state.favoriteFinancialProducts ?? []).includes(
                    allValues._id
                  )
                    ? faBookmarkSolid
                    : faBookmarkRegular
                }
              />
            }
            onClick={() =>
              !state.managed && handleToggleFavorite(allValues._id)
            }
            size="small"
            style={{
              cursor: state.managed ? "default" : "pointer",
            }}
            type="text"
          />
          <Button
            disabled={!handleActionClick}
            icon={<ActionMenu />}
            onClick={() => handleActionClick(allValues)}
            shape="circle"
            size="small"
            style={{ height: "fit-content", minHeight: "unset" }}
            type="text"
          />
        </Flex>
      ),
      title: "Action",
      width: 70,
    },
  ];

  return (
    <SecurityGate state={state}>
      <StylesContainer token={token}>
        <header>
          <h4>All Products</h4>
        </header>

        <main>
          {!!productsList?.length && (
            <>
              <FiltersContainer
                customFilters={customFilters}
                productFiltersApplied={productFiltersApplied}
                assetManagerFilters={assetManagerFilters}
                broadCategoryFilters={broadCategoryFilters}
                globalCategoryFilters={globalCategoryFilters}
                typeCategoryFilters={typeCategoryFilters}
                handleCustomFilterChange={handleCustomFilterChange}
                handleAssetManagerFilterChange={handleAssetManagerFilterChange}
                handleBroadCategoryFilterChange={
                  handleBroadCategoryFilterChange
                }
                handleGlobalCategoryFilterChange={
                  handleGlobalCategoryFilterChange
                }
                handleTypeCategoryFilterChange={handleTypeCategoryFilterChange}
                productsList={productsList}
                filteredProducts={filteredProducts}
              />

              <Flex
                gap={16}
                style={{ paddingRight: 10, width: "100%" }}
                vertical
              >
                <Input
                  allowClear
                  onChange={e => setSearchTerm(e.target.value)}
                  placeholder="Search..."
                  size="large"
                  style={{ borderRadius: 20, width: 270 }}
                  suffix={
                    <SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                  }
                  value={searchTerm}
                />

                <Table
                  columns={columns}
                  dataSource={filteredProducts?.map(it => ({
                    ...it,
                    key: it._id,
                  }))}
                  loading={state.loading}
                  pagination={false}
                  size="small"
                />
              </Flex>
            </>
          )}
        </main>

        <ModalLevelProductDetails
          handleClose={() => state.closeModal("productDetailsModal")}
          investmentAssumptions={proposalData ?? state.investmentAssumptions}
          open={state.productDetailsModal}
          product={state.productsList?.find(
            it => it._id === state.productDetailsId
          )}
          showError={state.showError}
        />
      </StylesContainer>
    </SecurityGate>
  );
};

export default PageLevelProducts;
