import { Flex, theme } from "antd";

import StyledMenu from "./style";

import { ReactComponent as GetAdviceIcon } from "./images/get_advice.svg";
import { ReactComponent as PennyIcon } from "./images/penny.svg";

const MenuSidebarSub = ({ state }) => {
  const { token } = theme.useToken();

  const handlePennyButtonClick = () => {
    if (state.getPreferenceValue("acceptedPennyTerms")) {
      state.setKeyValue("openModalAskAdvice", true);
      state.setKeyValue("sectionAskAdviceActiveTab", "penny");
    } else {
      state.setKeyValue("openModalPennyTerms", true);
    }
  };

  const handleGetAdviceButtonClick = () => {
    state.setKeyValue("openModalAskAdvice", true);
    state.setKeyValue("sectionAskAdviceActiveTab", "advisor");
  };

  return (
    <StyledMenu
      items={[
        {
          key: 0,
          label: (
            <Flex
              align="center"
              justify="center"
              onClick={handlePennyButtonClick}
              vertical
            >
              <PennyIcon />
              Penny
            </Flex>
          ),
        },
        {
          key: 1,
          label: (
            <Flex
              align="center"
              justify="center"
              onClick={handleGetAdviceButtonClick}
              vertical
            >
              <GetAdviceIcon />
              Get
              <br />
              Advice
            </Flex>
          ),
        },
      ]}
      token={token}
    />
  );
};

export default MenuSidebarSub;
