import { useContext, useState } from "react";
import dayjs from "dayjs";
import State from "../../../../../../context";
import { Button, Flex, Input, theme } from "antd";
import MenuActions from "../../../../../../atom/MenuActions";
import ModalArchiveManagedUser from "../../../../../../molecule/ModalArchiveManagedUser";
import ModalConvertClient from "../ModalConvertClient";
import ModalVbi from "../../../../../oneascent/component/ModalVbi";

import StyledTable from "./style";

import {
  archiveLead,
  openProposal,
} from "../../../../../../utils/request/manager";
import {
  getManagedUsers,
  handleCreateProposal,
  isAdminView,
  isManagerTypeIpq,
  isOrganizationOneascent,
} from "../../../../../../utils/helper/specialized";
import { getCurrencyValue } from "../../../../../../utils/helper/general";
import { downloadVbiFile } from "../../../../../oneascent/request";
import { getManagedUserAssets } from "../../../../../oneascent/utils";

const getItems = ({
  email,
  handleActionConvertClient,
  handleActionEditClient,
  handleActionNewIpq,
  handleActionVbi,
  handleActionArchiveClientActionClick,
  managerType,
  organization,
}) =>
  [
    {
      key: "editClient",
      label: "Edit Client",
      onClick: () => handleActionEditClient(email),
    },
    {
      key: "convertClient",
      label: isOrganizationOneascent(organization)
        ? "Mark as Prospect"
        : "Move to Lead",
      onClick: () => handleActionConvertClient(email),
    },
    {
      // hidden: !isOrganizationOneascent(organization),
      hidden: true,
      key: "vbi",
      label: "VBI Audit",
      onClick: () => handleActionVbi(email),
    },
    {
      hidden: !isManagerTypeIpq(managerType),
      key: "newIpq",
      label: "New IPQ",
      onClick: () => handleActionNewIpq(email),
    },
    {
      type: "divider",
    },
    {
      key: "archiveClient",
      label: <span style={{ color: "red" }}>Archive</span>,
      onClick: () => handleActionArchiveClientActionClick(email),
    },
  ].filter(it => !it.hidden);

const TableLevelAdvisorClients = () => {
  const { token } = theme.useToken();
  const [state] = useContext(State);
  const [searchedText, setSearchedText] = useState("");
  useState(false);
  const [isConvertModalOpened, setIsConvertModalOpened] = useState(false);

  const handleActionEditClient = email => {
    state.setKeyValue("activeEmail", email);
    state.setKeyValue("openModalLevelClientDetails", true);
  };

  const handleActionNewIpq = email => {
    state.setKeyValue("activeEmail", email);
    state.setKeyValue("newIpqMode", true);
    state.setKeyValue("openModalProposalGuide", true);
  };

  const handleActionVbi = email => {
    state.setKeyValue("activeEmail", email);
    state.setKeyValue("open_modal_vbi", true);
  };

  const handleActionViewIpq = email => {
    state.setKeyValue("activeEmail", email);
    state.setKeyValue("openModalViewIpq", true);
  };

  const handleActionConvertClient = email => {
    state.setKeyValue("activeEmail", email);
    setIsConvertModalOpened(true);
  };

  const handleActionArchiveClient = () => {
    state.closeModal("archiveManagedUserModal");
    state.setKeyValue("loading", true);

    archiveLead({
      email: state.activeEmail,
      userManagerEmail: state._id,
    })
      .then(() =>
        state.setAdminData().then(() => {
          state.setKeyValue("loading", false);
          state.setKeyValue("activeEmail", null);
          state.showSuccess(`Client ${state.activeEmail} archived`);
        })
      )
      .catch(error => {
        state.showError(error);
        state.setKeyValue("loading", false);
      });
  };

  const handleActionArchiveClientActionClick = email => {
    state.setKeyValue("activeEmail", email);
    state.openModal("archiveManagedUserModal");
  };

  const getTableData = () =>
    getManagedUsers(state)?.map(it => ({
      ...it,
      assets: getManagedUserAssets(it),
      clientName: it.lastName + ", " + it.firstName,
      key: it.email,
      proposalSaved: it.proposalSavedDate ?? it.valueMap?.productMap,
    }));

  const columns = [
    {
      dataIndex: "clientName",
      filteredValue: [searchedText],
      key: "clientName",
      onFilter: (value, record) =>
        String(record.clientName).toLowerCase().includes(value.toLowerCase()) ||
        String(record.email).toLowerCase().includes(value.toLowerCase()),
      render: (name, restValues) =>
        restValues.proposalSaved ||
        isOrganizationOneascent(state.organization?.name) ? (
          <a
            onClick={() =>
              openProposal({
                userEmail: restValues.email,
                organization: state.organization?.name,
              })
            }
          >
            {name}
          </a>
        ) : (
          <b>{name}</b>
        ),
      sorter: (a, b) => a.clientName.localeCompare(b.clientName),
      title: "Client Name",
    },
    {
      dataIndex: "email",
      key: "email",
      render: value => <a href={`mailto:${value}`}>{value}</a>,
      sorter: (a, b) => a.email.localeCompare(b.email),
      title: "Email",
    },
    {
      align: "left",
      hidden: !isManagerTypeIpq(state.onboardingQuestionnaireType),
      key: "ipq",
      render: (_, all_values) =>
        all_values.valueMap?.prospectObjective && (
          <a onClick={() => handleActionViewIpq(all_values.email)}>View</a>
        ),
      sorter: (a, b) =>
        !!a.valueMap?.prospectObjective - !!b.valueMap?.prospectObjective,
      title: "IPQ",
    },
    {
      hidden: true,
      key: "vbi",
      render: (_, all_values) => {
        const vbi_status = all_values.vbi?.statusAdvisorView;

        if (vbi_status === "View")
          return (
            <a
              onClick={() =>
                downloadVbiFile({
                  advisor: state._id,
                  client: all_values.email,
                })
              }
            >
              View
            </a>
          );

        return vbi_status;
      },
      sorter: (a, b) =>
        (a.vbi?.statusAdvisorView ?? "")?.localeCompare(
          b.vbi?.statusAdvisorView ?? ""
        ),
      title: "VBI Audit",
    },
    {
      align: "right",
      className: "assets-column",
      dataIndex: "assets",
      key: "assets",
      render: value => getCurrencyValue(value),
      sorter: (a, b) => a.assets - b.assets,
      title: "Assets",
    },
    {
      align: "right",
      key: "updateDateTime",
      render: (_, all_values) =>
        all_values.proposalSaved ? (
          dayjs(all_values.updateDateTime?.$date).format("MM/DD/YYYY")
        ) : (
          <Flex justify="end">
            <Button
              shape="round"
              size="small"
              style={{
                borderColor: token.color_secondary_button,
                color: token.color_secondary_button,
              }}
              onClick={() => handleCreateProposal(all_values.email, state)}
              disabled={isAdminView()}
            >
              Create Proposal
            </Button>
          </Flex>
        ),
      sorter: (a, b) =>
        dayjs(a.updateDateTime?.$date) - dayjs(b.updateDateTime?.$date),
      title: "Last Accessed",
    },
    {
      className: "actions-menu-table-cell",
      key: "actionsMenu",
      render: (_, all_values) => (
        <MenuActions
          items={getItems({
            email: all_values.email,
            handleActionArchiveClientActionClick,
            handleActionConvertClient,
            handleActionEditClient,
            handleActionNewIpq,
            handleActionVbi,
            managerType: state.onboardingQuestionnaireType,
            organization: state.organization?.name,
          })}
        />
      ),
      title: "",
      width: 40,
    },
  ].filter(it => !it.hidden);

  return (
    <>
      <Input
        style={{
          maxWidth: 290,
          height: 37,
          marginBottom: 16,
          borderRadius: 25,
        }}
        placeholder="Search Clients..."
        onChange={event => setSearchedText(event.target.value)}
      />
      <StyledTable
        columns={columns}
        dataSource={getTableData()}
        loading={state.loading}
        pagination={false}
        rowHoverable={false}
      />

      <ModalArchiveManagedUser
        loading={state.loading}
        onCancel={() => state.closeModal("archiveManagedUserModal")}
        onConfirm={handleActionArchiveClient}
        open={state.archiveManagedUserModal}
      />
      <ModalConvertClient
        email={state.activeEmail}
        handleClose={() => setIsConvertModalOpened(false)}
        open={isConvertModalOpened}
      />
      <ModalVbi
        onCancel={() => state.setKeyValue("open_modal_vbi", false)}
        open={state.open_modal_vbi}
      />
    </>
  );
};

export default TableLevelAdvisorClients;
