import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import State from "../../../../context";
import {
  Button,
  Divider,
  Flex,
  Form,
  InputNumber,
  Progress,
  Radio,
  Select,
  theme,
} from "antd";
import BreadcrumbProposalStep from "../BreadcrumbProposalStep";

import StylesContainer from "./style";

import { PROPOSAL_DEFAULT_DATA_LEVEL } from "../../constants";
import { LIST_STEP_DATA } from "./constants";

import {
  cleanCurrencyValue,
  getCurrencyValue,
  selectValue,
} from "../../../../utils/helper/general";

import { ReactComponent as IconGrowth } from "./images/icon_growth.svg";
import { ReactComponent as IconIncome } from "./images/icon_income.svg";
import { ReactComponent as IconGrowthIncome } from "./images/icon_growth_income.svg";
import promo from "./images/promo.png";

const QuestionnaireContentLevel = ({
  handleClose,
  handleCreateProposal,
  open,
}) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const [state] = useContext(State);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const isSmallScreen = useMediaQuery({ maxWidth: token.screenMDMax });

  const investmentObjective = Form.useWatch("investmentObjective", form);

  const activeUserData = state.managedUsers.find(
    it => it.email === state.activeEmail
  );

  const { title, description } = LIST_STEP_DATA[currentStepIndex];

  useEffect(() => {
    if (open) {
      setCurrentStepIndex(0);
      form.setFieldsValue(PROPOSAL_DEFAULT_DATA_LEVEL);
    }
  }, [open]);

  const handleSubmit = () => {
    handleCreateProposal({
      ...PROPOSAL_DEFAULT_DATA_LEVEL,
      ...form.getFieldsValue(),
    });
    setCurrentStepIndex(0);
  };

  return (
    <StylesContainer align="center" token={token} vertical>
      <header>
        <Flex align="center" className="copy-container" justify="space-between">
          <h4>
            Proposal:{" "}
            <b>
              {activeUserData?.firstName} {activeUserData?.lastName}
            </b>
          </h4>

          <BreadcrumbProposalStep activeStep="start" />

          <Button onClick={handleClose} shape="round">
            Close
          </Button>
        </Flex>

        <Progress
          percent={(100 / LIST_STEP_DATA.length) * (currentStepIndex + 1)}
          showInfo={false}
          strokeColor={{ from: "#E2E86C", to: "#4F8083" }}
          strokeLinecap="square"
          trailColor="#A9C8CA"
        />
      </header>

      <main>
        <Flex gap={121}>
          <Flex vertical>
            <div className="container-form">
              <h2 className="title">{title}</h2>
              <p
                className="description"
                dangerouslySetInnerHTML={{ __html: description }}
              />

              <Form id="levelQuestionnaireForm" form={form} layout="vertical">
                <Form.Item
                  hidden={currentStepIndex !== 0}
                  name="investmentObjective"
                >
                  <Radio.Group className="aim-container">
                    <Flex align="center" gap={24} vertical>
                      <Radio.Button value="growth">
                        <IconGrowth /> Drive Growth
                      </Radio.Button>
                      <Radio.Button value="income">
                        <IconIncome /> Drive Income
                      </Radio.Button>
                      <Radio.Button value="growth_income">
                        <IconGrowthIncome />
                        Growth & Income
                      </Radio.Button>
                    </Flex>
                  </Radio.Group>
                </Form.Item>
                <Flex justify="center">
                  <Form.Item
                    hidden={currentStepIndex !== 1}
                    name="riskTolerance"
                  >
                    <Radio.Group className="risk-container">
                      <Flex gap={20} vertical>
                        <Radio value="Preservation">Preservation</Radio>
                        <Radio value="Conservative">Conservative</Radio>
                        <Radio value="Moderate">Moderate</Radio>
                        <Radio value="Growth">Growth</Radio>
                        <Radio value="Aggressive">Aggressive</Radio>
                        <Flex gap={16} vertical>
                          <Divider />
                          <Radio value="unknown">I don't know</Radio>
                        </Flex>
                      </Flex>
                    </Radio.Group>
                  </Form.Item>
                </Flex>

                <Form.Item
                  hidden={currentStepIndex !== 2}
                  label={token.proposal_copy_investment_amount}
                  name="investmentAmount"
                >
                  <InputNumber
                    formatter={getCurrencyValue}
                    max={100000000}
                    min={0}
                    onClick={selectValue}
                    parser={cleanCurrencyValue}
                    size="large"
                    step={1000}
                  />
                </Form.Item>
                <Form.Item
                  hidden={
                    currentStepIndex !== 2 || investmentObjective === "growth"
                  }
                  label="Year to Start Withdrawals"
                  name="yearToStartWithdrawals"
                >
                  <InputNumber
                    max={80}
                    min={1}
                    onClick={selectValue}
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  hidden={currentStepIndex !== 2}
                  label="Length of Investment"
                  name="investmentDuration"
                >
                  <InputNumber
                    addonAfter={
                      <Form.Item style={{ marginBottom: 0 }}>
                        <Select
                          options={[
                            {
                              label: "Years",
                              value: "years",
                            },
                            {
                              disabled: true,
                              label: "Quarters",
                              value: "quarters",
                            },
                            {
                              disabled: true,
                              label: "Months",
                              value: "months",
                            },
                          ]}
                          style={{ width: 120 }}
                          value="years"
                        />
                      </Form.Item>
                    }
                    max={80}
                    min={10}
                    onClick={selectValue}
                    size="large"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Form>
            </div>

            <footer>
              {currentStepIndex === 0 ? (
                <Button
                  block={isSmallScreen}
                  onClick={handleClose}
                  shape="round"
                  style={{ order: isSmallScreen ? 3 : 1 }}
                  type="text"
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  block={isSmallScreen}
                  onClick={() => setCurrentStepIndex(currentStepIndex - 1)}
                  shape="round"
                  style={{ order: isSmallScreen ? 3 : 1 }}
                  type="text"
                >
                  Previous
                </Button>
              )}

              <Flex gap={12} justify="center" style={{ order: 2 }}>
                {Array(LIST_STEP_DATA.length)
                  .fill(null)
                  .map((_, index) =>
                    currentStepIndex === index ? (
                      <div
                        key={index}
                        style={{
                          background: "#1E1919",
                          borderRadius: "50%",
                          height: 12,
                          width: 12,
                        }}
                      />
                    ) : (
                      <div
                        key={index}
                        style={{
                          background: "#B6B6B6",
                          borderRadius: "50%",
                          boxShadow: "0 2px 1px 0 #000 inset",
                          height: 12,
                          opacity: 0.32,
                          width: 12,
                        }}
                      />
                    )
                  )}
              </Flex>

              {currentStepIndex === 2 ? (
                <Button
                  block={isSmallScreen}
                  disabled={state.loading}
                  onClick={handleSubmit}
                  shape="round"
                  style={{
                    background: "#1E1919",
                    order: isSmallScreen ? 1 : 3,
                  }}
                  type="primary"
                >
                  Let’s Go
                </Button>
              ) : (
                <Button
                  block={isSmallScreen}
                  onClick={() => setCurrentStepIndex(currentStepIndex + 1)}
                  shape="round"
                  style={{
                    background: "#1E1919",
                    order: isSmallScreen ? 1 : 3,
                  }}
                  type="primary"
                >
                  Continue
                </Button>
              )}
            </footer>
          </Flex>

          <img alt="promo" className="image-promo" src={promo} />
        </Flex>
      </main>
    </StylesContainer>
  );
};

export default QuestionnaireContentLevel;
