import logo from "../images/logo.png";
import logo_short from "../images/logo_short.png";
import want_to_talk from "../images/want_to_talk.png";

export default {
  chart_colors: ["#4E96D1", "#1A2849", "#64C981", "#CCB681", "#00BDC4"],
  comparison_products_colors: ["#7908A3", "#0076FF", "#001A45"],
  goals_onboarding_background: "#EBEBEB",
  goals_onboarding_step_divider_line_color: "#0E51A7",
  goals_onboarding_button_continue_background: "#0551A7",
  goals_onboarding_button_continue_color: "#ffffff",
  goals_onboarding_button_continue_border_radius: 30,
  goals_onboarding_section_basic_info_list_marker_background: "#516474",
  goals_onboarding_section_risk_tolerance_button_icon_color: "#FFFFFF",
  goals_onboarding_section_risk_tolerance_button_icon_background: "#437CBD",
  goals_onboarding_section_risk_tolerance_button_color: "#FFFFFF",
  goals_onboarding_section_risk_tolerance_button_conservative_background:
    "#0551A7",
  goals_onboarding_section_risk_tolerance_button_moderate_background: "#0551A7",
  goals_onboarding_section_risk_tolerance_button_aggressive_background:
    "#0551A7",
  goals_onboarding_form_registration_button_submit_background: "#0551A7",
  goals_onboarding_form_registration_button_submit_border_radius: 30,
  goals_page_dash_all_episodes_button_background: "#296469",
  goals_page_dash_create_new_goals_button_background: "#0551A7",
  goals_page_dash_create_new_goals_button_color: "#FFFFFF",
  goals_page_dash_section_want_talk_button_background: "#0551A7",
  goals_page_dash_section_want_talk_button_color: "#FFFFFF",
  goals_page_dash_section_want_talk_button_copy: "Get Advice",
  goals_page_dash_section_want_talk_copy: `You can click the “Get Advice” button any time to get in touch with a financial professional.`,
  goals_page_goal_update_progress_button_background: "#0551A7",
  goals_page_goal_update_progress_button_color: "#FFFFFF",
  icon_goal_type_background: "#0E51A7",
  icon_goal_type_color: "#FFFFFF",
  income_chart_with_types_color_contributions: "#4997D1",
  income_chart_with_types_navbar_button_background: "#FFFFFF",
  income_chart_with_types_navbar_button_color: "#2A2828",
  income_chart_with_types_navbar_container_background: "#D9F3F7",
  logo_src: logo,
  proposal_page_dash_want_to_talk_image_link: want_to_talk,
  proposal_page_dash_want_to_talk_limited_content: "",
  proposal_page_dash_want_to_talk_title_content:
    "Want to talk to a<br/>Bluepoint Advisor?",
  page_login_button_submit_background: "#0551A7",
  page_login_logo: logo,
  penny_send_message_button_background: "#0551A7",
  sidebar_avatar_background: "#FFFFFF",
  sidebar_avatar_color: "#1056AA",
  sidebar_background: "#0D1238",
  sidebar_icon_color: "#9EA0AF",
  sidebar_logo: logo_short,
  tenant_color_primary: "#0551A7",
};
