import styled from "styled-components";
import { Table } from "antd";

const StyledTable = styled(Table)`
  &.ant-table-wrapper tr > td.assets-column,
  &.ant-table-wrapper tr > th.assets-column {
    background: #6dc1c730;
  }

  a {
    font-weight: 500;
  }
`;

export default StyledTable;
