import { Button, Flex } from "antd";

import StyledModal from "./style";

const ModalOptionsInvestmentCard = ({
  handleEditPortfolio,
  handleRemoveFromThisSection,
  handleSelectInvestment,
  handleSelectOtherProduct,
  onCancel,
  open,
  showButtonRemoveFromThisSection,
  showButtonSelectOtherProduct,
}) => (
  <StyledModal
    closeIcon={false}
    footer={
      <Button onClick={onCancel} shape="round">
        Cancel
      </Button>
    }
    onCancel={onCancel}
    open={open}
    title="Options"
    width={288}
  >
    <Flex gap={16} vertical>
      <Button
        block
        disabled={!handleEditPortfolio}
        onClick={handleEditPortfolio}
        shape="round"
      >
        Edit Portfolio
      </Button>

      {showButtonSelectOtherProduct && (
        <Button
          block
          disabled={!handleSelectOtherProduct}
          onClick={handleSelectOtherProduct}
          shape="round"
        >
          Select other product
        </Button>
      )}

      {showButtonRemoveFromThisSection && (
        <Button
          block
          disabled={!handleRemoveFromThisSection}
          onClick={handleRemoveFromThisSection}
          shape="round"
        >
          Remove from this section
        </Button>
      )}

      <Button
        block
        disabled={!handleSelectInvestment}
        onClick={handleSelectInvestment}
        shape="round"
      >
        Select this Investment
      </Button>
    </Flex>
  </StyledModal>
);

export default ModalOptionsInvestmentCard;
