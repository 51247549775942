import { DEFAULT_BIRTH_YEAR } from "../constant";

import {
  convertDecimalIntoDate,
  getCurrencyValue,
  getCurrencyValueWithRoundSign,
} from "../helper/general";

export const getGrowthChartConfig = (
  { birthYear = DEFAULT_BIRTH_YEAR, height = 332 } = {
    birthYear: DEFAULT_BIRTH_YEAR,
    height: 332,
  }
) => {
  return {
    chart: {
      type: "spline",
      height,
      borderWidth: 0,
      spacingTop: 25,
      spacingRight: 25,
      spacingBottom: 24,
      backgroundColor: "transparent",
    },
    legend: false,
    title: {
      text: "",
    },
    credits: false,
    xAxis: {
      lineColor: "transparent",
      tickWidth: 0,
      labels: {
        style: {
          fontSize: 14,
          fontFamily: "Roboto, sans-serif",
          color: "#516474",
        },
        formatter: function () {
          return `'${(this.value + birthYear).toString().slice(2)}`;
        },
      },
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        return `
        <p><b>Date:</b> ${convertDecimalIntoDate(birthYear + this.x)}</p>
        <p><b>${this.series.name}:</b> ${getCurrencyValue(this.y)}</p>
      `;
      },
    },
    yAxis: {
      min: 0,
      opposite: true,
      title: {
        text: null,
      },
      labels: {
        style: {
          fontSize: 14,
          fontFamily: "Roboto, sans-serif",
          color: "#354A5F",
        },
        formatter: data => getCurrencyValueWithRoundSign(data.value),
      },
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
      },
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
  };
};

export const getMcChartConfig = (birthYear = DEFAULT_BIRTH_YEAR) => {
  return {
    chart: {
      height: 250,
    },
    xAxis: {
      lineColor: "transparent",
      tickWidth: 0,
      labels: {
        style: {
          fontSize: 14,
          fontFamily: "Roboto, sans-serif",
          color: "#516474",
        },
        formatter: function () {
          return `'${(this.value + birthYear).toString().slice(2)}`;
        },
      },
    },
    yAxis: {
      opposite: true,
      gridLineColor: "transparent",
      title: {
        text: null,
      },
      labels: {
        style: {
          fontSize: 14,
          fontFamily: "Roboto, sans-serif",
          color: "#354A5F",
        },
        formatter: data => getCurrencyValueWithRoundSign(data.value),
      },
    },
    tooltip: {
      shared: true,
      crosshairs: true,
      useHTML: true,
      formatter: function () {
        let tooltipHtml = `<span style="line-height: 21px"><b>Date:</b> ${convertDecimalIntoDate(
          birthYear + this.x
        )}</span>`;

        this.points.map(
          point =>
            (tooltipHtml += `
              <br>
              <span style="line-height: 21px"><b>${point.series.name}</b></span>
              <span style="line-height: 21px">${getCurrencyValue(
                point.point.z ?? point.point.high ?? point.y
              )}</span>
            `)
        );

        return tooltipHtml;
      },
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
    },
    legend: false,
    title: false,
    credits: false,
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
  };
};

export const getActualAccountBalanceSeries = (calculationsData, series) => {
  if (calculationsData?.gOT?.progressDataForPlotting?.length) {
    if (
      calculationsData.gOT.progressDataForPlotting.length === 1 &&
      calculationsData.gOT.progressDataForPlotting[0][1] === 0
    ) {
      //do not render only zero progress value
    } else {
      series.push({
        name: "Actual account balance",
        lineWidth: 2,
        color: "#1B5568",
        zoneAxis: "x",
        dashStyle: "Line",
        zones: [
          {
            value: 10,
            color: "#1B5568",
          },
        ],
        marker: {
          enabled: calculationsData.gOT.progressDataForPlotting.length === 1,
          symbol: "circle",
          fillColor: "#000",
        },
        dataLabels: {
          enabled: false,
        },
        data: calculationsData.gOT.progressDataForPlotting.sort(
          (a, b) => a[0] - b[0]
        ),
      });
    }
  }
};
