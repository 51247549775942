import { lazy } from "react";

const PageAdmin = lazy(() => import("../pages/PageAdmin"));
const PageAura = lazy(() => import("../pages/PageAuraCalculator"));
const PageAuraDemo = lazy(() => import("../pages/PageAuraDemo"));
const PageNotFound = lazy(() => import("../../../page/PageNotFound"));
const PageWelcome = lazy(() => import("../pages/PageWelcome"));

export default {
  "": PageAura,
  aura: PageAura,
  "aura-demo": PageAuraDemo,
  "halo-admin": PageWelcome,
  "halo-admin/dashboard": PageAdmin,
  "*": PageNotFound,
};
