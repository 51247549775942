import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  background: rgba(255, 255, 255, 0.12);
  border: 1px solid #bbb;
  border-radius: 12px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25) inset;
  height: 240px;
  min-width: 260px;
  width: 260px;
`;

export default StylesContainer;
