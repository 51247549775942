import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  &.ant-modal {
    .ant-modal-content {
      padding: 25px 30px 30px;
    }

    .ant-modal-header {
      margin-bottom: 19px;
    }

    .ant-modal-title {
      font-family: Roboto, sans-serif;
    }

    .ant-modal-body {
      font-family: Roboto, sans-serif;
    }

    .ant-modal-footer {
      margin-top: 23px;
    }
  }

  section {
    background: #4897d126;
    border-radius: 12px;
    padding: 20px 23px;

    h3 {
      color: #516474;
      font-size: 16px;
      line-height: 19px;
      margin: 0 0 2px;
    }

    p {
      color: #516474;
      line-height: 19px;
      margin: 0;
    }
  }

  .ant-upload-wrapper .ant-upload-drag {
    background: #fff;
    border: unset;
    margin-top: 11px;

    .ant-upload-btn {
      padding: 27px 10px 20px;
    }
  }
`;

export default StyledModal;
