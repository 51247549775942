import styled from "styled-components";
import { Button, Modal } from "antd";

const StyledModal = styled(Modal)`
  &.ant-modal {
    .ant-modal-header {
      margin: unset;
      padding: 24px 24px 16px;
    }

    .ant-modal-title {
      font-weight: 700;
    }

    .ant-modal-content {
      max-height: 90vh;
      overflow: auto;
      padding: 0;
    }

    .ant-modal-footer {
      margin: unset;
      padding: 24px;
    }
  }

  .ant-tabs .ant-tabs-ink-bar {
    background: #215054;
    height: 4px;
  }

  .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    margin: 0;
    padding-left: 24px;
  }

  .ant-tabs .ant-tabs-content {
    padding: 24px 24px 0;
  }

  .ant-form-item {
    margin: 0;
  }

  .ant-form-vertical
    .ant-form-item:not(.ant-form-item-horizontal)
    .ant-form-item-label {
    padding-bottom: 0;
  }

  .container-tab-other {
    margin: 0 auto;
    width: 338px;
  }
`;

export default StyledModal;
