export const getManagedUserAssets = preferences =>
  preferences?.valueMap?.productMap
    ? Object.keys(preferences.valueMap.productMap)
        .map(
          key =>
            preferences.valueMap.productMap[key][
              preferences.valueMap.productMap[key].length - 1
            ].investmentAmount
        )
        .reduce((acc, value) => acc + value, 0)
    : 0;
