import logo from "../images/logo_main.png";

export default {
  card_financial_product_border: "1px solid #BFC8CE",
  chart_colors: ["#6DC1C7", "#1A2849", "#64C981", "#CCB681", "#00BDC4"],
  colorPrimary: "#04223A",
  comparison_products_colors: ["#6DC1C7", "#004E88", "#D8D800"],
  income_chart_with_types_color_contributions: "#1B5568",
  income_chart_with_types_navbar_button_background: "#FFFFFF",
  income_chart_with_types_navbar_button_color: "#354A5F",
  income_chart_with_types_navbar_container_background: "#04223A",
  logo_src: logo,
  modal_financial_product_details_header_background: "#E7EBEE",
  page_login_button_submit_background: "#192849",
  page_login_button_submit_font_size: 16,
  page_login_button_submit_font_weight: 700,
  product_card_background: "linear-gradient(173deg, #FFFFFF 0%, #DEE8EF 100%)",
  sidebar_background: "#04223A",
  sidebar_icon_layout_vertical: true,
  sidebar_menu_item_font_size: "12px",
  sidebar_menu_item_hover_background: "#152A4E",
  sidebar_menu_item_padding: "9px",
  sidebar_width: 100,
  tenant_color_primary: "#04223A",
};
