import styled from "styled-components";
import { Button, Modal } from "antd";

const StyledModal = styled(Modal)`
  top: 18px;

  &.ant-modal {
    .ant-modal-header {
      padding: 21px 35px 0;
    }

    .ant-modal-content {
      max-height: 90vh;
      overflow: auto;
      padding: 0;
    }

    .ant-modal-footer {
      margin: unset;
      padding: 24px;
    }
  }

  .ant-tabs .ant-tabs-ink-bar {
    background: #215054;
    height: 4px;
  }

  .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    margin: 0;
    padding-left: 35px;
  }

  .ant-tabs .ant-tabs-content {
    padding: 24px 24px 0;
  }

  .ant-form-item {
    margin: 0;
  }

  .ant-form-vertical .ant-form-item-label {
    padding-bottom: 0;
  }
`;

export const StyledButton = styled(Button)`
  background: #ebf1f3;
  border-color: transparent;

  .ant-btn-icon {
    position: absolute;
    left: 4px;
    top: 4px;
  }

  &.active {
    background: #3aaafc;
    color: #ffffff;
  }
`;

export default StyledModal;
