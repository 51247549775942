import { Flex } from "antd";
import RadioButton from "../../component/RadioButton";

import StylesContainer from "./style";

const ReportPageIpq2 = ({ children, prospectObjective }) => (
  <StylesContainer className="page-container" id="reportPageIpq2">
    {children}

    <main>
      <h4>Risk Selection</h4>
      <div>
        <b>A:</b> Your calculated risk score is{" "}
        <b style={{ textTransform: "capitalize" }}>
          {prospectObjective?.calculatedRiskTolerance ?? "--"}
        </b>
      </div>
      <div style={{ marginTop: 12 }}>
        <b>B:</b> You selected an investment with a goal of{" "}
        <b style={{ textTransform: "capitalize" }}>
          {prospectObjective?.riskTolerance ?? "--"}
        </b>
      </div>
      <Flex align="center" gap={16} style={{ marginTop: 16 }}>
        <RadioButton
          checked={prospectObjective?.acceptedRiskLevel === "recommended"}
        />
        <span>
          All accounts should be invested according to my measured risk level
          listed above <b>(A)</b>
        </span>
      </Flex>
      <Flex gap={16} style={{ marginTop: 20 }}>
        <RadioButton
          checked={prospectObjective?.acceptedRiskLevel === "custom"}
        />
        <Flex vertical>
          <span>
            I wish to deviate from my measured risk level and request that my
            account be invested
            <br /> according to my selection above <b>(B)*</b>
          </span>
          {prospectObjective?.acceptedRiskLevel === "custom" && (
            <table>
              <thead>
                <tr>
                  <th style={{ width: 240 }}>Account</th>
                  <th style={{ width: 140 }}>Risk Tolerance</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    style={{
                      maxWidth: 240,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {prospectObjective?.listAccounts?.account__name__1}
                  </td>
                  <td style={{ textTransform: "capitalize" }}>
                    {prospectObjective?.listAccounts?.account__riskType__1}
                  </td>
                </tr>
                <tr>
                  <td>{prospectObjective?.listAccounts?.account__name__2}</td>
                  <td style={{ textTransform: "capitalize" }}>
                    {prospectObjective?.listAccounts?.account__riskType__2}
                  </td>
                </tr>
                <tr>
                  <td>{prospectObjective?.listAccounts?.account__name__3}</td>
                  <td style={{ textTransform: "capitalize" }}>
                    {prospectObjective?.listAccounts?.account__riskType__3}
                  </td>
                </tr>
                <tr>
                  <td>{prospectObjective?.listAccounts?.account__name__4}</td>
                  <td style={{ textTransform: "capitalize" }}>
                    {prospectObjective?.listAccounts?.account__riskType__4}
                  </td>
                </tr>
                <tr>
                  <td>{prospectObjective?.listAccounts?.account__name__5}</td>
                  <td style={{ textTransform: "capitalize" }}>
                    {prospectObjective?.listAccounts?.account__riskType__5}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </Flex>
      </Flex>
      <p className="description">
        <b>* Misaligned Portfolio Consent:</b> In the event that the portfolio
        selected above does not correspond to the measured risk tolerance
        information above, I understand that I have chosen a different portfolio
        despite the lack of alignment with risk tolerance and am comfortable
        with this choice and the risks associated with such choice, including
        the risk that I may lose principal. I further understand that it is my
        responsibility to communicate any changes in my circumstances, risk
        tolerance, or portfolio selection to my adviser. In addition, I will
        regularly review all account statements and reports from my adviser and
        alert my adviser if I have any questions, comments or concerns.
      </p>
      <Flex gap={100}>
        <Flex align="end" gap={23} vertical>
          <Flex>
            <div>Client Name:</div>
            <div className="signature-line" />
          </Flex>
          <Flex>
            <div>Client Signature:</div>
            <div className="signature-line" />
          </Flex>
          <Flex>
            <div>Date:</div>
            <div className="signature-line" />
          </Flex>
          <Flex>
            <div>Advisor Signature:</div>
            <div className="signature-line" />
          </Flex>
        </Flex>
        <Flex align="end" gap={23} vertical>
          <Flex>
            <div>Secondary Client Name:</div>
            <div className="signature-line" />
          </Flex>
          <Flex>
            <div>Secondary Signature:</div>
            <div className="signature-line" />
          </Flex>
          <Flex>
            <div>Date:</div>
            <div className="signature-line" />
          </Flex>
        </Flex>
      </Flex>
      <p style={{ marginTop: 52 }}>
        <b>Notes:</b>
      </p>
      <p style={{ paddingRight: 70 }}>{prospectObjective?.note}</p>
    </main>
  </StylesContainer>
);

export default ReportPageIpq2;
