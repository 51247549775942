import { useState } from "react";
import { Row, Space } from "antd";

import { RiskToleranceCard, SliderContainer, StyledSlider } from "./style";

import { riskToleranceCardsData } from "../../constants";

import ColumnGolden from "./images/column_golden.svg";
import ColumnGreen from "./images/column_green.svg";

const RiskToleranceSection = ({ updateProposalData }) => {
  const [sliderValue, setSliderValue] = useState(0);

  const convertNumberIntoRiskTolerance = number => {
    if (number < 25) return "Income";
    else if (number < 75) return "Income/Growth";
    else return "Growth";
  };

  const getBackgroundColumnsPosition = () => {
    if (sliderValue < 26) return [10, 5];
    else if (sliderValue < 51) return [20, 16];
    else if (sliderValue < 76) return [30, 40];
    else return [40, 50];
  };

  const getRecommendedProductId = number => {
    if (number < 26) return 47;
    else if (number < 51) return 50;
    else if (number < 76) return 48;
    else return 49;
  };

  return (
    <>
      <h3 className="title" style={{ position: "relative" }}>
        What’s Your Risk Tolerance?
      </h3>
      <p className="description" style={{ position: "relative" }}>
        An individual’s appetite for risk is generally defined as the amount of
        money you are willing to lose in order to achieve a future goal/gain.
        Determining how comfortable you are experiencing declines in your
        accounts while continuing to hold your investments during market
        declines is the key to accurately matching your risk profile to your
        investments.
      </p>
      <Space size={8}>
        <RiskToleranceCard className="disabled">
          <Row align="bottom" style={{ height: 46 }}>
            <h4>Capital Preservation</h4>
          </Row>
          <p>
            Conservative. Investors with a short or unknown time horizon and
            unwilling to accept significant volatility or potential loss.
          </p>
        </RiskToleranceCard>
        <Row
          style={{
            position: "relative",
            background:
              "transparent linear-gradient(270deg, #489557 0%, #027DBA 100%)",
            zIndex: 3,
          }}
          wrap={false}
        >
          {riskToleranceCardsData.map(({ description, title }, index) => (
            <RiskToleranceCard key={index}>
              <Row style={{ height: 46 }}>
                <h4>{title}</h4>
              </Row>
              <p>{description}</p>
            </RiskToleranceCard>
          ))}
          <SliderContainer>
            <StyledSlider
              value={sliderValue}
              min={0}
              max={100}
              step={1}
              tooltip={{ open: false }}
              trackStyle={{
                height: 12,
                background: "#7DBCBB",
                boxShadow: "inset 0px 4px 3px #00000093",
                opacity: 0.5,
                cursor: "default",
              }}
              railStyle={{
                height: 12,
                background: "#7DBCBB",
                boxShadow: "inset 0px 4px 3px #00000093",
                opacity: 0.5,
                cursor: "default",
              }}
              onChange={setSliderValue}
              onAfterChange={value => {
                updateProposalData(
                  "riskTolerance",
                  convertNumberIntoRiskTolerance(value)
                );
                updateProposalData(
                  "recommendedProductId",
                  getRecommendedProductId(value)
                );
              }}
            />
          </SliderContainer>
        </Row>
        <RiskToleranceCard className="disabled">
          <Row align="bottom" style={{ height: 46 }}>
            <h4>Aggressive</h4>
          </Row>
          <p>
            Growth. Investors with long term (20+ year) time horizons willing to
            accept the highest volatility and potential loss.
          </p>
        </RiskToleranceCard>
      </Space>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          right: 270,
          height: `${getBackgroundColumnsPosition()[0]}vh`,
          width: 395,
          background: `url(${ColumnGolden}) no-repeat`,
          transition: "all 500ms",
          zIndex: 1,
        }}
      />
      <div
        style={{
          position: "absolute",
          bottom: 0,
          right: 105,
          height: `${getBackgroundColumnsPosition()[1]}vh`,
          width: 395,
          background: `url(${ColumnGreen}) no-repeat`,
          transition: "all 500ms",
          zIndex: 2,
        }}
      />
    </>
  );
};

export default RiskToleranceSection;
