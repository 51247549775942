import { ReactComponent as NotComfortable } from "../images/icon_not_comfortable.svg";
import { ReactComponent as SomewhatUncomfortable } from "../images/icon_somewhat_uncomfortable.svg";
import { ReactComponent as SomewhatComfortable } from "../images/icon_somewhat_comfortable.svg";
import { ReactComponent as VeryComfortable } from "../images/icon_very_comfortable.svg";
import { ReactComponent as Loss } from "../images/icon_loss.svg";
import { ReactComponent as Uncertainty } from "../images/icon_uncertainty.svg";
import { ReactComponent as Opportunity } from "../images/icon_opportunity.svg";
import { ReactComponent as Thrill } from "../images/icon_thrill.svg";
import { ReactComponent as Critical } from "../images/icon_critical.svg";
import { ReactComponent as VeryImportant } from "../images/icon_very_important.svg";
import { ReactComponent as Important } from "../images/icon_important.svg";
import { ReactComponent as SlightlyImportant } from "../images/icon_slightly_important.svg";
import { ReactComponent as NotImportant } from "../images/icon_not_important.svg";

export const LIST_STEP_DATA = [
  {
    description: `<p>In terms of experience, how comfortable are you investing in stocks and/or stock mutual funds or exchange traded funds (ETFs).</p>`,
    formItemName: "experience__investments",
    options: [
      {
        Icon: <NotComfortable />,
        label: "Not Comfortable",
        value: 1,
      },
      {
        Icon: <SomewhatUncomfortable />,
        label: "Somewhat Uncomfortable",
        value: 3,
      },
      {
        Icon: <SomewhatComfortable />,
        label: "Somewhat Comfortable",
        value: 5,
      },
      {
        Icon: <VeryComfortable />,
        label: "Very Comfortable",
        value: 7,
      },
    ],
    title: "Investments",
  },
  {
    description:
      "When you think of the word “risk” which of the following words come to mind first",
    formItemName: "experience__risk",
    options: [
      {
        Icon: <Loss />,
        label: "Loss",
        value: 1,
      },
      {
        Icon: <Uncertainty />,
        label: "Uncertainty",
        value: 3,
      },
      {
        Icon: <Opportunity />,
        label: "Opportunity",
        value: 5,
      },
      {
        Icon: <Thrill />,
        label: "Thrill",
        value: 7,
      },
    ],
    title: "Risk",
  },
  {
    description: "How important is cash flow for this account?",
    formItemName: "timeline__cashflow_importance",
    options: [
      {
        Icon: <Critical />,
        label: "Critical",
        value: 0,
      },
      {
        Icon: <VeryImportant />,
        label: "Very Important",
        value: 1,
      },
      {
        Icon: <Important />,
        label: "Important",
        value: 3,
      },
      {
        Icon: <SlightlyImportant />,
        label: "Slightly Important",
        value: 5,
      },
      {
        Icon: <NotImportant />,
        label: "Not Important",
        value: 7,
      },
    ],
    title: "Timeline",
  },
  {
    description: "What is the investment time horizon for this account?",
    formItemName: "timeline__time_horizon",
    options: [
      {
        label: "Less than 3 years",
        value: 0,
      },
      {
        label: "3-5 years",
        value: 1,
      },
      {
        label: "5-10 years",
        value: 3,
      },
      {
        label: "10-15 years",
        value: 5,
      },
      {
        label: "Over 15 years",
        value: 7,
      },
    ],
    title: "Timeline",
  },
  {
    description:
      "When do you expect to start drawing income from this account?",
    formItemName: "timeline__withdrawal",
    options: [
      {
        label: "Immediately",
        value: 1,
      },
      {
        label: "Not now, but within 5 years",
        value: 3,
      },
      {
        label: "In 5-15 years",
        value: 5,
      },
      {
        label: "Over 15 years",
        value: 7,
      },
    ],
    title: "Timeline",
  },
  {
    description:
      "How much risk are you willing to take in this account to achieve a higher return?",
    formItemName: "experience__higher_return",
    options: [
      {
        label: "No Risk",
        value: 1,
      },
      {
        label: "A Little Risk",
        value: 3,
      },
      {
        label: "Some Risk",
        value: 5,
      },
      {
        label: "High Risk",
        value: 7,
      },
    ],
    title: "Risk",
  },
  {
    description:
      "If there was an unexpected financial situation, how likely are you to withdraw from this account?",
    formItemName: "timeline__unexpected_situation",
    options: [
      {
        label: "Definitely",
        value: 1,
      },
      {
        label: "Very likely",
        value: 3,
      },
      {
        label: "Somewhat likely",
        value: 5,
      },
      {
        label: "Unlikely",
        value: 7,
      },
    ],
    title: "Timeline",
  },
  {
    description:
      "How much do you expect your household income to increase or decrease over the next 5 years?",
    formItemName: "timeline__income_increase",
    options: [
      {
        label: "Planning to Retire",
        value: 1,
      },
      {
        label: "Decrease by 5% or more",
        value: 3,
      },
      {
        label: "Within 5% up or down",
        value: 5,
      },
      {
        label: "Increase by 10% or more",
        value: 7,
      },
    ],
    title: "Timeline",
  },
];
