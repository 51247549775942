import { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import State from "../../../../context";
import { Button, Flex, Input, Table, theme } from "antd";

import StylesContainer from "./style";

import { getManagerConversations } from "../../../../utils/request/manager";
import { parsePennyResponse } from "../../../../utils/helper/general";

import IconPenny from "../../images/IconPenny";
import { DownOutlined, RightOutlined, SearchOutlined } from "@ant-design/icons";

const PageAskPenny = () => {
  const { token } = theme.useToken();
  const [state] = useContext(State);
  const [conversations, setConversations] = useState();
  const [searchedText, setSearchedText] = useState("");

  useEffect(() => {
    if (state._id && !conversations) {
      getManagerConversations(state._id)
        .then(setConversations)
        .catch(error => state.showError(error));
    }
  }, [state]);

  return (
    <StylesContainer>
      <Flex align="center" className="header" justify="space-between">
        <h2 className="title">Conversations</h2>
        <Button
          disabled={!state.access?.penny}
          icon={<IconPenny />}
          onClick={() => state.openModal("openModalAskAdvice")}
          shape="round"
          style={{ background: token.button_ask_penny_background }}
          type="primary"
        >
          <span>
            Ask<b>Penny</b>
          </span>
        </Button>
      </Flex>
      <Flex className="body" gap={32} vertical>
        <Input
          style={{
            maxWidth: 270,
            height: 40,
            borderRadius: 25,
          }}
          placeholder="Search..."
          onChange={event => setSearchedText(event.target.value)}
          suffix={<SearchOutlined />}
        />
        <Table
          dataSource={conversations?.map(it => ({
            ...it,
            key: it._id,
          }))}
          expandRowByClick={true}
          expandable={{
            expandedRowRender: record =>
              parsePennyResponse(record.pennyResponse),
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <DownOutlined onClick={e => onExpand(record, e)} />
              ) : (
                <RightOutlined onClick={e => onExpand(record, e)} />
              ),
          }}
          rowHoverable={false}
          pagination={false}
          scroll={{
            x: 1010,
            y: "calc(100vh - 200px)",
          }}
          size="small"
        >
          <Table.Column
            dataIndex="userQuery"
            filteredValue={[searchedText]}
            onFilter={(value, record) =>
              String(record.userQuery)
                .toLowerCase()
                .includes(value.toLowerCase()) ||
              String(record.userEmail)
                .toLowerCase()
                .includes(value.toLowerCase()) ||
              String(record.environment)
                .toLowerCase()
                .includes(value.toLowerCase())
            }
            sorter={{
              compare: (a, b) => a.userQuery?.localeCompare(b.userQuery),
            }}
            title="Question"
          />
          <Table.Column
            dataIndex="userEmail"
            sorter={{
              compare: (a, b) => a.userEmail?.localeCompare(b.userEmail),
            }}
            title="User"
          />
          <Table.Column
            dataIndex="environment"
            render={value => `${value} app`}
            sorter={{
              compare: (a, b) => a.environment?.localeCompare(b.environment),
            }}
            title="Source"
          />
          <Table.Column
            dataIndex="updatedAt"
            render={value => dayjs(value).format("MM/DD/YY, h:mma")}
            sorter={{
              compare: (a, b) => dayjs(a.updatedAt) - dayjs(b.updatedAt),
            }}
            title="Time"
          />
        </Table>
      </Flex>
    </StylesContainer>
  );
};

export default PageAskPenny;
