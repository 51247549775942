import { useContext, useState } from "react";
import State from "../../../../context";
import { Button, Flex, Form, Input, Modal, Switch } from "antd";

import {
  changeOrgManagerPassword,
  changeUserManagerPassword,
  saveOrgManagerMfaEnabled,
  saveUserManagerMfaEnabled,
} from "../../../../utils/request/manager";
import { verifyPassword } from "../../../../utils/request/regularApp";
import { isPortalAdmin } from "../../../../utils/helper/specialized";

import Close from "../../../../icon/Close";

const ModalManagerSettings = ({ onCancel, open }) => {
  const [form] = Form.useForm();
  const [state] = useContext(State);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const onFinish = values => {
    state.setKeyValue("loading", true);

    verifyPassword(oldPassword)
      .then(response => {
        if (response.data) {
          (isPortalAdmin()
            ? changeOrgManagerPassword
            : changeUserManagerPassword)(values)
            .then(() => {
              state.showSuccess("Password changed!");
              form.resetFields();
              state.setKeyValue("loading", false);
              state.setKeyValue("openModalManagerSettings", false);
              setOldPassword("");
              setNewPassword("");
            })
            .catch(error => {
              state.showError(error);
              state.setKeyValue("loading", false);
            });
        } else {
          state.setKeyValue("loading", false);
          state.showError("Old password is incorrect");
        }
      })
      .catch(error => {
        state.showError(error);
        state.setKeyValue("loading", false);
      });
  };

  const toggleMFA = enabled => {
    (isPortalAdmin() ? saveOrgManagerMfaEnabled : saveUserManagerMfaEnabled)(
      enabled
    )
      .then(response => {
        state.setAdminData().then(() => {
          state.setKeyValue("openModalManagerSettings", false);
          state.setKeyValue("loading", false);
          state.showSuccess("Your information has been saved. Thank you!");
        });
      })
      .catch(error => {
        state.showError(error);
        state.setKeyValue("loading", false);
      });
  };

  return (
    <Modal
      closeIcon={<Close />}
      footer={false}
      onCancel={onCancel}
      open={open}
      title="Settings"
    >
      <Flex style={{ padding: 40 }} vertical>
        <Form
          autoComplete="off"
          form={form}
          id="managerSettingsForm"
          layout="vertical"
          onFinish={onFinish}
          style={{ display: "flex", flexDirection: "column", gap: 20 }}
        >
          <Form.Item
            label="Old password"
            name="oldPassword"
            onChange={e => setOldPassword(e.target.value)}
            rules={[
              {
                required: true,
                whitespace: false,
              },
            ]}
            style={{ margin: 0 }}
          >
            <Input.Password id="oldPasswordinput" size="large" />
          </Form.Item>
          <Form.Item
            hasFeedback
            label="New password"
            name="newPassword"
            onChange={e => setNewPassword(e.target.value)}
            rules={[
              {
                required: true,
                pattern:
                  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9#?!@$%^&*-]).{8,}$/,
                message: () => (
                  <Flex gap={2} style={{ marginTop: 4 }} vertical>
                    {!/[a-z]/.test(newPassword) && (
                      <span>One lowercase character</span>
                    )}
                    {!/[A-Z]/.test(newPassword) && (
                      <span>One uppercase character</span>
                    )}
                    {newPassword?.length < 8 && (
                      <span>8 characters minimum</span>
                    )}
                    {(!newPassword ||
                      !/^(?=.*[0-9])|(?=.*[^0-9A-Za-z]).*$/.test(
                        newPassword
                      )) && <span>One number or one special character</span>}
                  </Flex>
                ),
                whitespace: false,
              },
            ]}
            style={{ margin: 0 }}
          >
            <Input.Password id="newPasswordInput " size="large" />
          </Form.Item>
          <Form.Item
            dependencies={["newPassword"]}
            hasFeedback
            label="Confirm Password"
            name="confirmNewPassword"
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The new password that you entered do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password id="confirmNewPasswordInput" size="large" />
          </Form.Item>
          <Button
            disabled={!oldPassword || state.loading}
            htmlType="submit"
            shape="round"
            type="primary"
          >
            Set New Password
          </Button>
        </Form>
        <Flex gap={6} style={{ marginTop: 40 }}>
          <Switch defaultChecked={state.isMfaEnabled} onChange={toggleMFA} />
          <span>Enable Mfa (Multi-Factor Authentication)</span>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default ModalManagerSettings;
