export const LIST_COMPANY_MOCK = [
  {
    _id: 1,
    name: "OneAscent",
    type: "LevelWealth",
    contacts: [
      {
        firstName: "Cole",
        lastName: "Pearson",
        email: "cpearson@oneascent.com",
        phone: "+15551234567",
        phoneAlt: "+15557654321",
        isPrimary: true,
      },
    ],
    features: {
      penny: true,
    },
    createDateTime: new Date("2023-02-25T03:39:17.836Z"),
    updateDateTime: new Date("2025-03-12T15:00:00.000Z"),
    lastAdminLoginTime: null,
  },
  {
    _id: 2,
    name: "LevelWealth, LLC",
    type: "Pro Portal",
    contacts: [
      {
        firstName: "Linda",
        lastName: "Bready",
        email: "lpbready@levelwealth.com",
        phone: "+15551234567",
        phoneAlt: "+15557654321",
        isPrimary: true,
      },
    ],
    features: {
      penny: true,
    },
    createDateTime: new Date("2023-02-25T03:39:17.836Z"),
    updateDateTime: new Date("2025-03-12T15:00:00.000Z"),
    lastAdminLoginTime: null,
  },
  {
    _id: 3,
    name: "3 Factor Indexing",
    type: "LevelWealth",
    contacts: [
      {
        firstName: "Bob",
        lastName: "Factor",
        email: "bob@3factorindexing.com",
        phone: "+15551234567",
        phoneAlt: "+15557654321",
        isPrimary: true,
      },
    ],
    features: {
      penny: true,
    },
    createDateTime: new Date("2023-02-25T03:39:17.836Z"),
    updateDateTime: new Date("2025-03-12T15:00:00.000Z"),
    lastAdminLoginTime: null,
  },
  {
    _id: 4,
    name: "Anchor Financial Management",
    type: "Pro Portal/Affiliate",
    contacts: [
      {
        firstName: "Connie",
        lastName: "Anchor",
        email: "connie@anchorfinancialmanagement.com",
        phone: "+15551234567",
        phoneAlt: "+15557654321",
        isPrimary: true,
      },
    ],
    features: {
      penny: true,
    },
    createDateTime: new Date("2023-02-25T03:39:17.836Z"),
    updateDateTime: new Date("2025-03-12T15:00:00.000Z"),
    lastAdminLoginTime: null,
  },
  {
    _id: 5,
    name: "Riggs Asset Management",
    type: "Pro Portal",
    contacts: [
      {
        firstName: "Brian",
        lastName: "Graham",
        email: "bgraham@riggsadvisors.com",
        phone: "+15551234567",
        phoneAlt: "+15557654321",
        isPrimary: true,
      },
    ],
    features: {
      penny: true,
    },
    createDateTime: new Date("2023-02-25T03:39:17.836Z"),
    updateDateTime: new Date("2025-03-12T15:00:00.000Z"),
    lastAdminLoginTime: null,
  },
  {
    _id: 6,
    name: "Worrell Advisory Services",
    type: "Pro Portal",
    contacts: [
      {
        firstName: "Rob",
        lastName: "Worrell",
        email: "rob@worrelladvisory.com",
        phone: "+15551234567",
        phoneAlt: "+15557654321",
        isPrimary: true,
      },
    ],
    features: {
      penny: true,
    },
    createDateTime: new Date("2023-02-25T03:39:17.836Z"),
    updateDateTime: new Date("2025-03-12T15:00:00.000Z"),
    lastAdminLoginTime: null,
  },
  {
    _id: 7,
    name: "Bluepointwealthmanagement",
    type: "Pro Portal",
    contacts: [
      {
        firstName: "Derick",
        lastName: "Bluepoint",
        email: "derrick@bluepointwealthmanagement.com",
        phone: "+15551234567",
        phoneAlt: "+15557654321",
        isPrimary: true,
      },
    ],
    features: {
      penny: true,
    },
    createDateTime: new Date("2023-02-25T03:39:17.836Z"),
    updateDateTime: new Date("2025-03-12T15:00:00.000Z"),
    lastAdminLoginTime: null,
  },
  {
    _id: 8,
    name: "Ohstrom",
    type: "Pro Portal/Affiliate",
    contacts: [
      {
        firstName: "Deb",
        lastName: "Ohstrom",
        email: "dohstrom@gmail.com",
        phone: "+15551234567",
        phoneAlt: "+15557654321",
        isPrimary: true,
      },
    ],
    features: {
      penny: true,
    },
    createDateTime: new Date("2023-02-25T03:39:17.836Z"),
    updateDateTime: new Date("2025-03-12T15:00:00.000Z"),
    lastAdminLoginTime: null,
  },
  {
    _id: 9,
    name: "MIP LLC",
    type: "Affiliate",
    contacts: [
      {
        firstName: "James",
        lastName: "Moore",
        email: "jmoore@mip-llc.com",
        phone: "+15551234567",
        phoneAlt: "+15557654321",
        isPrimary: true,
      },
    ],
    features: {
      penny: true,
    },
    createDateTime: new Date("2023-02-25T03:39:17.836Z"),
    updateDateTime: new Date("2025-03-12T15:00:00.000Z"),
    lastAdminLoginTime: null,
  },
  {
    _id: 10,
    name: "Compton Advisors",
    type: "Pro Portal",
    contacts: [
      {
        firstName: "John",
        lastName: "Compton",
        email: "john@comptonadvisors.com",
        phone: "+15551234567",
        phoneAlt: "+15557654321",
        isPrimary: true,
      },
    ],
    features: {
      penny: true,
    },
    createDateTime: new Date("2023-02-25T03:39:17.836Z"),
    updateDateTime: new Date("2025-03-12T15:00:00.000Z"),
    lastAdminLoginTime: null,
  },
  {
    _id: 11,
    name: "Optonline",
    type: "Affiliate",
    contacts: [
      {
        firstName: "David",
        lastName: "Gold",
        email: "dgoldst@optonline.net",
        phone: "+15551234567",
        phoneAlt: "+15557654321",
        isPrimary: true,
      },
    ],
    features: {
      penny: true,
    },
    createDateTime: new Date("2023-02-25T03:39:17.836Z"),
    updateDateTime: new Date("2025-03-12T15:00:00.000Z"),
    lastAdminLoginTime: null,
  },
  {
    _id: 12,
    name: "Prudeo",
    type: "Pro Portal",
    contacts: [
      {
        firstName: "Sheila",
        lastName: "Little",
        email: "slittle@prudeo.com",
        phone: "+15551234567",
        phoneAlt: "+15557654321",
        isPrimary: true,
      },
    ],
    features: {
      penny: true,
    },
    createDateTime: new Date("2023-02-25T03:39:17.836Z"),
    updateDateTime: new Date("2025-03-12T15:00:00.000Z"),
    lastAdminLoginTime: null,
  },
  {
    _id: 13,
    name: "Private Client Group AM",
    type: "Pro Portal",
    contacts: [
      {
        firstName: "David",
        lastName: "Private",
        email: "davidj@privateclientgroupam.com",
        phone: "+15551234567",
        phoneAlt: "+15557654321",
        isPrimary: true,
      },
    ],
    features: {
      penny: true,
    },
    createDateTime: new Date("2023-02-25T03:39:17.836Z"),
    updateDateTime: new Date("2025-03-12T15:00:00.000Z"),
    lastAdminLoginTime: null,
  },
  {
    _id: 14,
    name: "Newland Group Insurance",
    type: "Pro Portal",
    contacts: [
      {
        firstName: "Sam",
        lastName: "Newland",
        email: "sam@insurewithngi.com",
        phone: "+15551234567",
        phoneAlt: "+15557654321",
        isPrimary: true,
      },
    ],
    features: {
      penny: true,
    },
    createDateTime: new Date("2023-02-25T03:39:17.836Z"),
    updateDateTime: new Date("2025-03-12T15:00:00.000Z"),
    lastAdminLoginTime: null,
  },
  {
    _id: 15,
    name: "Rivershares",
    type: "Pro Portal",
    contacts: [
      {
        firstName: "TBD",
        lastName: "TBD",
        email: "tbd@dev.com",
        phone: "+15551234567",
        phoneAlt: "+15557654321",
        isPrimary: true,
      },
    ],
    features: {
      penny: true,
    },
    createDateTime: new Date("2023-02-25T03:39:17.836Z"),
    updateDateTime: new Date("2025-03-12T15:00:00.000Z"),
    lastAdminLoginTime: null,
  },
  {
    _id: 16,
    name: "Astor Financial",
    type: "Pro Portal",
    contacts: [
      {
        firstName: "TBD",
        lastName: "TBD",
        email: "tbd@dev.com",
        phone: "+15551234567",
        phoneAlt: "+15557654321",
        isPrimary: true,
      },
    ],
    features: {
      penny: true,
    },
    createDateTime: new Date("2023-02-25T03:39:17.836Z"),
    updateDateTime: new Date("2025-03-12T15:00:00.000Z"),
    lastAdminLoginTime: null,
  },
];
