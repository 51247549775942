import {
  isOrganizationBluepoint,
  isOrganizationLevel,
} from "../../../utils/helper/specialized";

export const getOrganizationForCalcs = organization => {
  if (isOrganizationLevel(organization)) return "level2";
  else if (isOrganizationBluepoint(organization)) return "goals";
  else if (organization) return organization;
  else return "level2";
};
