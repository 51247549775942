import { useContext, useState } from "react";
import dayjs from "dayjs";
import State from "../../../../context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Flex, Button, Input, Table } from "antd";
import MenuActions from "../../../../atom/MenuActions";

import StyledContainer from "./style";

import { LIST_COMPANY_MOCK } from "./constant";

import { SearchOutlined } from "@ant-design/icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as ActionIcon } from "../../image/icon_actions_menu.svg";
import ModalCompany from "../../component/ModalCompany";

const PageCompanies = () => {
  const [state] = useContext(State);
  const [searchedText, setSearchedText] = useState("");

  const handleButtonNewCompany = () => {
    state.setKeyValue("openModalCompany", true);
  };

  const submitCompany = values => {
    console.log(values);
  };

  const columns = [
    {
      dataIndex: "name",
      filteredValue: [searchedText],
      onFilter: (value, record) =>
        String(record.name)?.toLowerCase()?.includes(value.toLowerCase()) ||
        String(record.type)?.toLowerCase()?.includes(value.toLowerCase()) ||
        String(record.contacts[0]?.firstName)
          ?.toLowerCase()
          ?.includes(value.toLowerCase()) ||
        String(record.contacts[0]?.lastName)
          ?.toLowerCase()
          ?.includes(value.toLowerCase()) ||
        String(record.contacts[0]?.email)
          ?.toLowerCase()
          ?.includes(value.toLowerCase()),
      render: value => <a>{value}</a>,
      sorter: (a, b) => a.name?.localeCompare(b.name),
      title: "Company",
    },
    {
      dataIndex: "type",
      sorter: (a, b) => a.type?.localeCompare(b.type),
      title: "Type",
    },
    {
      render: allValues => (
        <Flex gap={4} vertical>
          <a>
            {allValues.contacts[0]?.allValues?.firstName}{" "}
            {allValues.contacts[0]?.allValues?.lastName}
          </a>
          <a
            href={`mailto:${allValues.contacts[0]?.email}`}
            style={{ color: "#4B4747", fontSize: 12 }}
          >
            {allValues.contacts[0]?.email}
          </a>
        </Flex>
      ),
      sorter: (a, b) =>
        a.contacts[0]?.email?.localeCompare(b.contacts[0]?.email),
      title: "Primary Contact",
    },
    {
      className: "cell-last-login",
      dataIndex: "updateDateTime",
      render: value => dayjs(value).format("MM/DD/YY, h:mma"),
      sorter: (a, b) => dayjs(a.updateDateTime) - dayjs(b.updateDateTime),
      title: "Last Admin Login",
    },
    {
      className: "actions-menu-table-cell",
      key: "actionsMenu",
      render: () => (
        <MenuActions
          items={[
            {
              key: "edit",
              label: "Edit Company",
              onClick: () => state.showWarning("Edit company TODO"),
            },
            {
              key: "archive",
              label: "Archive",
              onClick: () => state.showWarning("Archive company TODO"),
            },
          ]}
        >
          <ActionIcon style={{ cursor: "pointer" }} />
        </MenuActions>
      ),
      title: "",
      width: 40,
    },
  ];

  return (
    <StyledContainer>
      <header>
        <h4>All Companies</h4>
      </header>

      <main>
        <Flex justify="space-between">
          <Input
            allowClear
            onChange={e => setSearchedText(e.target.value)}
            placeholder="Search..."
            size="large"
            style={{ borderRadius: 20, width: 270 }}
            suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />}
            value={searchedText}
          />

          <Button
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={handleButtonNewCompany}
            shape="round"
            type="primary"
          >
            New Company
          </Button>
        </Flex>

        <Table
          columns={columns}
          dataSource={LIST_COMPANY_MOCK.sort((a, b) =>
            a.name.localeCompare(b.name)
          ).map(it => ({
            ...it,
            key: it._id,
          }))}
          loading={state.loading}
          pagination={false}
          size="small"
        />
      </main>

      <ModalCompany
        onCancel={() => state.setKeyValue("openModalCompany")}
        onFinish={submitCompany}
        open={state.openModalCompany}
      />
    </StyledContainer>
  );
};

export default PageCompanies;
