import { useContext, useEffect, useState } from "react";
import State from "../../../../../../context/admin/State";
import { Button, Flex, Input, Table } from "antd";
import MenuActions from "../../../../../../../../atom/MenuActions";

import { StyledSwitch } from "./style";

import { MAX_FEATURED_SPONSORS_AMOUNT } from "../../../../../../constants";

import { updateSponsor } from "../../../../../../requests/global";

import { SearchOutlined } from "@ant-design/icons";
import { ReactComponent as CirclePlusGreen } from "../../../../../../images/circle_plus_green.svg";

const getColumns = ({
  handleDelete,
  handleFeatured,
  handleUpdate,
  loading,
  searchedText,
}) => [
  {
    dataIndex: "name",
    filteredValue: [searchedText],
    key: "name",
    onFilter: (value, record) =>
      String(record.name).toLowerCase().includes(value.toLowerCase()) ||
      String(record.category).toLowerCase().includes(value.toLowerCase()) ||
      String(record.referral).toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => a.name.localeCompare(b.name),
    title: "Sponsor Name",
  },
  {
    dataIndex: "url",
    key: "url",
    render: value => (
      <div
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: 300,
          whiteSpace: "nowrap",
        }}
      >
        <a href={value} target="_blank" rel="noreferrer">
          <u>{value}</u>
        </a>
      </div>
    ),
    sorter: (a, b) => a.url.localeCompare(b.url),
    title: "URL",
  },
  {
    dataIndex: "category",
    key: "category",
    sorter: (a, b) => a.category.localeCompare(b.category),
    title: "Category",
  },
  {
    dataIndex: "referral",
    key: "referral",
    sorter: (a, b) => a.referral.localeCompare(b.referral),
    title: "MMI Referral",
  },
  {
    dataIndex: "featured",
    key: "featured",
    render: (checked, restValues) => (
      <Flex justify="center">
        <StyledSwitch
          checked={checked}
          disabled={loading}
          onChange={checked => handleFeatured(restValues._id, checked)}
          size="small"
        />
      </Flex>
    ),
    sorter: false,
    title: "Featured",
  },
  {
    dataIndex: "logo",
    key: "logo",
    render: value =>
      value && (
        <a href={value.url} target="_blank" rel="noreferrer">
          <u>{value.filename}</u>
        </a>
      ),
    title: "Logo",
  },
  {
    className: "actions-menu-table-cell",
    dataIndex: "actionsMenu",
    key: "actionsMenu",
    render: (value, restValues) => (
      <MenuActions
        items={getItems({
          handleDelete,
          handleUpdate,
          sponsorId: restValues._id,
        })}
      />
    ),
    title: "",
    width: 40,
  },
];

const getItems = ({ handleDelete, handleUpdate, sponsorId }) => [
  {
    key: "1",
    label: "Edit Sponsor",
    onClick: () => handleUpdate(sponsorId),
  },
  {
    key: "2",
    label: "Delete Sponsor",
    onClick: () => handleDelete(sponsorId),
  },
];

const TabSponsors = () => {
  const [state] = useContext(State);
  const [searchedText, setSearchedText] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    state.reFetch.sponsors().then(() => setLoading(false));
  }, []);

  const handleDelete = sponsorId => {
    state.setKeyValue("activeSponsorId", sponsorId);
    state.openModal("deleteSponsorModal");
  };

  const handleFeatured = (sponsorId, checked) => {
    if (checked) {
      const featuredSponsorsQuantity = state.sponsors.reduce(
        (acc, it) => (it.featured ? ++acc : acc),
        0
      );

      if (featuredSponsorsQuantity >= MAX_FEATURED_SPONSORS_AMOUNT) {
        state.showWarning(
          `A maximum of ${MAX_FEATURED_SPONSORS_AMOUNT} sponsors can be featured at once. Please disable a sponsor.`
        );

        return;
      }
    }

    const sponsorData = state.sponsors.find(it => it._id === sponsorId);

    if (sponsorData) {
      state.setKeyValue("loading", true);

      updateSponsor({
        sponsorId: sponsorId,
        payloadData: {
          ...sponsorData,
          featured: checked,
        },
      })
        .then(() => {
          state.reFetch.sponsors().then(() => {
            state.showSuccess("Sponsor updated");
          });
        })
        .catch(error => state.showWarning(error.message))
        .finally(() => state.setKeyValue("loading", false));
    } else {
      state.showWarning("Sponsor do not exist");
    }
  };

  const handleUpdate = sponsorId => {
    state.setKeyValue("activeSponsorId", sponsorId);
    state.openModal("sponsorModal");
  };

  return (
    <Flex vertical gap={20}>
      <Flex justify="space-between">
        <Input
          className="pointer"
          onChange={event => setSearchedText(event.target.value)}
          placeholder="Search..."
          size="large"
          style={{ width: 236, borderRadius: 23 }}
          suffix={<SearchOutlined style={{ fontSize: 20, color: "#B1B1B1" }} />}
        />
        <Button
          onClick={() => state.openModal("sponsorModal")}
          shape="round"
          size="small"
          style={{ color: "#2E9642" }}
          type="text"
        >
          <Flex align="center" gap={8}>
            <CirclePlusGreen />
            <span style={{ fontFamily: "Poppins, sans-serif" }}>
              New Sponsor
            </span>
          </Flex>
        </Button>
      </Flex>
      <Table
        columns={getColumns({
          handleDelete,
          handleFeatured,
          handleUpdate,
          loading: state.loading,
          searchedText,
        })}
        dataSource={state.sponsors?.map((it, index) => ({
          ...it,
          key: index,
        }))}
        loading={loading}
        pagination={{
          position: ["none", "bottomCenter"],
        }}
        size="small"
      />
    </Flex>
  );
};

export default TabSponsors;
