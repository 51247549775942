import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import dayjs from "dayjs";
import Highcharts from "highcharts";
import exporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";
import { Flex, theme } from "antd";
import ChartSkeleton from "../../atom/ChartSkeleton";

import {
  ChartTypeButtonsContainer,
  Marker,
  NoContributionsContainer,
  StyledButton,
} from "./style";

import { DEFAULT_BIRTH_YEAR } from "../../utils/constant";

import {
  getCurrencyValue,
  getCurrencyValueWithRoundSign,
} from "../../utils/helper/general";
import { isGrowthTypeRiskTolerance } from "../../utils/helper/specialized";

exporting(Highcharts);

const ChartIncomeWithTypes = ({
  height,
  hideChartTypeButtons,
  loading,
  productsLiveAssessment,
  productsList,
  productData,
  showLegend = true,
  showLegendAmounts,
  viewMode,
  chartType = "",
  disableLoading = false,
}) => {
  const { token } = theme.useToken();
  const isMobile = useMediaQuery({ maxWidth: token.screenSM });
  const [chartSeries, setChartSeries] = useState([]);
  const [incomeChartType, setIncomeChartType] = useState(chartType);
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartType !== incomeChartType) {
      setIncomeChartType(chartType);
    }
  }, [chartType]);

  useEffect(() => {
    if (viewMode === "pdf") {
      // required for Astor & Rivershares pdf generators
      const storedData = localStorage.getItem("barsChartSvg");

      const interval = setInterval(() => {
        if (!storedData) {
          const barsChartSvg = chartRef?.current?.chart?.getSVG();

          barsChartSvg && localStorage.setItem("barsChartSvg", barsChartSvg);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [viewMode]);

  useEffect(() => {
    const investmentObjectiveChartType = isGrowthTypeRiskTolerance(
      productData?.investmentObjective
    )
      ? "contributions"
      : "income";

    if (incomeChartType !== investmentObjectiveChartType && chartType === "")
      setIncomeChartType(investmentObjectiveChartType);
  }, [productData]);

  useEffect(() => {
    if (productsLiveAssessment?.length) {
      updateSeries();
    }
  }, [productsLiveAssessment, incomeChartType]);

  useEffect(() => {
    if (chartSeries.length) {
      if (chartRef.current) {
        const chart = chartRef.current.chart;
        while (chart.series.length > 0) {
          chart.series[0].remove(false);
        }

        chartSeries.forEach(series => {
          chart.addSeries(series, false);
        });

        chart.redraw();
      }
    }
  }, [chartSeries]);

  const updateSeries = () => {
    if (productsLiveAssessment?.length) {
      if (incomeChartType === "contributions") {
        const s = {
          name: "Contributions",
          color: token.income_chart_with_types_color_contributions,
          data: productsLiveAssessment[0].incomeBarchart.totalContributions,
          visible: productData?.contributions !== 0,
          borderRadius: "6px",
          groupPadding: 0.05,
          pointPadding: 0.05,
        };

        setChartSeries([s]);
      } else {
        const s = structuredClone(productsLiveAssessment)
          .map(it => {
            const series = [];

            const annuities_isOn = productData?.annuities_isOn;
            const hideDetailedNames = productsLiveAssessment.length === 1;
            const incomeBarchart = it.incomeBarchart;
            const name = productsList.find(
              product => product._id === (it.productId ?? it._id)
            )?.name;
            const otherIncome_isOn = productData?.otherIncome_isOn;
            const socialSecurity_isOn = productData?.socialSecurity_isOn;
            const totalWithdrawalsColor = it.color;

            incomeBarchart?.totalWithdrawals &&
              series.push({
                name:
                  name && !hideDetailedNames
                    ? `Withdrawals (${name})`
                    : "Withdrawals",
                color: totalWithdrawalsColor || "#1B5568",
                data: incomeBarchart.totalWithdrawals,
                stack: name,
                visible: true,
                borderRadius: "6px",
                groupPadding: 0.05,
                pointPadding: 0.05,
              });

            incomeBarchart?.annuities &&
              annuities_isOn &&
              series.push({
                name:
                  name && !hideDetailedNames
                    ? `Annuities (${name})`
                    : "Annuities",
                color: "#5F82F8",
                data: incomeBarchart.annuities,
                stack: name,
                visible: true,
                borderRadius: "6px",
                groupPadding: 0.05,
                pointPadding: 0.05,
              });

            incomeBarchart?.otherIncome &&
              otherIncome_isOn &&
              series.push({
                name:
                  name && !hideDetailedNames
                    ? `Other Income (${name})`
                    : "Other Income",
                color: "#9FBEFF",
                data: incomeBarchart.otherIncome,
                stack: name,
                visible: true,
                borderRadius: "6px",
                groupPadding: 0.05,
                pointPadding: 0.05,
              });

            incomeBarchart?.socialSecurity &&
              socialSecurity_isOn &&
              series.push({
                name:
                  name && !hideDetailedNames
                    ? `Social Security (${name})`
                    : "Social Security",
                color: "#3C4C99",
                data: incomeBarchart.socialSecurity,
                stack: name,
                visible: true,
                borderRadius: "6px",
                groupPadding: 0.05,
                pointPadding: 0.05,
              });

            return series;
          })
          .flat();
        setChartSeries(s);
      }
    }
  };

  const getLegend = () =>
    [
      {
        color: token.income_chart_with_types_color_contributions,
        dataName: "withdrawals",
        label: "Withdrawals",
      },
      {
        color: "#3C4C99",
        dataName: "socialSecurity",
        label: "Social Security",
        togglerName: "socialSecurity_isOn",
      },
      {
        color: "#5F82F8",
        dataName: "annuities",
        label: "Annuities",
        togglerName: "annuities_isOn",
      },
      {
        color: "#9FBEFF",
        dataName: "otherIncome",
        label: "Other Income",
        togglerName: "otherIncome_isOn",
      },
    ].map(
      (config, index) =>
        productData && (
          <Flex
            align="start"
            gap={12}
            justify="space-between"
            key={index}
            style={{
              display:
                config.togglerName && !productData[config.togglerName]
                  ? "none"
                  : "flex",
              width: isMobile && "100%",
            }}
            vertical={(!isMobile).toString()}
          >
            <Flex gap={12}>
              <Marker style={{ background: config.color }} />
              <span>{config.label}</span>
            </Flex>
            <Flex justify="end" style={{ width: "100%" }}>
              {showLegendAmounts && (
                <b>
                  {getCurrencyValue(
                    config.dataName === "withdrawals"
                      ? getWithdrawalsLegendLabel()
                      : (productData[config.dataName] ?? 0)
                  )}{" "}
                  / year
                </b>
              )}
            </Flex>
          </Flex>
        )
    );

  const getWithdrawalsLegendLabel = () =>
    productsLiveAssessment?.length &&
    productsLiveAssessment[0].incomeBarchart.totalWithdrawals.find(
      ([, value]) => value > 0
    )
      ? productsLiveAssessment[0].incomeBarchart.totalWithdrawals.find(
          ([, value]) => value > 0
        )[1]
      : 0;

  const incomeChartConfig = {
    chart: {
      type: "column",
      height:
        height ??
        (incomeChartType === "contributions" && productData?.contributions === 0
          ? 50
          : 200),
      borderWidth: 1,
      borderRadius: 8,
      borderColor: "transparent",
      spacingTop: 20,
      spacingRight: 25,
      spacingLeft: 20,
      styledMode: false,
      backgroundColor: "transparent",
    },
    title: {
      text: "",
    },
    plotOptions: {
      column: {
        borderRadiusTopLeft: 16,
        borderRadiusTopRight: 16,
        stacking: "normal",
      },
    },
    legend: false,
    credits: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        return `${this.series.name}: ${getCurrencyValue(this.y)}`;
      },
    },
    dataLabels: {
      enabled: false,
    },
    xAxis: {
      reserveSpace: true,
      showFirstLabel: true,
      allowDecimals: false,
      lineColor: "transparent",
      tickWidth: 0,
      tickPixelInterval: 30,
      minRange: 1,
      labels: {
        step: 1,
        showFirstLabel: true,
        style: {
          fontSize: 14,
          fontFamily: "Roboto, sans-serif",
          color: "#516474",
        },
        formatter: data => {
          const birthYear =
            productData?.birthYear ??
            (productData?.currentAge
              ? dayjs().format("YYYY") - productData.currentAge
              : DEFAULT_BIRTH_YEAR);

          return `'${(data.value + birthYear).toString().slice(2)}`;
        },
      },
    },
    yAxis: {
      opposite: true,
      lineWidth: 0,
      labels: {
        step: 1,
        formatter: data => getCurrencyValueWithRoundSign(data.value),
      },
      title: {
        text: null,
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
  };

  return (
    <ChartSkeleton loading={!disableLoading && loading} height={200}>
      <div style={{ position: "relative" }}>
        {!hideChartTypeButtons && (
          <ChartTypeButtonsContainer
            align="center"
            background={
              token.income_chart_with_types_navbar_container_background
            }
            className="chart-navbar-container"
          >
            <StyledButton
              activebackground={
                token.income_chart_with_types_navbar_button_background
              }
              activecolor={token.income_chart_with_types_navbar_button_color}
              className={incomeChartType === "contributions" && "active"}
              onClick={() => setIncomeChartType("contributions")}
              type="text"
            >
              Annual Contributions
            </StyledButton>
            <StyledButton
              activebackground={
                token.income_chart_with_types_navbar_button_background
              }
              activecolor={token.income_chart_with_types_navbar_button_color}
              className={incomeChartType === "income" && "active"}
              disabled={isGrowthTypeRiskTolerance(
                productData?.investmentObjective
              )}
              onClick={() => setIncomeChartType("income")}
              type="text"
            >
              Annual Income
            </StyledButton>
          </ChartTypeButtonsContainer>
        )}

        {incomeChartType === "contributions" &&
        productData?.contributions === 0 ? (
          <div style={{ paddingTop: 40 }}>
            <NoContributionsContainer align="center" justify="center">
              No contributions selected
            </NoContributionsContainer>
          </div>
        ) : (
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              ...incomeChartConfig,
              series: chartSeries,
            }}
            ref={chartRef}
          />
        )}
        {showLegend && incomeChartType === "income" && (
          <Flex align="top" gap={10} justify="end" wrap="wrap">
            {getLegend()}
          </Flex>
        )}
      </div>
    </ChartSkeleton>
  );
};

export default ChartIncomeWithTypes;
