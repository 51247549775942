import { useContext, useState } from "react";
import State from "../../../../context";
import { Button, Flex, Row, Skeleton, theme } from "antd";
import MenuActions from "../../../../atom/MenuActions";
import ModalProductDetails from "../../../../organism/ModalProductDetails";
import ProposalGoalPreview from "../ProposalGoalPreview";

import {
  archiveProposalGoal,
  restoreProposalGoal,
} from "../../../../utils/request/manager";
import {
  isLockedByAdminView,
  isOrganizationOneascent,
} from "../../../../utils/helper/specialized";

import { ReactComponent as ActionsButton } from "../../../../icon/actions_menu.svg";

const ViewProposalGoals = ({
  handleAddGoal,
  handleCopyGoal,
  handleGeneratePdf,
  handleViewGoal,
  productsList,
}) => {
  const { token } = theme.useToken();
  const [state] = useContext(State);
  const [showArchivedGoals, setShowArchivedGoals] = useState(false);

  const getActions = () => [
    {
      key: "1",
      label: showArchivedGoals ? (
        <div onClick={() => setShowArchivedGoals(false)}>
          Hide archived goals
        </div>
      ) : (
        <div onClick={() => setShowArchivedGoals(true)}>
          Show archived goals
        </div>
      ),
      disabled:
        !state.getPreferenceValue("productMapArchive") ||
        !Object.keys(state.getPreferenceValue("productMapArchive"))?.length,
    },
  ];

  const handleArchiveGoal = goalId => {
    archiveProposalGoal({
      email: state._id,
      productMapId: goalId,
      organization: state.organization?.name,
    })
      .then(() => {
        state.showSuccess("Goal archived");
        state.setUserData();
      })
      .catch(console.log);
  };

  const handleCardClick = cardId => {
    state.setKeyValue("productDetailsId", cardId);
    state.openModal("productDetailsModal");
  };

  const handleRestoreGoal = goalId => {
    restoreProposalGoal({
      email: state._id,
      productMapId: goalId,
      organization: state.organization?.name,
    })
      .then(() => {
        state.showSuccess("Goal restored");
        state.setUserData();
      })
      .catch(console.log);
  };

  const handleDownloadProposalClick = (activeGoal, productId) => {
    if (isOrganizationOneascent(state.organization?.name)) {
      state.setKeyValue("activeGoal", activeGoal);
      handleGeneratePdf();
      return;
    }

    state.setKeyValue("activeGoal", activeGoal);
    state.setKeyValue("productDetailsId", productId);
    state.setKeyValue("selectedProduct", productId);

    localStorage.removeItem("growthChartSvg");
    localStorage.removeItem("barsChartSvg");
    localStorage.removeItem("pieChartSvg");
    localStorage.removeItem("pieChartWithLegendSvg");

    state.setKeyValue("viewMode", "pdf");
    state.openModal("productDetailsModal");
  };

  return (
    <Skeleton loading={state.loading}>
      <Row
        className="goals-page-header"
        justify="space-between"
        style={{ marginBottom: 20, width: 440 }}
      >
        <Flex align="center" gap={8}>
          <div
            style={{
              maxWidth: 250,
              fontSize: 20,
              color: "#253A58",
              wordBreak: "break-all",
            }}
          >
            {state.personalInfo?.firstName} {state.personalInfo?.lastName} Plans
          </div>
          <MenuActions items={getActions()}>
            <Button
              icon={<ActionsButton />}
              shape="circle"
              size="small"
              style={{ background: "#D9F3F7", width: 32 }}
              type="text"
            />
          </MenuActions>
        </Flex>
        <Button
          disabled={isLockedByAdminView({
            managerAccess: state.managerAccess,
          })}
          onClick={handleAddGoal}
          shape="round"
          size="small"
          style={{
            color: token.button_primary_text_color,
            backgroundColor: token.color_success,
            width: 124,
          }}
          type="primary"
        >
          + New Goal
        </Button>
      </Row>
      <Row align="stretch" style={{ gap: 40 }}>
        {Object.keys(state.getPreferenceValue("productMap")).map(
          (goalId, index) => (
            <ProposalGoalPreview
              archivedGoals={state.getPreferenceValue("productMapArchive")}
              goalsList={state.getPreferenceValue("productMap")}
              handleArchiveGoal={handleArchiveGoal}
              handleCardClick={handleCardClick}
              handleCopyGoal={handleCopyGoal}
              handleDownloadProposalClick={handleDownloadProposalClick}
              handleRestoreGoal={handleRestoreGoal}
              handleViewGoal={handleViewGoal}
              key={index}
              organization={state.organization?.name}
              productData={{
                ...state.getPreferenceValue("productMap")[goalId][
                  state.getPreferenceValue("productMap")[goalId].length - 1
                ],
                goalId,
              }}
              productsList={productsList}
              showError={state.showError}
            />
          )
        )}
        {showArchivedGoals &&
          Object.keys(state.getPreferenceValue("productMapArchive")).map(
            (goalId, index) => (
              <ProposalGoalPreview
                archivedGoals={state.getPreferenceValue("productMapArchive")}
                goalsList={state.getPreferenceValue("productMap")}
                handleArchiveGoal={handleArchiveGoal}
                handleCardClick={handleCardClick}
                handleCopyGoal={handleCopyGoal}
                handleDownloadProposalClick={handleDownloadProposalClick}
                handleRestoreGoal={handleRestoreGoal}
                handleViewGoal={handleViewGoal}
                key={index}
                organization={state.organization?.name}
                productData={{
                  ...state.getPreferenceValue("productMapArchive")[goalId].data[
                    state.getPreferenceValue("productMapArchive")[goalId].data
                      .length - 1
                  ],
                  goalId,
                }}
                productsList={productsList}
                showError={state.showError}
              />
            )
          )}
      </Row>
      <ModalProductDetails
        open={state.productDetailsModal}
        hand
        handleClose={() => state.closeModal("productDetailsModal")}
        product={productsList.find(it => it._id === state.productDetailsId)}
        productsList={productsList}
        investmentAssumptions={state.investmentAssumptions}
        organization={state.organization?.name?.toLowerCase()}
      />
    </Skeleton>
  );
};

export default ViewProposalGoals;
