import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Divider, Flex, Image, theme } from "antd";
import ReportHeader from "../../component/ReportHeader";

import StylesContainer from "./style";

import demo from "./image/demo.svg";

const ReportPageProposalHowToGet = ({
  personaName,
  productsList,
  proposalData,
}) => {
  const { token } = theme.useToken();

  const financialProductData = productsList?.find(
    it => it.name === proposalData?.productName
  );

  return (
    <StylesContainer id="reportPageProposalHowToGet">
      <Flex className="page-container" gap={29} vertical>
        <ReportHeader personaName={personaName} />

        <main>
          <section>
            <Flex gap={9} vertical>
              <h3>How do we get there?</h3>
              <p>
                Investments drive financial plans by turning long-term goals
                into actionable strategies. By allocating resources through
                growth-focused or income-generating investment strategies,
                individuals can achieve tangible results, aligning their
                portfolios with risk tolerance and financial objectives for
                sustained progress.
              </p>
            </Flex>
          </section>

          <section>
            <h3 style={{ fontWeight: 400 }}>Selected Investment Product:</h3>
            <h3>{proposalData?.productName}</h3>
            <Divider />
            <Flex gap={70}>
              <Flex gap={9} vertical>
                <h4>Overview</h4>
                <p>{financialProductData?.investmentPhilosophy}</p>
              </Flex>
              <Flex gap={9} vertical>
                <h4>Objective</h4>
                <p>{financialProductData?.objective}</p>
              </Flex>
            </Flex>
          </section>

          <section>
            <h3>Portfolio Mix</h3>
            <Divider />
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                chart: {
                  backgroundColor: "transparent",
                  height: 176,
                  spacingLeft: 0,
                  type: "pie",
                  width: 1000,
                },
                tooltip: {
                  outside: true,
                  style: {
                    zIndex: 9999,
                  },
                },
                title: {
                  text: null,
                },
                credits: {
                  enabled: false,
                },
                plotOptions: {
                  pie: {
                    size: 146,
                    innerSize: "60%",
                    showInLegend: true,
                    center: ["50%", "50%"],
                    borderColor: "#ffffff",
                    borderWidth: 2,
                  },
                },
                legend: {
                  enabled: true,
                  align: "right",
                  verticalAlign: "middle",
                  borderWidth: 0,
                  labelFormatter: function () {
                    return `<div style="width:245px; white-space: normal;">${
                      this.y + "% " + this.name
                    }</div>`;
                  },
                  itemStyle: {
                    fontSize: 14,
                  },
                },
                exporting: {
                  buttons: {
                    contextButton: {
                      enabled: false,
                    },
                  },
                },
                colors: token.chart_colors,
                series: [
                  {
                    colorByPoint: true,
                    data: productsList
                      .find(
                        product => product.name === proposalData?.productName
                      )
                      ?.assets?.map(it => ({
                        name: it.assetName,
                        y: it.investmentPercent,
                      })),
                    name: "Selected",
                    size: "100%",
                  },
                ],
              }}
            />
          </section>

          <section>
            <h3>Best, Worst & Average Years</h3>
            <Divider />
            <Flex gap={36}>
              <Flex flex="0 0 650px">
                <Image preview={false} src={demo} />
              </Flex>

              <Flex vertical>
                <h3>How does risk affect my trajectory?</h3>
                <p>
                  Stocks are high-risk with higher return potential, bonds offer
                  moderate risk with fixed income, and cash is the safest but
                  provides low returns. Diversified solutions help balance risk
                  and return by combining these assets, reducing volatility
                  while optimizing growth and income opportunities across
                  different market conditions.
                </p>
              </Flex>
            </Flex>
          </section>
        </main>
      </Flex>
    </StylesContainer>
  );
};

export default ReportPageProposalHowToGet;
