import { useContext, useEffect } from "react";
import State from "../../../../context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Flex, Form, Upload } from "antd";

import StyledModal from "./style";

import { addVbiFile } from "../../request";

import Close from "../../../../icon/Close";
import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";

const ModalVbi = ({ admin_view, onCancel, open }) => {
  const [state] = useContext(State);
  const [form] = Form.useForm();

  useEffect(() => {
    if (!open) return;

    form.resetFields();
  }, [open]);

  const handleUploadVbi = ({ fileData }) => {
    if (!fileData) return;

    const formData = new FormData();
    formData.append("file", fileData.file);
    formData.append(
      "advisor",
      admin_view ? state.active_advisor_email : state._id
    );
    formData.append("client", state.activeEmail);

    state.setKeyValue("loading", true);

    addVbiFile(formData)
      .then(() =>
        state.setAdminData().then(() => {
          state.showSuccess("VBI Audit file added");
          state.setKeyValue("open_modal_vbi", false);
        })
      )
      .catch(error => state.showError(error))
      .finally(() => state.setKeyValue("loading", false));
  };

  return (
    <StyledModal
      closeIcon={<Close />}
      footer={
        <Flex gap={4}>
          <Button
            disabled={state.loading}
            form="formUploadVbi"
            htmlType="submit"
            shape="round"
            style={{ background: "#D9F3F7", border: "unset" }}
          >
            Submit
          </Button>
          <Button onClick={onCancel} shape="round" type="text">
            Close
          </Button>
        </Flex>
      }
      onCancel={onCancel}
      open={open}
      title="VBI Audit"
    >
      <Flex gap={12} vertical>
        {!admin_view && (
          <>
            <section>
              <h3>Step 1</h3>
              <p>
                <a
                  href="https://apinode-storage-dev.s3.us-east-1.amazonaws.com/oneascent/vbi/OneAscentTemplateVBIScreening.xlsx"
                  target="_blank"
                >
                  <u>Click Here</u>
                </a>{" "}
                to download the VBI Audit spreadsheet.
              </p>
            </section>

            <section>
              <h3>Step 2</h3>
              <p>Make updates to the spreadsheet.</p>
            </section>
          </>
        )}

        <section>
          {admin_view ? (
            <h3>Upload screening summary</h3>
          ) : (
            <>
              <h3>Step 3</h3>
              <p>Upload your updated spreadsheet and click submit.</p>
            </>
          )}

          <Form
            form={form}
            id="formUploadVbi"
            layout="vertical"
            onFinish={handleUploadVbi}
          >
            <Form.Item name="fileData">
              <Upload.Dragger
                beforeUpload={() => false}
                maxCount={1}
                name="fileData"
              >
                <Flex align="center" gap={9} justify="center" vertical>
                  <FontAwesomeIcon
                    icon={faArrowUpFromBracket}
                    style={{ color: "#B2B2B2", fontSize: 27 }}
                  />
                  <p>
                    Drag file or{" "}
                    <u style={{ color: "#4897D1" }}>
                      <b>browse</b>
                    </u>{" "}
                    to upload
                  </p>
                </Flex>
              </Upload.Dragger>
            </Form.Item>
          </Form>
        </section>
      </Flex>
    </StyledModal>
  );
};

export default ModalVbi;
