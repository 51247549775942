import { useContext, useMemo, useState } from "react";
import dayjs from "dayjs";
import State from "../../../../../../context";
import { Button, Input, Table, theme, Flex, Dropdown } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import StyledContainer from "./style";

import { openProposal } from "../../../../../../utils/request/manager";
import {
  getCurrencyValue,
  getCurrencyValueWithRoundSign,
} from "../../../../../../utils/helper/general";
import {
  getLastSavedProposalProps,
  getManagedUsersTotalProposedAmount,
} from "../../../../../../utils/helper/specialized";
import { getManagedUserAssets } from "../../../../../oneascent/utils";

import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import MenuActions from "../../../../../../atom/MenuActions";

const ViewAdvisor = ({ advisorEmail, setActiveAdvisorEmail }) => {
  const { token } = theme.useToken();
  const [state] = useContext(State);
  const [searchedText, setSearchedText] = useState("");
  const [filterBy, setFilterBy] = useState("All");

  const advisorData = useMemo(
    () => state.managedUserManagers?.find(it => it.email === advisorEmail),
    [advisorEmail]
  );

  const getListClients = () =>
    advisorData?.userManager?.managedUsers?.filter(
      it => it.userType === "Registered" || it.userType === "ConvertedProspect"
    );

  const getListProposals = () => {
    const latestProposals = [];

    advisorData?.userManager?.managedUsers?.map(it => {
      if (it.valueMap?.productMap) {
        Object.keys(it.valueMap.productMap).map(proposalId => {
          const timestamp =
            it.valueMap.productMap[proposalId]?.at(-1)?.timestamp;

          if (timestamp && dayjs(timestamp).isAfter(dayjs().subtract(7, "day")))
            latestProposals.push({
              ...it,
              latestProposalData: it.valueMap.productMap[proposalId].at(-1),
            });
        });
      }
    });

    return latestProposals;
  };

  const getListProspects = () =>
    advisorData?.userManager?.managedUsers?.filter(
      it =>
        !it.isDeleted &&
        (it.userType === "Prospect" ||
          (it.entryPoint === "addedByUserManager" &&
            it.userType === "Assigned"))
    );

  const getFilteredUsers = () => {
    if (filterBy === "Prospects Only") {
      return getListProspects();
    } else if (filterBy === "Clients Only") {
      return getListClients();
    } else {
      return advisorData?.userManager?.managedUsers?.filter(
        it => !it.isDeleted
      );
    }
  };

  const getTableData = list =>
    list?.map(it => {
      const isProposalSaved = !!it.valueMap?.productMap;

      const lastSavedProposalProps = getLastSavedProposalProps(
        it.valueMap?.productMap
      );

      return {
        assets: isProposalSaved ? getManagedUserAssets(it) : "",
        clientName: it.firstName + " " + it.lastName,
        email: it.email,
        key: it.email,
        product: isProposalSaved ? lastSavedProposalProps.productName : "",
        proposalCreated:
          isProposalSaved &&
          dayjs(lastSavedProposalProps.timestamp).format("MM/DD/YYYY"),
      };
    });

  const columns = [
    {
      title: "Client Name",
      dataIndex: "clientName",
      key: "clientName",
      sorter: (a, b) => a.clientName?.localeCompare(b.clientName),
      render: (text, allValues) => (
        <a
          onClick={() =>
            openProposal({
              userEmail: allValues.email,
              organization: state.organization?.name,
              windowName: "adminView",
            })
          }
        >
          {text}
        </a>
      ),
      filteredValue: [searchedText],
      onFilter: (value, record) =>
        String(record.clientName).toLowerCase().includes(value.toLowerCase()) ||
        String(record.email).toLowerCase().includes(value.toLowerCase()),
    },
    {
      dataIndex: "email",
      key: "email",
      render: value => <a href={`mailto:${value}`}>{value}</a>,
      sorter: (a, b) => a.email?.localeCompare(b.email),
      title: "Email",
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      sorter: (a, b) => (a.product ?? "")?.localeCompare(b.product ?? ""),
    },
    {
      className: "cell-proposed-aum",
      title: "Proposal",
      dataIndex: "assets",
      key: "assets",
      render: value => getCurrencyValue(value),
      sorter: (a, b) => a.assets - b.assets,
    },
    {
      title: "Proposal Created",
      dataIndex: "proposalCreated",
      key: "proposalCreated",
      sorter: (a, b) => dayjs(a.proposalCreated) - dayjs(b.proposalCreated),
    },
    {
      className: "actions-menu-table-cell",
      fixed: "right",
      render: allValues => (
        <MenuActions
          items={[
            {
              disabled: !allValues.proposalCreated,
              key: "view",
              label: "View proposal",
              onClick: () => {
                openProposal({
                  userEmail: allValues.email,
                  organization: state.organization?.name,
                  windowName: "adminView",
                });
              },
            },
            {
              disabled: !allValues.proposalCreated,
              key: "download",
              label: "Download Proposal",
              onClick: () => {
                openProposal({
                  modeDownloadPdf: true,
                  userEmail: allValues.email,
                  organization: state.organization?.name,
                  windowName: "adminView",
                });
              },
            },
          ]}
        />
      ),
      width: 40,
    },
  ];

  return (
    <StyledContainer>
      <Flex align="start" gap={8} vertical>
        <Button
          icon={<FontAwesomeIcon icon={faAngleLeft} />}
          onClick={() => setActiveAdvisorEmail()}
          style={{
            color: token.color_secondary_button,
            paddingLeft: 0,
          }}
          type="text"
        >
          Back to Advisors
        </Button>

        <Flex gap={73} style={{ marginBottom: 40 }}>
          <Flex gap={2} vertical>
            <h3
              style={{
                color: "#516474",
                fontSize: 22,
                fontWeight: 300,
                margin: 0,
              }}
            >
              {advisorData?.firstName} {advisorData?.lastName}
            </h3>
            <p style={{ color: "#8396A6", margin: 0 }}>
              Financial Advisor
              <br />
              {advisorData?.userManager?.brokerDealerName ||
                advisorData?.userManager?.firmName}
            </p>
          </Flex>

          <Flex gap={45}>
            <div className="container-analytic-value">
              <p>Clients</p>
              <h5>{getListClients()?.length ?? 0}</h5>
            </div>
            <div className="container-analytic-value">
              <p>Prospects</p>
              <h5>{getListProspects()?.length ?? 0}</h5>
            </div>
            <div className="container-analytic-value">
              <p>Total Proposed AUM</p>
              <h5>
                {getCurrencyValueWithRoundSign(
                  getManagedUsersTotalProposedAmount(
                    advisorData?.userManager?.managedUsers
                  )
                )}
              </h5>
            </div>
            <div className="container-analytic-value">
              <p>Proposals</p>
              <h5>{getListProposals()?.length}</h5>
            </div>
          </Flex>
        </Flex>
      </Flex>

      <Flex align="center" gap={16} style={{ marginBottom: 16 }}>
        <Input
          style={{
            borderRadius: 25,
            height: 37,
            width: 240,
          }}
          placeholder="Search..."
          onChange={event => setSearchedText(event.target.value)}
        />
        <Dropdown
          className="container-filter-by"
          menu={{
            items: [
              {
                key: "All",
                label: "All",
                onClick: () => setFilterBy("All"),
              },
              {
                key: "Clients Only",
                label: "Clients Only",
                onClick: () => setFilterBy("Clients Only"),
              },
              {
                key: "Prospects Only",
                label: "Prospects Only",
                onClick: () => setFilterBy("Prospects Only"),
              },
            ],
          }}
          trigger={["click"]}
        >
          <span>
            Filter by: <b>{filterBy}</b>
          </span>
        </Dropdown>
      </Flex>

      <Table
        columns={columns}
        dataSource={getTableData(getFilteredUsers())}
        loading={state.loading}
        pagination={false}
        rowHoverable={false}
        scroll={{
          x: 1450,
        }}
      />
    </StyledContainer>
  );
};

export default ViewAdvisor;
