import { useContext } from "react";
import State from "../../../../../../context";
import { Button, theme } from "antd";

import StyledContainer from "./style";

const FeaturedVideoModalToggler = () => {
  const { token } = theme.useToken();
  const [state] = useContext(State);

  return (
    <StyledContainer gap={25}>
      <span className="description">
        Advisor Dashboard
        <br /> video widget settings.
      </span>
      <Button
        type="secondary"
        shape="round"
        size="small"
        style={{
          width: 118,
          background: token.color_secondary_button,
          color: token.color_secondary_button_text,
        }}
        onClick={() => state.openModal("openModalFeaturedVideo")}
      >
        Configure
      </Button>
    </StyledContainer>
  );
};

export default FeaturedVideoModalToggler;
