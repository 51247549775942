import { useContext, useEffect, useMemo } from "react";
import State from "../../../../../../context";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import { Flex } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import StyledContainer from "./style";

import {
  getAllManagedClientsOfAdvisors,
  getAllManagedProspectsOfAdvisors,
  getAllManagedUsersOfAdvisors,
  getLastSavedProposalProps,
  getManagedUsersTotalProposedAmount,
} from "../../../../../../utils/helper/specialized";
import { getCurrencyValueWithRoundSign } from "../../../../../../utils/helper/general";
import { addAdminAnalytic } from "../../../../../../utils/request/manager";

import {
  faArrowDownLong,
  faArrowUpLong,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";

dayjs.extend(quarterOfYear);

const StatisticContainer = ({ descriptionCopy, gap, title, value }) => (
  <Flex className="container-statistic" vertical>
    <h4>{title}</h4>
    <h3>{value}</h3>

    {descriptionCopy ? (
      <p>
        <span>{descriptionCopy}</span>
      </p>
    ) : (
      <p>
        {gap > 0 ? (
          <h5 className="up">
            {Math.round(gap * 100) / 100}%
            <FontAwesomeIcon icon={faArrowUpLong} />
          </h5>
        ) : gap < 0 ? (
          <h5 className="down">
            {Math.round(gap * 100) / 100}%
            <FontAwesomeIcon icon={faArrowDownLong} />
          </h5>
        ) : (
          <h5>0%</h5>
        )}
        <span>than last quarter</span>
      </p>
    )}
  </Flex>
);

const SectionAdvisorsAnalytic = () => {
  const [state] = useContext(State);

  const currentQuarter = dayjs().quarter();
  const currentYear = +dayjs().format("YYYY");

  const lastQuarter = currentQuarter === 1 ? 4 : currentQuarter - 1;
  const lastQuarterYear = currentQuarter === 1 ? currentYear - 1 : currentYear;

  useEffect(() => {
    if (state.loading || state.showOverlay) return;

    if (
      !state.analytics?.find(
        it => it.quarter === lastQuarter && it.year === lastQuarterYear
      )
    ) {
      // save last quarter analytic, when new quarter start
      addAdminAnalytic([
        ...(state.analytics ?? []),
        {
          quarter: lastQuarter,
          year: lastQuarterYear,
          pointAdded: new Date(),
          ...calculation,
        },
      ])
        .then(() => state.setAdminData())
        .catch(state.showError);
    }
  }, [state]);

  // if last quarter value is zero, need to remove Infinity value from the calculation
  const validateGap = gap => (gap === Infinity ? 100 : gap);

  const calculation = useMemo(() => {
    if (!state.managedUserManagers) return {};

    const lastQuarterData =
      state.analytics?.find(
        it => it.quarter === lastQuarter && it.year === lastQuarterYear
      ) ?? {};

    const advisorsList = state.managedUserManagers;

    const currentQuarterAdvisors = advisorsList.filter(it => {
      const creationYear = +dayjs(it.userManager.createDateTime.$date).format(
        "YYYY"
      );

      const creationQuarter = dayjs(
        it.userManager.createDateTime.$date
      ).quarter();

      return creationYear === currentYear && creationQuarter === currentQuarter;
    });

    const advisorsGap =
      (currentQuarterAdvisors?.length /
        (lastQuarterData?.advisorsQuantity - currentQuarterAdvisors?.length)) *
      100;

    const dateBeforeTodayOn30Days = dayjs().subtract(30, "day");
    const dateBeforeTodayOn31Days = dayjs().subtract(31, "day");
    const dateBeforeTodayOn60Days = dayjs().subtract(60, "day");

    const activeAdvisors30Days = advisorsList.filter(
      it =>
        it.userManager?.lastLoginTime?.$date &&
        dayjs(it.userManager.lastLoginTime.$date).isAfter(
          dateBeforeTodayOn30Days
        )
    );

    const activeAdvisors31To60Days = advisorsList.filter(
      it =>
        it.userManager?.lastLoginTime?.$date &&
        dayjs(it.userManager.lastLoginTime.$date).isAfter(
          dateBeforeTodayOn60Days
        ) &&
        dayjs(it.userManager.lastLoginTime.$date).isBefore(
          dateBeforeTodayOn31Days
        )
    );

    const activeAdvisorsGap =
      ((activeAdvisors30Days.length - activeAdvisors31To60Days.length) /
        activeAdvisors31To60Days.length) *
      100;

    const managedUsers = getAllManagedUsersOfAdvisors(
      state.managedUserManagers
    );

    const clientsList = getAllManagedClientsOfAdvisors(
      state.managedUserManagers
    );

    const currentQuarterClients = clientsList.filter(it => {
      if (!it.user?.createDateTime?.$date) return false;

      const creationYear = +dayjs(it.user.createDateTime.$date).format("YYYY");
      const creationQuarter = dayjs(it.user.createDateTime.$date).quarter();

      return creationYear === currentYear && creationQuarter === currentQuarter;
    });

    const clientsGap =
      (currentQuarterClients?.length /
        (lastQuarterData?.clientsQuantity - currentQuarterClients?.length)) *
      100;

    const prospectsList = getAllManagedProspectsOfAdvisors(
      state.managedUserManagers
    );

    const currentQuarterProspects = prospectsList.filter(it => {
      if (!it.user?.createDateTime?.$date) return false;

      const creationYear = +dayjs(it.user.createDateTime.$date).format("YYYY");
      const creationQuarter = dayjs(it.user.createDateTime.$date).quarter();

      return creationYear === currentYear && creationQuarter === currentQuarter;
    });

    const prospectsGap =
      (currentQuarterProspects?.length /
        (lastQuarterData?.prospectsQuantity -
          currentQuarterProspects?.length)) *
      100;

    const listCurrentQuarterSavedProposal = managedUsers.filter(it => {
      const proposalSavedDate =
        it.proposalSavedDate?.$date ??
        (it.valueMap?.productMap &&
          getLastSavedProposalProps(it.valueMap.productMap)?.timestamp);

      if (!proposalSavedDate) return false;

      const proposalSavedYear = +dayjs(proposalSavedDate).format("YYYY");
      const proposalSavedQuarter = dayjs(proposalSavedDate).quarter();

      return (
        proposalSavedYear === currentYear &&
        proposalSavedQuarter === currentQuarter
      );
    });

    const currentQuarterProposedAum = getManagedUsersTotalProposedAmount(
      listCurrentQuarterSavedProposal
    );

    const currentQuarterProposedAumGap =
      (currentQuarterProposedAum /
        (getManagedUsersTotalProposedAmount(managedUsers) -
          currentQuarterProposedAum)) *
      100;

    const latestProposalDateList = [];

    managedUsers
      .map(it => {
        if (it.valueMap?.productMap) {
          Object.keys(it.valueMap.productMap).map(proposalId => {
            const timestamp =
              it.valueMap.productMap[proposalId].at(-1).timestamp;

            if (
              timestamp &&
              dayjs(timestamp).isAfter(dayjs().subtract(7, "day"))
            )
              latestProposalDateList.push(timestamp);
          });
        }
      })
      .flat();

    console.log("Analytic debugging =>", {
      activeAdvisors30Days,
      activeAdvisors31To60Days,
      currentQuarterProposedAum,
      listCurrentQuarterSavedProposal,
    });

    return {
      activeAdvisorsGap: validateGap(activeAdvisorsGap),
      activeAdvisorsQuantity: activeAdvisors30Days?.length,
      advisorsLastLoginTime: advisorsList.map(
        it => it.userManager?.lastLoginTime?.$date
      ),
      advisorsGap: validateGap(advisorsGap),
      advisorsQuantity: advisorsList?.length,
      clientsGap: validateGap(clientsGap),
      clientsQuantity: clientsList?.length,
      latestProposalsQuantity: latestProposalDateList?.length,
      prospectsGap: validateGap(prospectsGap),
      prospectsQuantity: prospectsList?.length,
      totalProposedAum: currentQuarterProposedAum,
      totalProposedAumGap: currentQuarterProposedAumGap,
    };
  }, [state.managedUserManagers]);

  return (
    <StyledContainer>
      <StatisticContainer
        gap={calculation.advisorsGap}
        title="Total Advisors"
        value={calculation.advisorsQuantity}
      />
      <StatisticContainer
        gap={calculation.clientsGap}
        title="Total Clients"
        value={calculation.clientsQuantity}
      />
      <StatisticContainer
        gap={calculation.prospectsGap}
        title="Total Prospects"
        value={calculation.prospectsQuantity}
      />
      <StatisticContainer
        gap={calculation.activeAdvisorsGap}
        title="Active Advisor Users"
        value={calculation.activeAdvisorsQuantity}
      />
      <StatisticContainer
        gap={calculation.totalProposedAumGap}
        title="Total Proposed AUM"
        value={getCurrencyValueWithRoundSign(calculation.totalProposedAum)}
      />
      <StatisticContainer
        descriptionCopy="In the past 7 days"
        title="Latest Proposals"
        value={
          <a
            onClick={() => state.setKeyValue("openModalLatestProposals", true)}
          >
            {calculation.latestProposalsQuantity}
            <span style={{ fontSize: 13, marginLeft: 11 }}>
              View <FontAwesomeIcon icon={faArrowRight} />
            </span>
          </a>
        }
      />
    </StyledContainer>
  );
};

export default SectionAdvisorsAnalytic;
