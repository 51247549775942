import styled from "styled-components";

const StyledContainer = styled.div`
  display: flex;
  gap: 28px;
  overflow: auto;

  .container-statistic {
    h4 {
      color: #5a607f;
      margin: 0;
      width: max-content;
    }

    h3 {
      color: #131523;
      font-size: 22px;
      font-weight: bold;
      height: 29px;
      line-height: 29px;
      margin: 0;
    }

    p {
      align-items: center;
      display: flex;
      gap: 4px;
      line-height: 21px;
      margin: 0;

      h5 {
        align-items: center;
        display: flex;
        font-size: 16px;
        gap: 2px;
        margin: 0;

        &.up {
          color: #47951d;
        }

        &.down {
          color: #bc141a;
        }
      }

      span {
        color: #7e84a3;
        font-size: 12px;
        width: max-content;
      }

      svg {
        font-size: 12px;
      }
    }
  }
`;

export default StyledContainer;
