import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import State from "../../../../context";
import { Button, ConfigProvider, Flex, Layout, Menu, theme } from "antd";
import MenuProfile from "../../../../molecule/MenuProfile";
import ModalConversationDetails from "../../component/ModalConversationDetails";
import ModalManagerSettings from "../../../manager/component/ModalManagerSettings";
import SectionAskAdvice from "../../../../organism/SectionAskAdvice";

import StyledContainer from "./style";

import { ReactComponent as Logo } from "../../image/logo.svg";
import { ReactComponent as PennyAi } from "../../image/icon_penny.svg";
import { ReactComponent as SidebarCompanies } from "./image/companies.svg";
import { ReactComponent as SidebarPenny } from "./image/penny.svg";
import { ReactComponent as SidebarProducts } from "./image/products.svg";
import { ReactComponent as SidebarUsers } from "./image/users.svg";

const TemplateSuperAdminAuthorized = ({ children }) => {
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const [state, setState] = useContext(State);

  const getDefaultSelectedKey = () => {
    switch (location.pathname) {
      case "/super-admin/companies":
        return "companies";
      case "/super-admin/users":
        return "users";
      case "/super-admin/products":
        return "products";
      case "/super-admin/conversations":
        return "conversations";
      default:
        return "";
    }
  };

  return (
    <StyledContainer>
      <ConfigProvider
        theme={{
          components: {
            Menu: {
              activeBarBorderWidth: 0,
              itemSelectedBg: token.colorPrimary,
              itemSelectedColor: "#fff",
              itemHoverBg: token.colorPrimary,
              itemHoverColor: "#fff",
            },
          },
        }}
      >
        <Layout>
          <Layout.Header
            style={{
              background: "#fff",
              borderBottom: "1px solid #C9C8C8",
              padding: "0 24px",
            }}
          >
            <Flex
              align="center"
              justify="space-between"
              style={{ height: "100%" }}
            >
              <Logo />
              <Button
                icon={<PennyAi />}
                onClick={() => state.setKeyValue("openModalAskAdvice", true)}
                shape="round"
                style={{ background: "#E6F4EF" }}
                type="text"
              >
                <span>
                  Ask<b>Penny</b>
                </span>
              </Button>
            </Flex>
          </Layout.Header>

          <Layout style={{ height: "calc(100vh - 64px)" }}>
            <Layout.Sider
              breakpoint="md"
              collapsedWidth="0"
              style={{ background: token.sidebar_background }}
              width={token.sidebar_width}
            >
              <Flex
                align="center"
                justify="space-between"
                style={{ height: "100%", padding: 16 }}
                vertical
              >
                <Menu
                  defaultSelectedKeys={[getDefaultSelectedKey()]}
                  items={[
                    {
                      key: "companies",
                      label: (
                        <>
                          <div className="container-sidebar-icon">
                            <SidebarCompanies />
                          </div>
                          Companies
                        </>
                      ),
                      onClick: () => navigate("/super-admin/companies"),
                    },
                    {
                      key: "users",
                      label: (
                        <>
                          <div className="container-sidebar-icon">
                            <SidebarUsers />
                          </div>
                          Users
                        </>
                      ),
                      onClick: () => navigate("/super-admin/users"),
                    },
                    {
                      key: "products",
                      label: (
                        <>
                          <div className="container-sidebar-icon">
                            <SidebarProducts />
                          </div>
                          Products
                        </>
                      ),
                      onClick: () => navigate("/super-admin/products"),
                    },
                    {
                      key: "conversations",
                      label: (
                        <>
                          <div className="container-sidebar-icon">
                            <SidebarPenny />
                          </div>
                          Penny
                        </>
                      ),
                      onClick: () => navigate("/super-admin/conversations"),
                    },
                    {
                      key: "next-advisors",
                      label: "next-advisors",
                      onClick: () => navigate("/super-admin/next-advisors"),
                    },
                    {
                      key: "next-leads",
                      label: "next-leads",
                      onClick: () => navigate("/super-admin/next-leads"),
                    },
                  ]}
                  style={{ background: "transparent", width: "100%" }}
                />
                <MenuProfile logoutLink="/login" />
              </Flex>
            </Layout.Sider>
            <Layout.Content style={{ background: "#fff", overflow: "auto" }}>
              {children}
            </Layout.Content>
          </Layout>
        </Layout>

        <ModalConversationDetails
          onCancel={() => state.closeModal("conversationModal")}
          open={state.conversationModal}
        />
        <ModalManagerSettings
          onCancel={() => state.setKeyValue("openModalManagerSettings", false)}
          open={state.openModalManagerSettings}
        />
        <SectionAskAdvice state={state} setState={setState} />
      </ConfigProvider>
    </StyledContainer>
  );
};

export default TemplateSuperAdminAuthorized;
