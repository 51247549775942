import { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import State from "../../../../context";
import { Button, Divider, theme, Typography } from "antd";
import RowUpdateProgress from "./components/RowUpdateProgress";

import StyledModal from "./style";

import { getGoalIdByGoalName } from "../../../../utils/helper/specialized";
import { getSavedProgressValuesByGoalId } from "../../helpers";

import Close from "../../../../icon/Close";

const ModalUpdateProgress = ({
  goalName,
  progressValues,
  saveProgress,
  setProgressValues,
}) => {
  const { token } = theme.useToken();
  const [state] = useContext(State);
  const [updatedProgressValues, setUpdatedProgressValues] =
    useState(progressValues);
  const [newProgressItemData, setNewProgressItemData] = useState({});
  const goalId = getGoalIdByGoalName(
    state.getPreferenceValue("productMap"),
    goalName
  );

  useEffect(() => {
    setUpdatedProgressValues(progressValues);
  }, [progressValues]);

  const handleCancel = () => {
    setProgressValues(
      getSavedProgressValuesByGoalId(goalId, state.productScenariosMap)
    );
    state.closeModal("updateProgressModal");
  };

  const handleSave = () => {
    saveProgress(
      newProgressItemData.date?.$date && newProgressItemData.actualTotal
        ? updatedProgressValues
          ? [...updatedProgressValues, newProgressItemData]
          : [newProgressItemData]
        : updatedProgressValues,
      () => setNewProgressItemData({})
    );
  };

  return (
    <StyledModal
      centered
      className="update-progress-menu"
      closeIcon={<Close />}
      destroyOnClose
      footer={
        <>
          <Button
            id="updateProgressModalSaveButton"
            type="primary"
            shape="round"
            block
            onClick={handleSave}
            style={{ maxWidth: 280 }}
          >
            Save Progress
          </Button>
          <Button
            id="updateProgressModalCancelButton"
            type="text"
            shape="round"
            block
            onClick={handleCancel}
            style={{ maxWidth: 280 }}
          >
            Cancel
          </Button>
        </>
      }
      onCancel={handleCancel}
      open={state.updateProgressModal}
      title="Update Progress"
      width={348}
    >
      <RowUpdateProgress
        data={newProgressItemData}
        goalName={goalName}
        setNewProgressItemData={setNewProgressItemData}
        showLabels
        showRemoveButton={false}
      />
      <Typography.Text>History</Typography.Text>
      <Divider
        style={{ margin: "4px 0 18px", borderTopColor: token.color_grey_2 }}
      />
      {updatedProgressValues
        ?.sort((a, b) => dayjs(b.date.$date) - dayjs(a.date.$date))
        ?.map((it, index) => (
          <RowUpdateProgress
            key={index}
            goalName={goalName}
            data={it}
            showLabels={index === 0}
            setProgressValues={setUpdatedProgressValues}
          />
        )) ?? ""}
    </StyledModal>
  );
};

export default ModalUpdateProgress;
