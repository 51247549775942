import axios from "axios";
import dayjs from "dayjs";

import {
  DEFAULT_ADVISOR_FEE,
  DEFAULT_INFLATION_RATE,
  DEFAULT_RETIREMENT_AGE,
} from "../../../utils/constant";

import { getPercentValue } from "../../../utils/helper/general";

export const addProposal = ({ email, proposalData }) =>
  axios.post(
    `${process.env.REACT_APP_API_GATEWAY}/user/valueMap/prospectObjective/${email}`,
    proposalData,
    {
      withCredentials: true,
    }
  );

export const getEtfAll = () =>
  axios
    .get(`${process.env.REACT_APP_FASTAPI}/level/etf`)
    .then(response => response.data);

export const getEtfByTicker = ({ benchmark_ticker, ticker }) =>
  axios
    .post(`${process.env.REACT_APP_FASTAPI}/level/etf-stats`, {
      ticker,
      benchmark_ticker,
    })
    .then(response => response.data.data);

export const getEtfDrawdownsByDate = ({ end_date, ticker, start_date }) =>
  axios
    .post(`${process.env.REACT_APP_FASTAPI}/level/etf-drawdowns`, {
      end_date,
      start_date,
      ticker,
    })
    .then(response => response.data);

export const getLiveAssessmentV2 = requestBody =>
  axios
    .post(
      `${process.env.REACT_APP_FASTAPI}/level/projectionAndStressTest`,
      {
        objective: {
          advisorFee: getPercentValue(
            requestBody.advisorFee || DEFAULT_ADVISOR_FEE
          ),
          annuities: requestBody.annuities_isOn ? requestBody.annuities : 0,
          birthYear: requestBody.birthYear,
          contributions: requestBody.contributions,
          inflationRate: requestBody.viewWithInflation
            ? DEFAULT_INFLATION_RATE
            : undefined,
          investmentAmount: requestBody.investmentAmount,
          investmentDuration: requestBody.investmentDuration,
          investmentObjective: requestBody.investmentObjective,
          otherIncome: requestBody.otherIncome_isOn
            ? requestBody.otherIncome
            : 0,
          riskTolerance: requestBody.riskTolerance,
          socialSecurity: requestBody.socialSecurity_isOn
            ? requestBody.socialSecurity
            : 0,
          withdrawalLevel: requestBody.withdrawalLevel,
          yearToStartWithdrawals: requestBody.yearToStartWithdrawals,
        },
        productId: requestBody.productId,
      },
      {
        withCredentials: true,
      }
    )
    .then(response => response.data);

export const getLiveSimulationV2 = requestBody =>
  axios
    .post(
      `${process.env.REACT_APP_FASTAPI}/level/liveSimulations2`,
      {
        organization: "level2",
        objective: {
          advisorFee: getPercentValue(
            requestBody.advisorFee || DEFAULT_ADVISOR_FEE
          ),
          amount: requestBody.investmentAmount,
          beginWithdrawal: "",
          contributions: requestBody.contributions,
          currentAge:
            requestBody.currentAge ??
            dayjs().format("YYYY") - requestBody.birthYear,
          currentAgeRange: "",
          currentSpouseAgeRange: "",
          decisionInfluence: "",
          decliningMarketWithholdingPeriod: "",
          email: requestBody.email,
          householdIncome: 0,
          incomeChartType: "contributions",
          incomeExpectations: "",
          inflationRate: requestBody.viewWithInflation
            ? DEFAULT_INFLATION_RATE
            : undefined,
          investmentsToAlignWith: "",
          investmentAmount: requestBody.investmentAmount,
          investmentDuration: requestBody.investmentDuration,
          investmentKnowledge: "",
          investmentObjective: requestBody.investmentObjective,
          investmentsToAvoid: "",
          organization: "Level",
          proposalSavedDate: new Date(),
          retirementAge: requestBody.retirementAge ?? DEFAULT_RETIREMENT_AGE,
          riskTolerance: requestBody.riskTolerance,
          time: requestBody.investmentDuration,
          timestamp: requestBody.timestamp,
          volatilityTolerance: "",
          withdrawalLevel: requestBody.withdrawalLevel,
          yearToStartWithdrawals: requestBody.yearToStartWithdrawals,
        },
        productId: requestBody.productId,
        inputSettings: {},
        montecarlo: true,
        historicalAnalysis: true,
      },
      {
        withCredentials: true,
      }
    )
    .then(response => response.data);

export const getLiveStressorV2 = requestBody =>
  axios
    .post(
      `${process.env.REACT_APP_FASTAPI}/level/projectionAndStressTest`,
      {
        marketEvent: requestBody.marketEvent,
        objective: {
          advisorFee: getPercentValue(
            requestBody.advisorFee ?? DEFAULT_ADVISOR_FEE
          ),
          amount: requestBody.investmentAmount,
          contributions: requestBody.contributions,
          currentAge:
            requestBody.currentAge ??
            dayjs().format("YYYY") - requestBody.birthYear,
          incomeChartType: requestBody.incomeChartType ?? "income",
          inflationRate: requestBody.viewWithInflation
            ? DEFAULT_INFLATION_RATE
            : undefined,
          investmentAmount: requestBody.investmentAmount,
          investmentDuration: requestBody.investmentDuration,
          investmentObjective: requestBody.investmentObjective,
          retirementAge: requestBody.retirementAge,
          time: requestBody.investmentDuration,
          timestamp: requestBody.timestamp,
          withdrawalLevel: requestBody.withdrawalLevel,
          yearToStartWithdrawals: requestBody.yearToStartWithdrawals,
        },
        productId: requestBody.productId,
      },
      {
        withCredentials: true,
      }
    )
    .then(response => response.data);

export const getMc = requestBody =>
  axios
    .post(
      `${process.env.REACT_APP_FASTAPI}/level/mc`,
      {
        objective: {
          contributions: requestBody.contributions,
          inflationRate: requestBody.viewWithInflation
            ? DEFAULT_INFLATION_RATE
            : undefined,
          investmentAmount: requestBody.investmentAmount,
          investmentDuration: requestBody.investmentDuration,
          investmentObjective: requestBody.investmentObjective,
          withdrawalLevel: requestBody.withdrawalLevel,
          yearToStartWithdrawals: requestBody.yearToStartWithdrawals,
        },
        productId: requestBody.productId,
      },
      {
        withCredentials: true,
      }
    )
    .then(response => response.data);

export const updateProposal = ({ email, proposalData }) =>
  axios.put(
    `${process.env.REACT_APP_API_GATEWAY}/user/valueMap/prospectObjective/${email}`,
    proposalData,
    {
      withCredentials: true,
    }
  );
