import { Button } from "antd";

import StylesContainer from "./style";

const CompareCardPlaceholder = ({ handleClick, loading }) => (
  <StylesContainer align="center" justify="center">
    <Button
      disabled={loading}
      onClick={handleClick}
      shape="round"
      style={{ height: 28 }}
    >
      Add an Investment
    </Button>
  </StylesContainer>
);

export default CompareCardPlaceholder;
