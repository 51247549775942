export const getLatestSavedProposalId = productsMap => {
  let latestSavedProposalId;

  Object.keys(productsMap).map(key => {
    const productLatestData = productsMap[key].at(-1);

    if (
      !latestSavedProposalId ||
      productLatestData?.timestamp > latestSavedProposalId?.timestamp
    ) {
      latestSavedProposalId = key;
    }
  });

  return latestSavedProposalId;
};
