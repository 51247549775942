import PageAdvisorProspects from "../../manager/page/PageAdvisorProspects";
import PageLevelAdvisorClients from "../pages/PageLevelAdvisorClients";
import PageLevelAdvisorDash from "../pages/PageLevelAdvisorDash";
import PageLevelProducts from "../pages/PageLevelProducts";
import PageMarketing from "../../manager/page/PageMarketing";

import DashboardIconSmall from "../../../icon/DashboardIconSmall";
import DocumentPage from "../../../icon/DocumentPage";
import Users from "../../../icon/Users";
import BoxOpen from "../../../icon/BoxOpen";

export default [
  {
    path: "/level-advisor-portal/dashboard",
    Component: PageLevelAdvisorDash,
    Icon: DashboardIconSmall,
    text: "Dashboard",
  },
  {
    path: "/level-advisor-portal/prospects",
    Component: PageAdvisorProspects,
    Icon: Users,
    text: "Prospects",
  },
  {
    path: "/level-advisor-portal/clients",
    Component: PageLevelAdvisorClients,
    Icon: Users,
    text: "Clients",
  },
  {
    path: "/level-advisor-portal/products",
    Component: PageLevelProducts,
    Icon: BoxOpen,
    text: "Products",
  },
  {
    path: "/level-advisor-portal/marketing",
    Component: PageMarketing,
    Icon: DocumentPage,
    text: "Marketing",
  },
];
