import dayjs from "dayjs";

export const DEFAULT_BIRTH_YEAR = 1987;

export const DEFAULT_PRODUCT_ACCENT_COLOR = "#93191F";

export const DEFAULT_ADVISOR_FEE = 1;

export const DEFAULT_RETIREMENT_AGE = 67;

export const DEFAULT_WITHDRAWAL_START_YEAR = 4;

export const DEFAULT_WITHDRAWAL_LEVEL = "1%";

export const DEFAULT_INFLATION_RATE = "3%";

export const HUBSPOT_FORM_ID_ASK_ADVISOR =
  "a208eb1b-8542-44d0-b620-717d34166291";

export const MAX_COMPARE_PRODUCTS = 3;

export const MC_DEFAULT_SETTINGS = {
  mcExpectedReturn: 8.88,
  mcVolatilityReturn: 18.54,
  mcExpectedInflation: 0,
  mcVolatilityInflation: 0,
};

export const MIN_BIRTH_YEAR = 1920;

export const NEW_CLIENT_DEFAULT_DATA = {
  assets: 0,
  firstName: "",
  lastName: "",
  email: "",
  doNotNotifyManagedUser: true,
  enableOnboardingQuestions: true,
  maritalStatus: "single",
  planType: "basic",
  retirementAge: DEFAULT_RETIREMENT_AGE,
  scenarioName: "Scenario 1",
  startDate: dayjs(),
  yearBorn: 1980,
  totalSavings: 0,
  annualIncome: 0,
  savingsPerMonth: 0,
  accounts: [
    {
      key: 0,
      accountType: "529 Plan",
      startingBalance: 0,
      startAge: 43,
      endAge: 90,
      growth: 5,
    },
    {
      key: 1,
      accountType: "Other Account",
      startingBalance: 0,
      startAge: 43,
      endAge: 90,
      growth: 4.5,
    },
  ],
  incomes: [
    {
      key: 0,
      incomeType: "Regular Income",
      annualAmount: 0,
      startAge: 43,
      endAge: 90,
    },
    {
      key: 1,
      incomeType: "Social Security",
      annualAmount: 21500,
      startAge: 43,
      endAge: 90,
    },
  ],
  expenses: [
    {
      key: 0,
      expenseType: "Vehicle Payment",
      amount: 2500,
      frequency: "Monthly",
      startAge: 43,
      endAge: 90,
    },
    {
      key: 1,
      expenseType: "Property Taxes",
      amount: 2500,
      frequency: "Monthly",
      startAge: 43,
      endAge: 90,
    },
  ],
};

export const PENDO_HOSTNAMES_LIST = [
  "align.oneascent.com",
  "go.ontrajectory.com",
  "www.goals.ontrajectory.com",
  "leveldemo.clevercx.com",
];

export const PENNY_FEEDBACK_FORM_URL =
  "https://share.hsforms.com/1TfzAHOV8SzSlz6j_6u1-4gdch11";

export const PENNY_TIPS_LIST = [
  {
    title: "How should I plan for retirement?",
    description: "Understand the basics of retirement planning.",
  },
  {
    title: "How much should I have in my emergency savings?",
    description: "Penny can help you figure out the right amount to save.",
  },
  {
    title: "What are the best questions to ask a financial advisor?",
    description: "Let Penny help you prepare for a conversation.",
  },
];

export const PRODUCTS_FACT_SHEETS_LIST = {
  "OneAscent Peak Equity Core":
    "https://ontrajectory.s3.amazonaws.com/oneascent/factsheets/OneAscent-Peak-Equity-Core-Fact-Sheet.pdf",
  "OneAscent Peak Growth Core":
    "https://ontrajectory.s3.amazonaws.com/oneascent/factsheets/OneAscent-Peak-Growth-Core-Fact-Sheet.pdf",
  "OneAscent Peak Moderate Core":
    "https://ontrajectory.s3.amazonaws.com/oneascent/factsheets/OneAscent-Peak-Moderate-Core-Fact-Sheet.pdf",
  "OneAscent Peak Conservative Core":
    "https://ontrajectory.s3.amazonaws.com/oneascent/factsheets/OneAscent-Peak-Conservative-Core-Fact-Sheet.pdf",
  "OneAscent Peak Preservation Core":
    "https://ontrajectory.s3.amazonaws.com/oneascent/factsheets/OneAscent-Peak-Preservation-Core-Fact-Sheet.pdf",
  "Aggressive Growth Strategy": "",
  "Moderate Growth Strategy": "",
  "Conservative Growth Strategy": "",
};

export const TERMS_LINK = "https://go.ontrajectory.com/terms-of-service";
