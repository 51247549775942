import { useContext, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import dayjs from "dayjs";
import State from "../../../../context";
import { Button, Col, Flex, Row, Space, theme } from "antd";
import GaugeSection from "./components/GaugeSection";
import CardProduct from "../../../../molecule/CardProduct";
import ChartComparisonGrowth from "../../../../molecule/ChartComparisonGrowth";
import ChartIncomeWithTypes from "../../../../molecule/ChartIncomeWithTypes";
import InvestmentAssumptions from "./components/InvestmentAssumptions";

import {
  getLiveAssessment,
  getLiveSimulation,
} from "../../../../utils/request/regularApp";
import { getPercentValue } from "../../../../utils/helper/general";
import { isGrowthTypeRiskTolerance } from "../../../../utils/helper/specialized";

const ProposalGoalDashboard = ({
  activeGoal,
  handleCardClick,
  handleProposalDataChange,
  openCompareMode,
  productsList,
  proposalData,
}) => {
  const [state] = useContext(State);
  const { token } = theme.useToken();
  const [loading, setLoading] = useState(true);
  const [productsLiveAssessment, setProductsLiveAssessment] = useState([]);
  const [productLiveSimulation, setProductLiveSimulation] = useState({});

  useEffect(() => {
    if (state.selectedProduct) {
      if (
        !isGrowthTypeRiskTolerance(proposalData?.investmentObjective) &&
        proposalData?.investmentDuration <= proposalData?.yearToStartWithdrawals
      ) {
        state.showWarning(
          "Length of investment must be greater than the begin withdrawals year"
        );
        return;
      }

      setLoading(true);
      debouncedCalculationsFetch();
    }
  }, [state.selectedProduct, productsList, proposalData]);

  const debouncedCalculationsFetch = useDebouncedCallback(() => {
    const requestBody = {
      organization:
        state.organization?.name?.toLowerCase() === "level"
          ? "level2"
          : state.organization?.name?.toLowerCase(),
      objective: {
        ...proposalData,
        advisorFee: getPercentValue(proposalData.advisorFee),
        birthYear: proposalData.currentAge ? undefined : proposalData.birthYear,
        withdrawalLevel: getPercentValue(proposalData.withdrawalLevel ?? 0),
      },
      productId: state.selectedProduct,
    };

    getLiveSimulation({
      ...requestBody,
      inputSettings: {},
      montecarlo: true,
      historicalAnalysis: true,
    })
      .then(data => {
        // required by regression testing scripts
        window.lastQuickSim = data.props?.lastQuickSim;

        setProductLiveSimulation({
          productId: state.selectedProduct,
          ...data,
        });
      })
      .catch(error => state.showError(error));

    getLiveAssessment({
      ...requestBody,
      activeGoal,
    })
      .then(data => {
        setProductsLiveAssessment([
          {
            productId: state.selectedProduct,
            ...data,
          },
        ]);

        if (data?.incomeBarchart?.totalWithdrawals) {
          // calculate Insufficient Funds Starting In
          const insufficientFundsData =
            data?.incomeBarchart?.totalWithdrawals?.find(
              it => it[1] > 0 && it[1] < data.incomeBarchart.withdrawalSize
            );

          if (insufficientFundsData) {
            const birthYear =
              proposalData.birthYear ??
              dayjs().format("YYYY") - proposalData.currentAge;

            state.setKeyValue(
              "insufficientFundsStartingIn",
              birthYear + insufficientFundsData[0]
            );
          } else {
            state.setKeyValue("insufficientFundsStartingIn", null);
          }
        }

        setTimeout(() => setLoading(false), 1000);
      })
      .catch(error => state.showError(error));
  }, 2000);

  return (
    <Row style={{ gap: 40, paddingTop: 32 }} wrap={false}>
      <Col flex="174px">
        <InvestmentAssumptions
          handleProposalDataChange={handleProposalDataChange}
          managerAccess={state.managerAccess}
          openGoalSettingsModal={() => state.openModal("goalSettingsModal")}
          organization={state.organization?.name}
          proposalData={proposalData}
        />
      </Col>
      <Col flex="auto">
        <Row wrap={false}>
          <Col flex="auto">
            <Row gutter={[22, 22]} style={{ width: "100%", marginBottom: 30 }}>
              <Col span={24}>
                <ChartComparisonGrowth
                  height={233}
                  loading={loading}
                  productsList={productsList}
                  productsLiveAssessment={productsLiveAssessment}
                  proposalData={proposalData}
                  viewMode={state.viewMode}
                />
              </Col>
              <Col span={24}>
                <ChartIncomeWithTypes
                  loading={loading}
                  productsList={productsList}
                  productsLiveAssessment={productsLiveAssessment}
                  productData={proposalData}
                  showLegendAmounts={true}
                  viewMode={state.viewMode}
                />
              </Col>
            </Row>
          </Col>
          <Col flex="170px">
            <GaugeSection
              investmentObjective={proposalData?.investmentObjective}
              loading={loading}
              productLiveSimulation={productLiveSimulation}
            />
          </Col>
        </Row>
        <Space size={[59, 10]} wrap>
          <p
            style={{
              minWidth: 145,
              color: token.color_textHeading,
              fontSize: 18,
              margin: 0,
            }}
          >
            Product Recommendation
          </p>
          <Space size={12}>
            <Button
              type="primary"
              shape="round"
              size="small"
              onClick={openCompareMode}
            >
              Compare Mode
            </Button>
            <Button
              type="primary"
              shape="round"
              size="small"
              onClick={() =>
                state.setKeyValue("openModalRelatedProducts", true)
              }
            >
              View Other Products
            </Button>
          </Space>
        </Space>
        {state.selectedProduct && (
          <Flex gap={20} style={{ marginTop: 10, overflowX: "auto" }}>
            <div
              style={{
                minWidth: 567,
                overflowX: "auto",
              }}
            >
              <CardProduct
                actions={[
                  {
                    buttonCopy: "Details",
                    buttonWidth: 165,
                    key: "viewDetails",
                    onSelect: product => handleCardClick(product?._id),
                  },
                ]}
                handleCardClick={handleCardClick}
                labels={[
                  productsList.find(it => it._id === state.selectedProduct)
                    ?.riskTolerance,
                  productsList.find(it => it._id === state.selectedProduct)
                    ?.allocationType,
                ]}
                product={productsList.find(
                  it => it._id === state.selectedProduct
                )}
                showChart
                showObjective
                width="567px"
                viewMode={state.viewMode}
              />
            </div>
          </Flex>
        )}
      </Col>
    </Row>
  );
};

export default ProposalGoalDashboard;
