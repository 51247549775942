import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import State from "../../../../context";
import { Layout, theme, Flex, Menu, Button, ConfigProvider } from "antd";
import MenuProfile from "../../../../molecule/MenuProfile";
import ProposalPdfContentHolder from "../../pdf/template/ProposalPdfContentHolder";
import SectionAskAdvice from "../../../../organism/SectionAskAdvice";
import ViewOneGoal from "./component/ViewOneGoal";
import ViewAllGoal from "./component/ViewAllGoal";

import StyledContainer from "./style";

import { PDF_PAGE_LIST } from "../../constants";

import { getHtml2pdfPromiseList } from "../../../../utils/pdf";
import {
  getOrganizationManagerData,
  getUserManagerData,
} from "../../../../utils/request/manager";

import { ReactComponent as Logo } from "../../images/logo_2.svg";
import { ReactComponent as Dashboard } from "../../images/icon_dashboard.svg";
import { ReactComponent as Dashboard2 } from "../../images/icon_dashboard2.svg";
import { ReactComponent as Penny } from "../../images/icon_penny.svg";

const PageLevelProposal = () => {
  const location = useLocation();
  const [state, setState] = useContext(State);
  const { token } = theme.useToken();

  useEffect(() => {
    getUserManagerData()
      .then(data => {
        state.setKeyValue("userManagerData", data);
      })
      .catch(() => {
        getOrganizationManagerData()
          .then(data => {
            state.setKeyValue("userManagerData", data);
          })
          .catch(console.log);
      });
  }, []);

  useEffect(() => {
    state.setKeyValue("viewMode", "goals");
  }, [location]);

  useEffect(() => {
    if (state.loading) return;

    const prospectObjective = state.getPreferenceValue("prospectObjective");

    // handle RTQ quiz
    if (!prospectObjective?.passedRTQ) return;

    state.setKeyValue("loading", true);
    state.setKeyValue("viewMode", "goal");

    // clean "passedRTQ" prop, to prevent handling on the next user entrance
    state
      .setPreferenceValue("prospectObjective", {
        ...prospectObjective,
        passedRTQ: null,
      })
      .catch(error => state.showError(error))
      .finally(() => state.setKeyValue("loading", false));
  }, [state]);

  useEffect(() => {
    if (state.generatePdfMode && !state.loading) {
      // calculations ready
      state.setKeyValue("generatePdfMode", false);

      const goalLastSavedProps = getGoalDataById(state.activeGoalId);

      const pagesList = PDF_PAGE_LIST.filter(
        it => it.type === goalLastSavedProps?.investmentObjective
      );

      if (!pagesList.length) return;

      getHtml2pdfPromiseList(pagesList)
        .output("bloburl")
        .then(function (pdf) {
          let pdfWindow = window.open("", "_blank");
          pdfWindow.document.write(
            "<iframe  width='100%' height='100%' src='" + pdf + "'></iframe>"
          );
          pdfWindow.document.title = "Level Report";

          state.setKeyValue("showOverlay", false);
        });
    }
  }, [state]);

  const handleCopyGoal = activeGoal => {
    state.setKeyValue("activeGoal", activeGoal);
    state.setKeyValue("viewMode", "goal");

    setTimeout(() => {
      state.setKeyValue("activeGoal", undefined);
    }, 1000);
  };

  const getGoalDataById = goalId => {
    if (!goalId) return;

    const goalHistory = state.getPreferenceValue("productMap")[goalId];

    return goalHistory[goalHistory.length - 1];
  };

  const handleDownloadProposal = goalId => {
    state.setKeyValue("activeGoalId", goalId);
    state.setKeyValue("loading", true);
    state.setKeyValue("showOverlay", true);
    state.setKeyValue("generatePdfMode", true);
  };

  const handleViewGoal = goalId => {
    state.setKeyValue("activeGoal", goalId);
    state.setKeyValue("viewMode", "goal");
  };

  return (
    <StyledContainer>
      <ConfigProvider
        theme={{
          components: {
            Menu: {
              activeBarBorderWidth: 0,
              itemSelectedBg: token.colorPrimary,
              itemSelectedColor: "#fff",
              itemHoverBg: token.colorPrimary,
              itemHoverColor: "#fff",
            },
          },
        }}
      >
        <Layout>
          <Layout.Header
            style={{
              background: "#fff",
              borderBottom: "1px solid #C9C8C8",
              padding: "0 24px",
            }}
          >
            <Flex
              align="center"
              justify="space-between"
              style={{ height: "100%" }}
            >
              <Logo />
              <Button
                disabled={!state.userManagerData?.access?.penny}
                icon={<Penny />}
                onClick={() => state.setKeyValue("openModalAskAdvice", true)}
                shape="round"
                style={{ background: token.tenant_color_primary }}
                type="text"
              >
                <span>
                  Ask<b>Penny</b>
                </span>
              </Button>
            </Flex>
          </Layout.Header>

          <Layout style={{ height: "calc(100vh - 64px)" }}>
            <Layout.Sider
              breakpoint="md"
              collapsedWidth="0"
              style={{ background: token.sidebar_background }}
              width={token.sidebar_width}
            >
              <Flex
                align="center"
                justify="space-between"
                style={{ height: "100%", padding: 16 }}
                vertical
              >
                <Menu
                  defaultSelectedKeys={["1"]}
                  items={[
                    {
                      key: "1",
                      label: <Dashboard />,
                      onClick: () => console.log("action 1 selected"),
                    },
                    {
                      disabled: true,
                      key: "2",
                      label: <Dashboard2 />,
                    },
                  ]}
                  style={{ background: "transparent", width: 55 }}
                />
                <MenuProfile logoutLink="/login" />
              </Flex>
            </Layout.Sider>
            <Layout.Content style={{ background: "#fff", overflow: "auto" }}>
              {state.getPreferenceValue("productMap") &&
              state.viewMode === "goals" ? (
                <ViewAllGoal
                  handleDownloadProposal={handleDownloadProposal}
                  handleViewGoal={handleViewGoal}
                />
              ) : (
                <ViewOneGoal handleDownloadProposal={handleDownloadProposal} />
              )}
            </Layout.Content>
          </Layout>

          <ProposalPdfContentHolder
            activeGoalData={getGoalDataById(state.activeGoalId)}
          />
        </Layout>

        <SectionAskAdvice state={state} setState={setState} />
      </ConfigProvider>
    </StyledContainer>
  );
};

export default PageLevelProposal;
