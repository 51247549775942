import { Checkbox, Col, Flex, Image, Row, Typography } from "antd";
import SeeDisclosures from "../SeeDisclosures";

import { StyledCheckBox } from "./style";

import { checkboxList } from "./constants";

import demo from "./images/demo.png";
import { ReactComponent as RedLargeX } from "./images/red_large_x.svg";

const SectionSeekToEliminate = ({
  ButtonsContainer,
  openDisclosuresModal,
  proposalData,
  updateProposalData,
}) => (
  <Flex gap={50} justify="space-between" wrap="wrap">
    <Flex vertical style={{ paddingTop: 30, maxWidth: 568 }}>
      <RedLargeX />
      <Typography.Title
        className="title"
        style={{ color: "#BC141A", margin: "10px 0 3px" }}
      >
        Eliminate
      </Typography.Title>
      <p className="description" style={{ margin: "0 0 17px" }}>
        We seek to Eliminate companies that demonstrably and consistently harm
        their stakeholders.*
      </p>
      <p style={{ fontSize: 16, color: "#939395", margin: "0 0 37px" }}>
        Of the options, select which ones you want to actively avoid.
      </p>
      <Checkbox.Group
        onChange={selectedValues =>
          updateProposalData("investmentsToAvoid", selectedValues)
        }
        style={{
          width: "100%",
          marginBottom: 81,
        }}
        value={proposalData.investmentsToAvoid}
      >
        <Row gutter={[60, 14]}>
          {checkboxList.map(value => (
            <Col key={value} span={12}>
              <StyledCheckBox value={value}>{value}</StyledCheckBox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
      {ButtonsContainer && <ButtonsContainer />}

      <SeeDisclosures openModal={openDisclosuresModal} />
    </Flex>
    <Image height={600} preview={false} src={demo} width={430} />
  </Flex>
);

export default SectionSeekToEliminate;
