import { useMediaQuery } from "react-responsive";
import { Button, Flex, Image, Layout, theme } from "antd";
import PennyChatToggler from "../../../../organism/SectionAskAdvice/components/PennyChatToggler";

import {
  isOrganizationAstor,
  isOrganizationLevel,
  isOrganizationOneascent,
  isOrganizationRivershares,
} from "../../../../utils/helper/specialized";

import GetAdvice from "../../../../layout/images/GetAdvice";
import { ReactComponent as LevelLogo } from "../../../level/images/logo.svg";
import { ReactComponent as OneAscentSlogan } from "../../../oneascent/image/slogan_light.svg";

const ProposalHeader = ({
  handleButtonBackToDashboard,
  organization,
  openModal,
  showButtonBackToDashboard,
}) => {
  const { token } = theme.useToken();
  const isSmallScreen = useMediaQuery({ maxWidth: token.screenMDMax });

  return (
    <Layout.Header
      style={{
        background: token.proposal_header_background,
        height: token.proposal_header_height,
        padding: token.proposal_header_padding,
        minHeight: "fit-content",
      }}
    >
      {isOrganizationOneascent(organization) && (
        <Flex align="center" justify="space-between">
          <Flex gap={46}>
            <Image id="headerLogo" preview={false} src={token.logo_src} />
            {showButtonBackToDashboard && !isSmallScreen && (
              <Button
                onClick={handleButtonBackToDashboard}
                shape="round"
                style={{ height: 38 }}
              >
                Back to Dashboard
              </Button>
            )}
          </Flex>
          {!isSmallScreen && <OneAscentSlogan id="oneascentSlogan" />}
        </Flex>
      )}
      {isOrganizationRivershares(organization) && (
        <Flex>
          <Image
            id="headerLogo"
            preview={false}
            src={token.logo_src}
            width={160}
          />
        </Flex>
      )}
      {isOrganizationAstor(organization) && (
        <Flex>
          <Image
            id="headerLogo"
            preview={false}
            src={token.logo_src}
            width={160}
          />
        </Flex>
      )}
      {isOrganizationLevel(organization) && (
        <Flex justify="space-between">
          <LevelLogo id="headerLogo" style={{ width: 160 }} />
          <PennyChatToggler
            handleClick={() => openModal("openModalAskAdvice")}
          />
        </Flex>
      )}
      {!organization && (
        <Flex justify="space-between">
          <Image
            id="headerLogo"
            preview={false}
            src={token.logo_src}
            width={160}
          />
          <Button
            type="secondary"
            shape="round"
            style={{
              width: 40,
              padding: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => openModal("openModalAskAdvice")}
          >
            <GetAdvice />
          </Button>
        </Flex>
      )}
    </Layout.Header>
  );
};

export default ProposalHeader;
