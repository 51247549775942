import styled from "styled-components";

const StylesContainer = styled.header`
  align-items: end;
  border-bottom: 1px solid #bec8ce;
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;

  p {
    color: #234744;
    display: flex;
    flex-direction: column;
    margin: unset;
    text-align: right;
  }
`;

export default StylesContainer;
