import { useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Row } from "antd";

import { StyledSlider } from "./style";

import { bottomChartConfig, topChartConfig } from "./configs";

const topChartSeries = [
  [5, 11, 14, 15.3, 15.5, 15.5, 15.4, 15.3, 15.2, 15.1],
  [5, 11, 14, 15.3, 15.5, 15.5, 15.4, 15.3, 15.2, 15.1],
  [5, 13, 15, 15.3, 15.5, 15.5, 15.4, 15.3, 15.2, 15.1],
  [5, 10, 15, 17, 18, 18, 17.7, 17, 16.5, 16],
  [5, 10, 15, 20, 21, 20, 19, 18.5, 18, 18],
  [5, 10, 15, 19, 21, 21, 21.5, 22, 22.5, 23],
  [5, 8, 12, 17, 21, 24, 26, 27, 27.5, 28],
  [5, 8, 10, 16, 20, 22, 23, 26, 26, 30],
  [5, 7, 9, 12, 15, 18, 22, 26, 30, 35],
  [5, 7, 9, 11, 13, 15.5, 19, 24, 30, 35],
  [5, 7, 9, 11, 13, 16, 20, 25, 31, 38],
  [5, 7, 9, 11, 13, 16, 20, 25, 32, 40],
];

const bottomChartSeries = [
  [6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
  [6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
  [0, 9, 9, 9, 9, 9, 9, 9, 9, 9],
  [0, 0, 10, 10, 10, 10, 10, 10, 10, 10],
  [0, 0, 0, 11, 11, 11, 11, 11, 11, 11],
  [0, 0, 0, 0, 12, 12, 12, 12, 12, 12],
  [0, 0, 0, 0, 0, 13, 13, 13, 13, 13],
  [0, 0, 0, 0, 0, 0, 14, 14, 14, 14],
  [0, 0, 0, 0, 0, 0, 0, 15, 15, 15],
  [0, 0, 0, 0, 0, 0, 0, 0, 17, 17],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 20],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
];

const InvestmentObjectiveSection = ({ proposalData, updateProposalData }) => {
  const [selectedRange, setSelectedRange] = useState(
    proposalData.investmentObjective === "growth" ? 6 : 1
  );
  const [disabledSlider, setDisabledSlider] = useState(false);

  const handleRangeChange = value => {
    setDisabledSlider(true);
    setSelectedRange(value);
    debouncedUpdateProposalData(value);
  };

  const debouncedUpdateProposalData = useDebouncedCallback(value => {
    updateProposalData("investmentObjective", value < 6 ? "income" : "growth");
    setDisabledSlider(false);
  }, 500);

  return (
    <div style={{ textAlign: "center", maxWidth: 700, margin: "0 auto" }}>
      <h3 className="title">Investment Objective</h3>
      <p className="description">
        Are you primarily looking for an investment to give you regular
        <br />
        <b>income</b> or are you trying to <b>grow</b> your total wealth?
      </p>
      <Row justify="space-between" style={{ fontSize: 16, color: "#4D83B2" }}>
        <b>
          Drive
          <br />
          Income
        </b>
        <b>
          Drive
          <br />
          Growth
        </b>
      </Row>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          ...topChartConfig,
          series: [
            {
              type: "spline",
              color: "#CC685E",
              data: topChartSeries[selectedRange],
            },
          ],
        }}
      />
      <StyledSlider
        value={selectedRange}
        min={1}
        max={11}
        step={1}
        tooltip={{ open: false }}
        trackStyle={{
          height: 15,
          background: "linear-gradient(90deg, #4D83B2 0%, #39495D 100%",
          borderRadius: 35,
          cursor: "default",
        }}
        railStyle={{
          height: 15,
          background: "#E7EBEE",
          borderRadius: 10,
          boxShadow: "inset 0 2px 3px #00000029",
          cursor: "default",
        }}
        disabled={disabledSlider}
        onChange={value => handleRangeChange(value)}
      />
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          ...bottomChartConfig,
          series: [
            {
              type: "column",
              color: "#6DC1C770",
              data: bottomChartSeries[selectedRange],
            },
          ],
        }}
      />
    </div>
  );
};

export default InvestmentObjectiveSection;
