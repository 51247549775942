import { Button, Flex, Tag } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import StyledContainer from "./style";

import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

const CardNews = ({ data }) => (
  <StyledContainer>
    <h4>{data.title}</h4>
    <p dangerouslySetInnerHTML={{ __html: data.body }} />
    <Flex gap={4} wrap>
      {data.tags?.map((tagData, index) => (
        <Tag
          color={tagData.background}
          key={index}
          style={{ color: tagData.color }}
        >
          {tagData.label}
        </Tag>
      ))}
    </Flex>
    <Flex align="center" justify="space-between">
      <Flex gap={16}>
        <span className="meta-label">{data.sources} sources</span>
        <span className="meta-label">{data.minutes} mins</span>
      </Flex>
      <Button
        icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
        size="small"
        type="text"
      >
        Full Article
      </Button>
    </Flex>
  </StyledContainer>
);

export default CardNews;
