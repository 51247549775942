import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Flex, Modal, Row, theme } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilterCategory from "../../../../../molecule/FilterCategory";

import Close from "../../../../../icon/Close";
import { ReactComponent as Filter } from "../images/filter.svg";
import { faBookmark as faBookmarkSolid } from "@fortawesome/free-solid-svg-icons";

const FiltersContainer = ({
  assetManagerFilters,
  broadCategoryFilters,
  customFilters,
  filteredProducts,
  globalCategoryFilters,
  handleAssetManagerFilterChange,
  handleBroadCategoryFilterChange,
  handleCustomFilterChange,
  handleGlobalCategoryFilterChange,
  handleTypeCategoryFilterChange,
  productFiltersApplied,
  productsList,
  typeCategoryFilters,
}) => {
  const { token } = theme.useToken();
  const isSmallScreen = useMediaQuery({ maxWidth: token.screenMDMax });
  const [filtersModalOpened, setFiltersModalOpened] = useState(false);

  if (isSmallScreen) {
    // small screen view
    return (
      <Flex style={{ width: "100%" }} vertical>
        <Row
          wrap={false}
          align="middle"
          style={{ gap: 16, margin: "6px 0 30px" }}
        >
          <Filter
            style={{ cursor: "pointer" }}
            onClick={() => setFiltersModalOpened(true)}
          />
        </Row>

        <Modal
          open={filtersModalOpened}
          title={
            <h3 style={{ fontSize: 18, color: token.color_navy }}>
              All Products{" "}
              <span style={{ fontWeight: 400 }}>({productsList.length})</span>
            </h3>
          }
          style={{ top: 36 }}
          onCancel={() => setFiltersModalOpened(false)}
          closeIcon={<Close />}
          footer={false}
        >
          <FilterCategory
            categoriesList={["My Favorites"]}
            categoryKey="customFilter"
            defaultValues={customFilters}
            filteredProducts={filteredProducts}
            labelIconList={[
              {
                name: "My Favorites",
                icon: (
                  <FontAwesomeIcon
                    icon={faBookmarkSolid}
                    style={{ color: "#A5A3A3" }}
                  />
                ),
              },
            ]}
            onClear={() => handleCustomFilterChange([])}
            onFilterChange={selectedValues => {
              handleCustomFilterChange(selectedValues);
            }}
            productFiltersApplied={productFiltersApplied}
            productsList={productsList}
            title="Filters"
          />

          <FilterCategory
            categoryKey="type"
            defaultValues={typeCategoryFilters}
            filteredProducts={filteredProducts}
            onClear={() => handleTypeCategoryFilterChange([])}
            onFilterChange={selectedValues => {
              handleTypeCategoryFilterChange(selectedValues);
            }}
            productFiltersApplied={productFiltersApplied}
            productsList={productsList}
            title="Type"
          />

          <FilterCategory
            categoryKey="broadCategory"
            defaultValues={broadCategoryFilters}
            filteredProducts={filteredProducts}
            onClear={() => handleBroadCategoryFilterChange([])}
            onFilterChange={selectedValues => {
              handleBroadCategoryFilterChange(selectedValues);
            }}
            productFiltersApplied={productFiltersApplied}
            productsList={productsList}
            tagView
            title="Categories"
          />

          <FilterCategory
            categoryKey="assetManager"
            defaultValues={assetManagerFilters}
            filteredProducts={filteredProducts}
            onClear={() => handleAssetManagerFilterChange([])}
            onFilterChange={selectedValues => {
              handleAssetManagerFilterChange(selectedValues);
            }}
            productFiltersApplied={productFiltersApplied}
            productsList={productsList}
            title="Asset Managers"
          />

          <FilterCategory
            categoryKey="globalCategory"
            defaultValues={globalCategoryFilters}
            disableBasedOnParent={true}
            filteredProducts={filteredProducts}
            hideUnavailableOptions={true}
            onClear={() => handleGlobalCategoryFilterChange([])}
            onFilterChange={selectedValues => {
              handleGlobalCategoryFilterChange(selectedValues);
            }}
            productFiltersApplied={productFiltersApplied}
            productsList={productsList}
            title="Global Categories"
          />
        </Modal>
      </Flex>
    );
  }

  // large screen view
  return (
    <Flex className="filters-container" gap={20} vertical>
      <FilterCategory
        categoriesList={["My Favorites"]}
        categoryKey="customFilter"
        defaultValues={customFilters}
        filteredProducts={filteredProducts}
        labelIconList={[
          {
            name: "My Favorites",
            icon: (
              <FontAwesomeIcon
                icon={faBookmarkSolid}
                style={{ color: "#A5A3A3" }}
              />
            ),
          },
        ]}
        onClear={() => handleCustomFilterChange([])}
        onFilterChange={selectedValues => {
          handleCustomFilterChange(selectedValues);
        }}
        productFiltersApplied={productFiltersApplied}
        productsList={productsList}
        title="Filters"
      />

      <FilterCategory
        categoryKey="type"
        defaultValues={typeCategoryFilters}
        filteredProducts={filteredProducts}
        onClear={() => handleTypeCategoryFilterChange([])}
        onFilterChange={selectedValues => {
          handleTypeCategoryFilterChange(selectedValues);
        }}
        productFiltersApplied={productFiltersApplied}
        productsList={productsList}
        title="Type"
      />

      <FilterCategory
        categoryKey="broadCategory"
        defaultValues={broadCategoryFilters}
        filteredProducts={filteredProducts}
        onClear={() => handleBroadCategoryFilterChange([])}
        onFilterChange={selectedValues => {
          handleBroadCategoryFilterChange(selectedValues);
        }}
        productFiltersApplied={productFiltersApplied}
        productsList={productsList}
        tagView
        title="Categories"
      />

      <FilterCategory
        categoryKey="assetManager"
        defaultValues={assetManagerFilters}
        filteredProducts={filteredProducts}
        onClear={() => handleAssetManagerFilterChange([])}
        onFilterChange={selectedValues => {
          handleAssetManagerFilterChange(selectedValues);
        }}
        productFiltersApplied={productFiltersApplied}
        productsList={productsList}
        title="Asset Managers"
      />

      <FilterCategory
        categoryKey="globalCategory"
        defaultValues={globalCategoryFilters}
        disableBasedOnParent={true}
        filteredProducts={filteredProducts}
        hideUnavailableOptions={true}
        onClear={() => handleGlobalCategoryFilterChange([])}
        onFilterChange={selectedValues => {
          handleGlobalCategoryFilterChange(selectedValues);
        }}
        productFiltersApplied={productFiltersApplied}
        productsList={productsList}
        title="Global Categories"
      />
    </Flex>
  );
};

export default FiltersContainer;
