import { useEffect, useState } from "react";
import { Button, Checkbox, Col, Flex, Row, Tag, theme } from "antd";

import StylesContainer from "./style";

import { FILTER_TAG_COLOR_LIST } from "../../ecosystem/level/constants";

const FilterCategory = ({
  categoriesList,
  categoryKey,
  defaultValues,
  disableBasedOnParent,
  filteredProducts,
  labelIconList,
  tagView,
  title,
  hideUnavailableOptions,
  onClear,
  onFilterChange,
  productFiltersApplied,
  productsList,
}) => {
  const { token } = theme.useToken();
  const [categoryOptions, setCategoryOptions] = useState([]);

  const handleChange = checkedValues => {
    if (onFilterChange) {
      onFilterChange(checkedValues);
    }
  };

  const createCategoryOptions = () =>
    (
      categoriesList ?? [
        ...new Set(productsList.map(product => product[categoryKey])),
      ]
    )
      .filter(it => !!it)
      .map(category => ({
        value: category,
        disabled:
          disableBasedOnParent &&
          filteredProducts?.length > 0 &&
          filteredProducts.filter(product => product[categoryKey] === category)
            .length === 0,
        visible: hideUnavailableOptions
          ? filteredProducts?.filter(
              product => product[categoryKey] === category
            ).length > 0
          : true,
      }));

  useEffect(() => {
    setCategoryOptions(createCategoryOptions());
  }, [filteredProducts, productFiltersApplied]);

  return (
    <StylesContainer vertical>
      <div className="header-container">
        {title}
        {onClear && (
          <Button onClick={onClear} size="small" type="text">
            Clear
          </Button>
        )}
      </div>
      <Checkbox.Group value={defaultValues} onChange={handleChange}>
        {categoryOptions
          ?.sort((a, b) => a.value.localeCompare(b.value))
          ?.map(
            filter =>
              filter.visible && (
                <Row key={filter.value}>
                  <Col span={24}>
                    <Checkbox disabled={filter.disabled} value={filter.value}>
                      <Flex align="center" justify="space-between">
                        {tagView ? (
                          <Tag
                            color={
                              FILTER_TAG_COLOR_LIST.find(
                                it => it.label === filter.value
                              )?.color
                            }
                            style={{ height: 24 }}
                          >
                            {filter.value}
                          </Tag>
                        ) : (
                          <span
                            style={{
                              color: filter.disabled
                                ? token.color_grey_1
                                : "#354A5F",
                              textTransform: "capitalize",
                            }}
                            dangerouslySetInnerHTML={{ __html: filter.value }}
                          />
                        )}
                        {labelIconList &&
                          labelIconList.find(it => it.name === filter.value)
                            ?.icon}
                      </Flex>
                    </Checkbox>
                  </Col>
                </Row>
              )
          )}
      </Checkbox.Group>
    </StylesContainer>
  );
};

export default FilterCategory;
