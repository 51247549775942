import { ArcElement, Chart as ChartJS, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Flex } from "antd";
import CardSkeleton from "../../../../atom/CardSkeleton";

import { getCurrencyValueWithRoundSign } from "../../../../utils/helper/general";
import { isGrowthTypeRiskTolerance } from "../../../../utils/helper/specialized";

ChartJS.register(ArcElement, Tooltip);

const START_POINT = 0;
const END_POINT = 100;

const ChartGaugeLevel = ({
  investmentObjective,
  productsLiveSimulation,
  loading,
}) => {
  const calculatedPercent =
    productsLiveSimulation[0]?.chanceOfSuccess === 100
      ? 99
      : productsLiveSimulation[0]?.chanceOfSuccess;

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    borderRadius: 20,
    borderWidth: 0,
  };

  const getGaugeLabel = percent => ({
    id: "gaugeChartText",
    afterDatasetDraw(chart, args, options) {
      const {
        ctx,
        data,
        chartArea: { top, bottom, left, right, width, height },
        scales: { r },
      } = chart;

      ctx.save();
      const xCoor = chart.getDatasetMeta(0).data[0].x;
      const yCoor = chart.getDatasetMeta(0).data[0].y;

      const textLabel = ({ text, x, y, fontSize, textBaseLine, textAlign }) => {
        ctx.font = `${fontSize}px sans-serif`;
        ctx.fillStyle = "#354A5F";
        ctx.textBaseline = textBaseLine;
        ctx.textAlign = textAlign;
        ctx.fillText(text, x, y);
      };

      textLabel({
        text: `${percent} %`,
        x: xCoor,
        y: yCoor - 5,
        fontSize: 21,
        textBaseLine: "bottom",
        textAlign: "center",
      });

      textLabel({
        text: "chance",
        x: xCoor,
        y: yCoor + 15,
        fontSize: 18,
        textBaseLine: "bottom",
        textAlign: "center",
      });
    },
  });

  const LabelComparedProductCos = ({ background, value }) => (
    <div style={{ borderRadius: 4, background, color: "#fff", padding: 8 }}>
      {value === 100 ? 99 : value}% chance
    </div>
  );

  if (calculatedPercent === undefined || loading) {
    return <CardSkeleton loading height={235} width={170} />;
  }

  if (isGrowthTypeRiskTolerance(investmentObjective)) {
    return (
      <div
        className="chance-of-success-container"
        style={{ textAlign: "center" }}
      >
        <Doughnut
          height="100px"
          data={{
            labels: [],
            datasets: [
              {
                backgroundColor: ["#0D63CD", "#EDEDED"],
                circumference: 240,
                cutout: "75%",
                data: [80, 20],
                rotation: 240,
              },
            ],
          }}
          options={options}
          plugins={[getGaugeLabel(80)]}
        />
        <Flex align="center" gap={8} vertical>
          {productsLiveSimulation
            ?.filter(it => it.isComparedProduct)
            ?.map(it => (
              <LabelComparedProductCos
                background={it.color}
                value={it.chanceOfSuccess}
              />
            ))}
          <p style={{ margin: 0 }}>this investment returns between</p>
        </Flex>

        {productsLiveSimulation[0] && (
          <b style={{ fontSize: 20 }}>
            {getCurrencyValueWithRoundSign(
              productsLiveSimulation[0].Percentile10thMC[
                productsLiveSimulation[0].Percentile10thMC.length - 1
              ]
            )}
            {" - "}
            {getCurrencyValueWithRoundSign(
              productsLiveSimulation[0].Percentile90thMC[
                productsLiveSimulation[0].Percentile90thMC.length - 1
              ]
            )}
          </b>
        )}
      </div>
    );
  }

  // income type risk tolerance selected
  return (
    <div
      className="chance-of-success-container"
      style={{ textAlign: "center" }}
    >
      <Doughnut
        height="100px"
        data={{
          labels: [],
          datasets: [
            {
              backgroundColor: ["#0D63CD", "#EDEDED"],
              circumference: 240,
              cutout: "80%",
              data: [
                START_POINT - calculatedPercent,
                END_POINT - calculatedPercent,
              ],
              rotation: 240,
            },
          ],
        }}
        options={options}
        plugins={[getGaugeLabel(calculatedPercent)]}
      />
      <Flex align="center" gap={8} vertical>
        {productsLiveSimulation
          ?.filter(it => it.isComparedProduct)
          ?.map((it, index) => (
            <LabelComparedProductCos
              background={it.color}
              key={index}
              value={it.chanceOfSuccess}
            />
          ))}
        <p style={{ margin: 0 }}>
          that this investment will last through retirement
        </p>
      </Flex>
    </div>
  );
};

export default ChartGaugeLevel;
