import styled from "styled-components";
import { Tabs } from "antd";

const StyledTabs = styled(Tabs)`
  .ant-form-item {
    margin: unset;
  }

  .ant-input-number {
    width: 100%;
  }
`;

export default StyledTabs;
