export const chartRender = (chart, config, onRangeChange) => {
  if (
    !chart?.xAxis?.[0]?.min ||
    !chart?.xAxis?.[0]?.max ||
    !chart.plotLeft ||
    !chart.plotWidth ||
    !chart.plotHeight ||
    chart.renderTo.clientWidth === 0
  ) {
    return;
  }
  window.chart = chart;
  requestAnimationFrame(() => {
    if (!chart?.xAxis?.[0]?.min || !chart?.xAxis?.[0]?.max) return;

    // Clean up existing elements
    chart.overlayContainer?.remove();

    // Abort adding stress test elements, if no config provided
    if (!config) return;

    const { durationYears, isCustomScenario, label, startYear } = config;

    const xAxis = chart.xAxis[0];
    let minValue = startYear || (xAxis.max - xAxis.min) * 0.1;
    let maxValue = durationYears ? minValue + durationYears : minValue;

    minValue = Math.max(xAxis.min, Math.min(xAxis.max, minValue));
    maxValue = Math.max(xAxis.min, Math.min(xAxis.max, maxValue));
    if (minValue === maxValue) {
      maxValue = minValue + durationYears;
    }

    const minX = xAxis.toPixels(minValue) - chart.plotLeft;
    const maxX = xAxis.toPixels(maxValue) - chart.plotLeft;
    const width = Math.abs(maxX - minX) < 40 ? 40 : Math.abs(maxX - minX);

    // Create main container
    const overlayContainer = document.createElement("div");

    overlayContainer.style.cssText = `
      height: ${chart.plotHeight}px;
      left: ${chart.plotLeft}px;
      pointer-events: none;
      position: absolute;
      top: ${chart.plotTop}px;
      width: ${chart.plotWidth}px;
      z-index: 3;
    `;
    chart.container.appendChild(overlayContainer);
    chart.overlayContainer = overlayContainer;

    // Create draggable region
    const dragRegion = document.createElement("div");
    dragRegion.style.cssText = `
      background: rgba(186, 207, 255, 0.60);
      border: 1px solid rgba(31, 85, 175, 0.85);
      border-radius: 12px;
      cursor: ${onRangeChange ? "move" : "default"};
      height: calc(100% + 20px);
      left: ${minX}px;
      min-width: 40px;
      pointer-events: all;
      position: absolute;
      top: -10px;
      width: ${width}px;
    `;
    overlayContainer.appendChild(dragRegion);

    // Add label
    const labelElement = document.createElement("div");
    labelElement.style.cssText = `
      background: #FFF;
      border-radius: 44px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
      color: #166997;
      font-size: 10px;
      left: ${width / 2 - 37}px;
      padding: 5px 9px;
      pointer-events: none;
      position: absolute;
      text-align: center;
      top: -11px;
      white-space: nowrap;
      width: 74px;
    `;
    labelElement.textContent = label;
    dragRegion.appendChild(labelElement);

    // Add handles
    const leftHandle = document.createElement("div");
    leftHandle.style.cssText = `
      position: absolute;
      left: -4px;
      top: 0;
      width: 8px;
      height: 100%;
      cursor: ew-resize;
      pointer-events: all;
    `;
    isCustomScenario && dragRegion.appendChild(leftHandle);

    const rightHandle = document.createElement("div");
    rightHandle.style.cssText = `
      position: absolute;
      right: -4px;
      top: 0;
      width: 8px;
      height: 100%;
      cursor: ew-resize;
      pointer-events: all;
    `;
    isCustomScenario && dragRegion.appendChild(rightHandle);

    // Add arrows container
    const arrowsContainer = document.createElement("div");
    arrowsContainer.style.cssText = `
      align-items: center;
      background: rgba(234, 241, 255, 0.75);
      border: 1px solid #3B92B7;
      border-radius: 12px;
      bottom: 0;
      box-shadow: 0 2px 4px rgba(74, 144, 226, 0.2);
      display: flex;
      height: 24px;
      left: ${width / 2 - 25}px;
      pointer-events: all;
      position: absolute;
      transform: translateY(-50%);
      width: 50px;
      z-index: 6;
    `;

    arrowsContainer.innerHTML = `
      <div style="
        align-items: center;
        color: #3B92B7;
        display: flex;
        font-size: 16px;
        justify-content: space-around;
        width: 100%;
      ">
        <span>←</span>
        <span style="width: 1px; height: 20px; background: #cbd5e9;"></span>
        <span>→</span>
      </div>
    `;
    isCustomScenario && dragRegion.appendChild(arrowsContainer);

    // Add tick marks container
    const ticksContainer = document.createElement("div");
    ticksContainer.style.cssText = `
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      height: 20px;
      pointer-events: none;
    `;
    overlayContainer.appendChild(ticksContainer);

    // Dragging functionality
    let isDragging = false;
    let startX, startLeft;

    const handleDragStart = e => {
      // abort drag start event, if no handler provided
      if (!onRangeChange) return;

      isDragging = true;
      startX = e.clientX;
      startLeft = parseFloat(dragRegion.style.left);
      document.addEventListener("mousemove", handleDragMove);
      document.addEventListener("mouseup", handleDragEnd);
    };

    const handleDragMove = e => {
      if (!isDragging) return;
      const dx = e.clientX - startX;
      const newLeft = Math.max(
        0,
        Math.min(chart.plotWidth - width, startLeft + dx)
      );
      dragRegion.style.left = `${newLeft}px`;
    };

    const handleDragEnd = () => {
      if (!isDragging) return;
      isDragging = false;
      const newLeft = parseFloat(dragRegion.style.left);
      const newStartYear = xAxis.toValue(newLeft + chart.plotLeft);
      const max = xAxis.toValue(newLeft + width + chart.plotLeft);
      onRangeChange?.({
        durationYears: Math.round(max - newStartYear),
        startYear: Math.round(newStartYear),
      });
      document.removeEventListener("mousemove", handleDragMove);
      document.removeEventListener("mouseup", handleDragEnd);
    };

    dragRegion.addEventListener("mousedown", handleDragStart);

    // Handle resizing
    const handleResize = (handle, isRight) => {
      let startWidth, startLeft;

      const handleMouseDown = e => {
        e.stopPropagation();
        startX = e.clientX;
        startWidth = parseFloat(dragRegion.style.width);
        startLeft = parseFloat(dragRegion.style.left);
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
      };

      const handleMouseMove = e => {
        const dx = e.clientX - startX;
        if (isRight) {
          const newWidth = Math.max(
            50,
            Math.min(chart.plotWidth - startLeft, startWidth + dx)
          );
          dragRegion.style.width = `${newWidth}px`;
        } else {
          const newLeft = Math.max(
            0,
            Math.min(startLeft + startWidth - 50, startLeft + dx)
          );
          const newWidth = startWidth - (newLeft - startLeft);
          dragRegion.style.left = `${newLeft}px`;
          dragRegion.style.width = `${newWidth}px`;
        }
      };

      const handleMouseUp = () => {
        const newLeft = parseFloat(dragRegion.style.left);
        const newWidth = parseFloat(dragRegion.style.width);
        const newStartYear = xAxis.toValue(newLeft + chart.plotLeft);
        const max = xAxis.toValue(newLeft + newWidth + chart.plotLeft);
        onRangeChange?.({
          durationYears: Math.round(max - newStartYear),
          startYear: Math.round(newStartYear),
        });
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };

      handle.addEventListener("mousedown", handleMouseDown);
    };

    isCustomScenario && handleResize(leftHandle, false);
    isCustomScenario && handleResize(rightHandle, true);
  });
};
