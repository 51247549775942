import { Button, Flex } from "antd";
import CardChartPie from "../CardChartPie";

import StylesContainer from "./style";

import { PORTFOLIO_LIST, PRODUCT_LIST } from "../../constants";

const SectionRecommendedPortfolios = ({
  handleContinueWithPortfolio,
  handleContinueWithProductOnly,
  handleGoBack,
  handleStartOver,
  proposalData,
  updateProposalData,
}) => (
  <StylesContainer align="center" vertical>
    <h3 className="title">
      {proposalData.product ? "Selected Product" : "Start with products"}
    </h3>
    <Flex gap={31} justify="center" style={{ marginBottom: 44 }} wrap="wrap">
      {PRODUCT_LIST.map(it => (
        <CardChartPie
          active={proposalData.product === it.label}
          inactive={proposalData.product && proposalData.product !== it.label}
          key={it.productId}
          onClick={() => {
            updateProposalData("product", it.label);
            updateProposalData("portfolio", undefined);
          }}
          series={[
            {
              color: it.color,
              y: 100,
            },
          ]}
          title={it.label}
        />
      ))}
    </Flex>
    <h3 className="title">
      {proposalData.product
        ? "Recommended for these portfolios"
        : "See Astor in a portfolio"}
    </h3>
    <Flex gap={31} justify="center" wrap="wrap">
      {PORTFOLIO_LIST.filter(it => {
        if (proposalData.product) {
          const selectedProductData = PRODUCT_LIST.find(
            it => it.label === proposalData.product
          );

          if (selectedProductData.portfolios.includes(it.value)) {
            return it;
          }
        } else {
          return it;
        }
      }).map(it => {
        return (
          <CardChartPie
            active={
              proposalData.portfolio !== undefined &&
              proposalData.portfolio === it.value
            }
            inactive={
              proposalData.portfolio !== undefined &&
              proposalData.portfolio !== it.label
            }
            key={it.value}
            onClick={() => updateProposalData("portfolio", it.value)}
            series={it.series}
            title={it.label}
          />
        );
      })}
    </Flex>
    <Flex gap={12} style={{ marginTop: 40 }} vertical>
      <Flex align="center" gap={11}>
        <Button
          disabled={!proposalData.portfolio}
          onClick={handleContinueWithPortfolio}
          shape="round"
          style={{ background: "#1971B9", color: "#fff" }}
          type="primary"
        >
          Plan with portfolio
        </Button>
        <span className="label-or">or</span>
        <Button
          onClick={handleContinueWithProductOnly}
          shape="round"
          style={{ background: "#1971B9", color: "#fff" }}
          type="primary"
        >
          Continue with Product only
        </Button>
      </Flex>
      <Flex align="center" justify="center">
        <Button
          onClick={handleGoBack}
          shape="round"
          size="small"
          style={{ color: "#32A2DE" }}
          type="text"
        >
          Go back
        </Button>
        <div
          style={{
            background: "#32a2de",
            height: 20,
            width: 1,
          }}
        />
        <Button
          onClick={handleStartOver}
          shape="round"
          size="small"
          style={{ color: "#32A2DE" }}
          type="text"
        >
          Start over
        </Button>
      </Flex>
    </Flex>
  </StylesContainer>
);

export default SectionRecommendedPortfolios;
