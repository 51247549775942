import sidebar_logo from "../images/sidebar_logo.svg";

export default {
  colorPrimary: "#215054",
  comparison_products_colors: ["#0D63CD", "#1E1919", "#A5A3A3"],
  fontFamily: "Inter, sans-serif",
  income_chart_with_types_navbar_button_background: "#fff",
  income_chart_with_types_navbar_button_color: "#1E1919",
  income_chart_with_types_navbar_container_background: "#749B9E",
  income_chart_with_types_color_annuities: "#4F8083",
  income_chart_with_types_color_contributions: "#1B5568",
  income_chart_with_types_color_other_income: "blue",
  income_chart_with_types_color_social_security: "#00C468",
  income_chart_with_types_color_withdrawal: "#1E1919",
  proposal_header_padding: 0,
  proposal_header_height: 0,
  sidebar_avatar_background: "#e3ebeb",
  sidebar_avatar_color: "#1E1919",
  sidebar_background: "#D5E1E2",
  sidebar_icon_layout_vertical: true,
  sidebar_logo: sidebar_logo,
  sidebar_menu_item_hover_background: "transparent",
  sidebar_width: 87,
  tenant_color_primary: "#E6F4EF",
};
