import dayjs from "dayjs";

import StylesContainer from "./style";

import logo from "./image/logo.svg";

const ReportPageCover = ({
  managerEmail,
  managerName,
  managerPhone,
  personaName,
}) => (
  <StylesContainer id="reportPageCover">
    <header>
      <img alt="logo" src={logo} />
    </header>

    <main>
      <p>Report prepared for</p>
      <h2>{personaName}</h2>
    </main>

    <footer>
      <div>
        <p>Generated by:</p>
        <h3>{managerName}</h3>
        <p>{dayjs().format("MM/DD/YYYY")}</p>
      </div>

      <div>
        <p>421 Granger Place</p>
        <p>Suite 400</p>
        <p>Charlotte, NC 28307</p>
      </div>

      <div>
        <p>{managerEmail}</p>
        {managerPhone && <p>{managerPhone}</p>}
      </div>
    </footer>
  </StylesContainer>
);

export default ReportPageCover;
