import { Button, Flex } from "antd";

import StyledContainer from "./style";

const CardPortfolio = ({
  chartLineColor,
  chartLineShow,
  chartLineType,
  handleButtonDetails,
  handleButtonOptions,
  loading,
  riskScore = 58,
  riskTolerance,
  title,
}) => (
  <StyledContainer>
    {chartLineShow &&
      (chartLineType === "dash" ? (
        <Flex gap={4}>
          <div
            style={{
              background: chartLineColor,
              borderRadius: 25,
              height: 4,
              width: 5,
            }}
          />
          <div
            style={{
              background: chartLineColor,
              borderRadius: 25,
              height: 4,
              width: 7,
            }}
          />
          <div
            style={{
              background: chartLineColor,
              borderRadius: 25,
              height: 4,
              width: 7,
            }}
          />
          <div
            style={{
              background: chartLineColor,
              borderRadius: 25,
              height: 4,
              width: 7,
            }}
          />
          <div
            style={{
              background: chartLineColor,
              borderRadius: 25,
              height: 4,
              width: 7,
            }}
          />
          <div
            style={{
              background: chartLineColor,
              borderRadius: 25,
              height: 4,
              width: 5,
            }}
          />
        </Flex>
      ) : (
        <div
          style={{
            background: chartLineColor,
            borderRadius: 25,
            height: 4,
            width: 58,
          }}
        />
      ))}

    <h5>{title}</h5>

    <Flex className="container-values" justify="space-between">
      <div className="container-value">
        <span className="label">Closest Match</span>
        <p>{riskTolerance}</p>
      </div>
      <div className="container-value" style={{ textAlign: "right" }}>
        <span className="label">Risk Score</span>
        <p>{riskScore}</p>
      </div>
    </Flex>

    <Flex gap={12}>
      <Button
        block
        disabled={loading}
        onClick={handleButtonOptions}
        shape="round"
        size="small"
      >
        Options
      </Button>
      <Button block onClick={handleButtonDetails} shape="round" size="small">
        Details
      </Button>
    </Flex>
  </StyledContainer>
);

export default CardPortfolio;
