import { Form, Flex, Select, Button, Input, InputNumber, Switch } from "antd";

import StyledModal from "./style";

import { LIST_COMPANY_TYPE } from "../../constant";

import {
  cleanPhoneMask,
  selectValue,
  setPhoneMask,
} from "../../../../utils/helper/general";

const ModalCompany = ({ onCancel, onFinish, open }) => {
  const [form] = Form.useForm();

  return (
    <StyledModal
      closeIcon={false}
      footer={
        <Flex justify="space-between">
          <Button shape="round">Cancel</Button>
          <Button
            form="formCompany"
            htmlType="submit"
            shape="round"
            type="primary"
          >
            Update
          </Button>
        </Flex>
      }
      onCancel={onCancel}
      open={open}
      title="New Company"
      width={583}
    >
      <Form form={form} id="formCompany" layout="vertical" onFinish={onFinish}>
        <Flex gap={17}>
          <Form.Item
            label="Company Name"
            name="companyName"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Company Type"
            name="companyType"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select options={LIST_COMPANY_TYPE} />
          </Form.Item>
        </Flex>
        <div className="container-primary-contact">
          <h5>Primary Contact</h5>
          <Flex gap={17}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Flex>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Flex gap={17}>
            <Form.Item
              label="Phone"
              name="phone"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                controls={false}
                formatter={setPhoneMask}
                onClick={selectValue}
                parser={cleanPhoneMask}
              />
            </Form.Item>
            <Form.Item label="Phone alt" name="phoneAlt">
              <InputNumber
                controls={false}
                formatter={setPhoneMask}
                onClick={selectValue}
                parser={cleanPhoneMask}
              />
            </Form.Item>
          </Flex>
        </div>

        <Flex align="start" gap={16} vertical>
          <p style={{ margin: 0 }}>Enable Features</p>
          <Flex gap={8}>
            <Form.Item name="featurePenny" layout="horizontal" noStyle>
              <Switch />
            </Form.Item>
            Penny
          </Flex>
          <Flex gap={8}>
            <Form.Item name="featureProportal" layout="horizontal" noStyle>
              <Switch />
            </Form.Item>
            Next ProPortal
          </Flex>
          <Flex gap={8}>
            <Form.Item name="featureAffiliate" layout="horizontal" noStyle>
              <Switch />
            </Form.Item>
            Next Affiliate
          </Flex>
        </Flex>
      </Form>
    </StyledModal>
  );
};

export default ModalCompany;
