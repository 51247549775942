import styled from "styled-components";

const StyledContainer = styled.div`
  background: #fff;
  border: 1px solid #0d63cd;
  border-radius: 12px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: fit-content;
  justify-content: space-between;
  min-height: 239px;
  min-width: 272px;
  padding: 24px;
  width: 272px;

  h5 {
    color: #052c2b;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    margin: unset;
  }

  .container-values {
    background: #fbfbfb;
    border: 1px solid #c8e4ff;
    border-radius: 8px;
    padding: 11px 12px;

    .container-value {
      display: flex;
      flex-direction: column;
      gap: 4px;

      span {
        color: #4b4747;
        font-size: 12px;
        font-style: normal;
        line-height: 115%;
      }

      p {
        color: #1e1919;
        font-size: 22px;
        font-style: normal;
        font-weight: 300;
        line-height: 110%;
        margin: unset;
      }
    }
  }
`;

export default StyledContainer;
