import { useEffect, useState } from "react";
import { Button, ConfigProvider, Flex, Row, theme } from "antd";
import SectionBitAboutYou from "./components/BitAboutYou";
import SectionSeekToElevate from "../../../manager/component/ModalProposalGuideManager/components/SectionSeekToElevate";
import SectionSeekToEliminate from "../../../manager/component/ModalProposalGuideManager/components/SectionSeekToEliminate";
import InvestmentObjectiveSection from "./components/InvestmentObjectiveSection";
import RiskToleranceSection from "./components/RiskToleranceSection";

import StylesContainer from "./style";

import { defaultProposalData } from "./constants";

import { ReactComponent as OneAscentSlogan } from "../../image/slogan_dark.svg";
import { ReactComponent as OneAscentLogo } from "../../image/logo_legacy.svg";

const QuestionnaireContentWealth = ({
  open,
  handleClose,
  handleCreateProposal,
  setShowDisclosuresModal,
}) => {
  const { token } = theme.useToken();
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [proposalData, setProposalData] = useState(defaultProposalData);

  useEffect(() => {
    if (!open) {
      setCurrentStepIndex(0);
      setProposalData(defaultProposalData);
    }
  }, [open]);

  const updateProposalData = (key, value) =>
    setProposalData(state => ({
      ...state,
      [key]: value,
    }));

  const handleGuideStepIndexChange = newStepIndex => {
    if (newStepIndex === -1) {
      handleClose();
    } else if (newStepIndex === 5) {
      handleCreateProposal({
        ...proposalData,
        contributions: proposalData.contributions * 12,
        targetAmount: 0,
        advisorFee: "1%",
        withdrawalLevel: "4%",
        yearToStartWithdrawals: 1,
        investmentsToAvoid: JSON.stringify(proposalData.investmentsToAvoid),
        investmentsToAlignWith: JSON.stringify(
          proposalData.investmentsToAlignWith
        ),
      });
    } else {
      setCurrentStepIndex(newStepIndex);
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Radio: token.proposal_guide_radio,
          Checkbox: token.proposal_guide_checkbox,
        },
      }}
    >
      <StylesContainer fontFamily={token.proposal_guide_font_primary}>
        <div className="content-body">
          <Row
            justify="space-between"
            style={{
              borderBottom: "1px solid #6DC1C7",
              marginBottom: 25,
              paddingBottom: 10,
            }}
          >
            <OneAscentLogo style={{ width: 160 }} />
            <OneAscentSlogan style={{ width: 160 }} />
          </Row>
          {currentStepIndex === 0 && (
            <SectionSeekToEliminate
              proposalData={proposalData}
              updateProposalData={updateProposalData}
              openDisclosuresModal={() => setShowDisclosuresModal(true)}
            />
          )}
          {currentStepIndex === 1 && (
            <SectionSeekToElevate
              proposalData={proposalData}
              updateProposalData={updateProposalData}
              openDisclosuresModal={() => setShowDisclosuresModal(true)}
            />
          )}
          {currentStepIndex === 2 && (
            <InvestmentObjectiveSection
              proposalData={proposalData}
              updateProposalData={updateProposalData}
            />
          )}
          {currentStepIndex === 3 && (
            <RiskToleranceSection updateProposalData={updateProposalData} />
          )}
          {currentStepIndex === 4 && (
            <SectionBitAboutYou
              proposalData={proposalData}
              updateProposalData={updateProposalData}
            />
          )}
          <Flex justify="space-evenly" style={{ marginTop: 25 }}>
            <Button
              shape="round"
              style={{
                width: 146,
                fontSize: 18,
                backgroundColor: "#BEC8CA",
                color: "#192849",
              }}
              onClick={() => handleGuideStepIndexChange(currentStepIndex - 1)}
            >
              {currentStepIndex === 0 ? "Close" : "Back"}
            </Button>
            <Button
              shape="round"
              style={{
                width: 146,
                fontSize: 18,
                backgroundColor: "#192849",
                color: "#FFFFFF",
              }}
              onClick={() => handleGuideStepIndexChange(currentStepIndex + 1)}
            >
              {currentStepIndex === 4 ? "Create" : "Continue"}
            </Button>
          </Flex>
        </div>
      </StylesContainer>
    </ConfigProvider>
  );
};

export default QuestionnaireContentWealth;
