import { useContext, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import dayjs from "dayjs";
import State from "../../../../../../../../context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Flex, Row, Dropdown, theme } from "antd";
import CardPortfolio from "../../../CardPortfolio";
import ChartGaugeLevel from "../../../../../../components/ChartGaugeLevel";
import ChartIncomeWithTypesLevel from "../../../ChartIncomeWithTypesLevel";
import ChartProjection from "../../../ChartProjection";
import ChartToggle from "./component/ChartToggle";
import FormMc from "./component/FormMc";
import FormStressTest from "./component/FormStressTest";
import InvestmentAssumptions from "./component/InvestmentAssumptions";
import ModalOptionsInvestmentCard from "../../../ModalOptionsInvestmentCard";
import SectionCompare from "../SectionCompare";

import StyledContainer from "./style";

import {
  CHART_VIEW_OBJECTIVE_GROWTH,
  CHART_VIEW_OBJECTIVE_INCOME,
} from "../../../../../../constants";
import {
  DEFAULT_BIRTH_YEAR,
  DEFAULT_WITHDRAWAL_LEVEL,
} from "../../../../../../../../utils/constant";

import { isGrowthTypeRiskTolerance } from "../../../../../../../../utils/helper/specialized";
import {
  getLiveAssessmentV2,
  getLiveSimulationV2,
  getLiveStressorV2,
  getMc,
} from "../../../../../../request";

import { ReactComponent as MainMenu } from "../../image/icon_main_menu.svg";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const SectionGoalDetails = ({
  handleCardClick,
  handleProposalDataChange,
  isCompareChartDirty,
  productsList,
  proposalData,
  setIsCompareChartDirty,
}) => {
  const { token } = theme.useToken();
  const [state] = useContext(State);
  const [loading, setLoading] = useState(true);
  const [productsLiveAssessment, setProductsLiveAssessment] = useState([]);
  const [productsLiveSimulation, setProductsLiveSimulation] = useState([]);
  const [stressorCalculations, setStressorCalculations] = useState(null);
  const [activeStressTestData, setActiveStressTestData] = useState(null);
  const [activeView, setActiveView] = useState("projection");
  const [mcData, setMcData] = useState(null);

  const selectedProductData = productsList?.find(
    it => it._id === state.selectedProduct
  );

  const birthYear =
    proposalData?.birthYear ??
    (proposalData?.currentAge
      ? dayjs().format("YYYY") - proposalData.currentAge
      : DEFAULT_BIRTH_YEAR);

  useEffect(() => {
    getStressorCalculations();
  }, [activeStressTestData, productsList, proposalData]);

  useEffect(() => {
    if (state.compareProducts?.length) {
      if (isCompareChartDirty || !productsLiveAssessment.length) {
        setLoading(true);
        getCompareModeCalculation();
      }
    }
  }, [isCompareChartDirty]);

  const getStressorCalculations = async () => {
    if (!activeStressTestData) {
      setStressorCalculations(null);
      setLoading(false);
      return;
    }

    const eventData = {
      ...activeStressTestData,
      key: activeStressTestData.key ?? "custom",
      startYear: activeStressTestData.startYear + 1,
    };

    const requestBody = {
      marketEvent: [eventData],
      ...proposalData,
      productId: proposalData.productId ?? productsLiveAssessment[0].productId,
      investmentDuration: +proposalData.investmentDuration,
      withdrawalLevel: proposalData.withdrawalLevel ?? DEFAULT_WITHDRAWAL_LEVEL,
    };

    try {
      const response = await getLiveStressorV2(requestBody);

      setStressorCalculations(response);
    } catch (error) {
      console.error("Error fetching stress test calculations:", error);
    }

    setLoading(false);
  };

  const getCompareModeCalculation = useDebouncedCallback(async () => {
    const organization =
      (state.organization?.name?.toLowerCase() ??
        state.orgName?.toLowerCase()) === "level"
        ? "level2"
        : (state.organization?.name ?? state.orgName);

    const responsesAssessment = [];
    const responsesSimulation = [];

    const assessmentPromises = state.compareProducts.map((productId, index) => {
      const requestBody = {
        ...proposalData,
        investmentDuration:
          proposalData.investmentDuration ?? proposalData.lengthOfInvestment,
        organization,
        productId,
      };

      return getLiveAssessmentV2(requestBody)
        .then(data => {
          responsesAssessment.push({
            productId,
            ...data,
            color: token.comparison_products_colors[index],
            shape: productId === selectedProductData._id ? "Line" : "Dash",
          });

          if (index === 0) {
            state.setKeyValue("productLiveAssessment", data);

            const insufficientFundsData = data?.main
              ?.filter(it => it.Date.includes("-12-"))
              ?.find(it => it.Date !== null && it.Withdrawals);

            if (insufficientFundsData) {
              state.setKeyValue(
                "insufficientFundsStartingIn",
                birthYear + insufficientFundsData[0]
              );
            } else {
              state.setKeyValue("insufficientFundsStartingIn", null);
            }
          }
        })
        .catch(error => state.showError(error));
    });

    const simulationPromises = state.compareProducts.map((productId, index) => {
      const requestBody = {
        organization,
        productId,
        ...proposalData,
        investmentDuration:
          proposalData.investmentDuration ?? proposalData.lengthOfInvestment,
        inputSettings: {},
        montecarlo: true,
        historicalAnalysis: true,
      };

      return getLiveSimulationV2(requestBody)
        .then(data => {
          if (index === 0) {
            // calculation for selected financial product
            // always at the first spot in the array

            // global value required by regression testing scripts
            window.lastQuickSim = data?.chanceOfSuccess;

            state.setKeyValue("productsLiveSimulation", data);
          }

          responsesSimulation.push({
            productId,
            ...data,
            color: token.comparison_products_colors[index],
            isComparedProduct: productId !== selectedProductData._id,
          });
        })
        .catch(error => state.showError(error));
    });

    await Promise.all(assessmentPromises);
    await Promise.all(simulationPromises);

    setProductsLiveAssessment(responsesAssessment);
    setProductsLiveSimulation(responsesSimulation);
    setLoading(false);
    setIsCompareChartDirty(false);
  }, 2000);

  useEffect(() => {
    if (state.selectedProduct) {
      if (
        !isGrowthTypeRiskTolerance(proposalData?.investmentObjective) &&
        proposalData?.investmentDuration <= proposalData?.yearToStartWithdrawals
      ) {
        state.showWarning(
          "Length of investment must be greater than the begin withdrawals year"
        );
        return;
      }

      setLoading(true);
      getCompareModeCalculation();
    }
  }, [state.selectedProduct, productsList, proposalData]);

  const handleButtonProductOptions = activeProductId => {
    state.setKeyValue("activeProductId", activeProductId);
    state.setKeyValue("openModalOptionsInvestmentCard", true);
  };

  const handleCloseCompareMode = () => {
    state.setKeyValue("compareProducts", [selectedProductData?._id]);
    setIsCompareChartDirty(true);
    state.setKeyValue("showCompareMode", false);
  };

  const handleCloseMonteCarlo = () => {
    state.setKeyValue("showMonteCarlo", false);
    setMcData(null);
  };

  const handleCloseStressTesting = () => {
    state.setKeyValue("showStressTesting", false);
    setActiveStressTestData(null);
  };

  const handleSelectOtherProduct = () => {
    state.setKeyValue("openModalOptionsInvestmentCard", false);
    state.setKeyValue("openModalRelatedProducts", true);
  };

  const handleRemoveFromThisSection = () => {
    state.setKeyValue(
      "compareProducts",
      state.compareProducts.filter(id => id !== state.activeProductId)
    );
    setIsCompareChartDirty(true);
    state.setKeyValue("openModalOptionsInvestmentCard", false);
  };

  const handleShowCompareMode = () => {
    const selectedProductRiskTolerance = selectedProductData.riskTolerance;
    const firstUnicRiskTolerance =
      selectedProductRiskTolerance === "Moderate" ? "Growth" : "Moderate";
    const secondUnicRiskTolerance =
      selectedProductRiskTolerance === "Aggressive"
        ? "Preservation"
        : "Aggressive";

    state.setKeyValue("compareProducts", [
      selectedProductData._id,
      productsList.find(it => it.riskTolerance === firstUnicRiskTolerance)?._id,
      productsList.find(it => it.riskTolerance === secondUnicRiskTolerance)
        ?._id,
    ]);

    setIsCompareChartDirty(true);
    state.setKeyValue("showCompareMode", true);
  };

  const handleShowMonteCarlo = () => {
    setLoading(true);

    const requestBody = {
      ...proposalData,
      productId: proposalData.productId ?? productsLiveAssessment[0].productId,
      investmentDuration: +proposalData.investmentDuration,
      withdrawalLevel: proposalData.withdrawalLevel ?? DEFAULT_WITHDRAWAL_LEVEL,
    };

    getMc(requestBody)
      .then(data => {
        setMcData(data);
        state.setKeyValue("showMonteCarlo", true);
        setActiveView("projection");
      })
      .catch(state.showError)
      .finally(() => setLoading(false));
  };

  return (
    <StyledContainer>
      <Row style={{ gap: 77 }} wrap={false}>
        <Col flex="213px">
          <InvestmentAssumptions
            handleProposalDataChange={handleProposalDataChange}
            loading={state.loading || loading}
            managerAccess={state.managerAccess}
            openAdvancedSettings={() =>
              state.setKeyValue("openModalAdvancedSettings", true)
            }
            organization={state.organization?.name}
            proposalData={proposalData}
          />
        </Col>

        <Col
          className={`container-hero-section ${
            state.showMonteCarlo ||
            state.showStressTesting ||
            state.showCompareMode
              ? "active"
              : ""
          }`}
          flex="auto"
        >
          <section>
            {state.showCompareMode && (
              <header>
                <h5>Compare Mode</h5>
                <Button onClick={handleCloseCompareMode} shape="round">
                  Close Compare Mode <FontAwesomeIcon icon={faClose} />
                </Button>
              </header>
            )}

            {state.showMonteCarlo && (
              <header>
                <h5>Monte Carlo</h5>
                <Button onClick={handleCloseMonteCarlo} shape="round">
                  Close Monte Carlo <FontAwesomeIcon icon={faClose} />
                </Button>
              </header>
            )}

            {state.showStressTesting && (
              <header>
                <h5>Stress Testing</h5>
                <Button onClick={handleCloseStressTesting} shape="round">
                  Close Stress Testing <FontAwesomeIcon icon={faClose} />
                </Button>
              </header>
            )}

            <Row className="container-chart" wrap={false}>
              <Col flex="auto">
                <Row
                  gutter={[22, 22]}
                  style={{ width: "100%", marginBottom: 30 }}
                >
                  <Col span={24}>
                    <Flex justify="space-between">
                      {!state.showMonteCarlo && (
                        <ChartToggle
                          activeView={activeView}
                          setActiveView={setActiveView}
                          views={
                            proposalData?.investmentObjective === "growth"
                              ? CHART_VIEW_OBJECTIVE_GROWTH
                              : CHART_VIEW_OBJECTIVE_INCOME
                          }
                        />
                      )}

                      <Dropdown
                        className="menu-view-mode"
                        disabled={loading}
                        menu={{
                          items: [
                            {
                              key: "stressTesting",
                              label: "Stress Testing",
                              onClick: () =>
                                state.setKeyValue("showStressTesting", true),
                            },
                            {
                              key: "compareMode",
                              label: "Compare Mode",
                              onClick: handleShowCompareMode,
                            },
                            {
                              key: "monteCarlo",
                              label: "Monte Carlo",
                              onClick: handleShowMonteCarlo,
                            },
                          ],
                        }}
                        trigger={["click"]}
                      >
                        <Button icon={<MainMenu />} shape="round" />
                      </Dropdown>
                    </Flex>

                    {activeView === "projection" ? (
                      <ChartProjection
                        activeStressTestData={activeStressTestData}
                        isStressorMode={state.showStressTesting}
                        loading={loading}
                        mcData={mcData}
                        productsList={productsList}
                        proposalData={proposalData}
                        productsLiveAssessment={productsLiveAssessment}
                        setActiveStressTestData={setActiveStressTestData}
                        stressorCalculations={stressorCalculations}
                        viewMode={state.viewMode}
                      />
                    ) : (
                      <ChartIncomeWithTypesLevel
                        activeStressTestData={activeStressTestData}
                        chartType={activeView}
                        height={300}
                        hideChartTypeButtons
                        isCompareMode={state.showCompareMode}
                        isStressorMode={state.showStressTesting}
                        loading={loading}
                        setLoading={setLoading}
                        productsList={productsList}
                        productsLiveAssessment={productsLiveAssessment}
                        productData={proposalData}
                        stressorCalculations={stressorCalculations}
                        viewMode={state.viewMode}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
              <Col flex="146px" style={{ height: "fit-content" }}>
                <ChartGaugeLevel
                  investmentObjective={proposalData?.investmentObjective}
                  loading={loading}
                  productsLiveSimulation={productsLiveSimulation}
                />
              </Col>
            </Row>
          </section>

          <footer>
            <Flex align="center" className="header" justify="space-between">
              <h4>How will this portfolio impact my scenario?</h4>
              <Button
                onClick={() =>
                  state.setKeyValue("openModalRelatedProducts", true)
                }
                shape="round"
              >
                Other Investments
              </Button>
            </Flex>

            <Flex gap={24}>
              <Flex vertical>
                <h5 className="card-portfolio-title">Selected Investment</h5>

                <CardPortfolio
                  chartLineColor={token.comparison_products_colors[0]}
                  chartLineShow={state.showCompareMode}
                  handleButtonDetails={() =>
                    handleCardClick(selectedProductData?._id)
                  }
                  handleButtonOptions={() =>
                    handleButtonProductOptions(selectedProductData?._id)
                  }
                  loading={state.loading || loading}
                  riskScore={selectedProductData?.riskScore}
                  riskTolerance={selectedProductData?.riskTolerance}
                  title={selectedProductData?.name}
                />
              </Flex>

              {state.showStressTesting && (
                <FormStressTest
                  onStressTestChange={setActiveStressTestData}
                  tabsList={[
                    { key: "bear", label: "Bear Market" },
                    { key: "bull", label: "Bull Market" },
                    { key: "inflation", label: "Inflation" },
                  ]}
                />
              )}

              {state.showMonteCarlo && <FormMc />}

              {state.showCompareMode && (
                <SectionCompare
                  handleButtonProductOptions={handleButtonProductOptions}
                  handleCardClick={handleCardClick}
                  loading={state.loading || loading}
                  openProductsListModal={() =>
                    state.setKeyValue("openModalRelatedProducts", true)
                  }
                  productsList={productsList}
                  selectedProductId={selectedProductData?._id}
                  state={state}
                />
              )}
            </Flex>
          </footer>
        </Col>
      </Row>

      <ModalOptionsInvestmentCard
        handleSelectOtherProduct={handleSelectOtherProduct}
        handleRemoveFromThisSection={handleRemoveFromThisSection}
        onCancel={() =>
          state.setKeyValue("openModalOptionsInvestmentCard", false)
        }
        open={state.openModalOptionsInvestmentCard}
        showButtonSelectOtherProduct={!state.showCompareMode}
        showButtonRemoveFromThisSection={
          state.showCompareMode &&
          state.activeProductId !== selectedProductData?._id
        }
      />
    </StyledContainer>
  );
};

export default SectionGoalDetails;
