import { useEffect } from "react";

import { Modal, Button, Flex, Form, InputNumber, Select } from "antd";

import {
  PROPOSAL_DEFAULT_DATA_LEVEL,
  PROPOSAL_GOAL_TYPE_LEVEL,
  RISK_TYPES_LEVEL,
} from "../../../../constants";

import {
  cleanCurrencyValue,
  getCurrencyValue,
  selectValue,
} from "../../../../../../utils/helper/general";

import Close from "../../../../../../icon/Close";

const ModalLevelGoalSettings = ({ onCancel, onSuccess, open }) => {
  const [form] = Form.useForm();
  const isGrowthGoalType =
    Form.useWatch("investmentObjective", form) === "growth";

  useEffect(() => {
    if (!open || !form) return;

    form.setFieldsValue(PROPOSAL_DEFAULT_DATA_LEVEL);
  }, [open]);

  return (
    <Modal
      closeIcon={<Close />}
      footer={
        <Flex justify="space-between">
          <Button onClick={onCancel} shape="round">
            Cancel
          </Button>
          <Button
            form="levelAddGoalForm"
            htmlType="submit"
            shape="round"
            type="primary"
          >
            Add Goal
          </Button>
        </Flex>
      }
      onCancel={onCancel}
      open={open}
      title="Goal Settings"
      width={370}
    >
      <Form
        form={form}
        id="levelAddGoalForm"
        layout="vertical"
        onFinish={onSuccess}
      >
        <Form.Item
          label="What’s your aim?"
          name="investmentObjective"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select options={PROPOSAL_GOAL_TYPE_LEVEL} />
        </Form.Item>
        <Form.Item
          label="What level of risk are you willing to take?"
          name="riskTolerance"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select style={{ width: "100%" }} options={RISK_TYPES_LEVEL} />
        </Form.Item>
        <Form.Item
          label="Investment Amount"
          name="investmentAmount"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            formatter={getCurrencyValue}
            max={100000000}
            min={0}
            onClick={selectValue}
            parser={cleanCurrencyValue}
            size="large"
            step={1000}
          />
        </Form.Item>
        <Form.Item
          hidden={isGrowthGoalType}
          label="Year to Start Withdrawals"
          name="yearToStartWithdrawals"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber max={80} min={1} onClick={selectValue} size="large" />
        </Form.Item>
        <Form.Item
          label="Length of Investment"
          name="investmentDuration"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            addonAfter={
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  options={[
                    {
                      label: "Years",
                      value: "years",
                    },
                    {
                      disabled: true,
                      label: "Quarters",
                      value: "quarters",
                    },
                    {
                      disabled: true,
                      label: "Months",
                      value: "months",
                    },
                  ]}
                  style={{ width: 120 }}
                  value="years"
                />
              </Form.Item>
            }
            max={80}
            min={10}
            onClick={selectValue}
            size="large"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalLevelGoalSettings;
