import { Button, Flex } from "antd";

import StylesContainer from "./style";

import { ReactComponent as IconAskAdvisor } from "../../images/icon_ask_advisor.svg";
import { ReactComponent as IconAskAi } from "../../images/icon_ask_ai.svg";

const ContainerHelpButtons = ({
  handleButtonClick,
  isAiButtonActive,
  isAdvisorButtonActive,
}) => {
  return (
    <Flex justify="center">
      <StylesContainer align="center" justify="center">
        <Button
          className={isAiButtonActive && "active"}
          icon={<IconAskAi />}
          onClick={() => handleButtonClick("ai")}
          shape="round"
          style={{ width: 145 }}
        >
          Ask our AI
        </Button>
        <Button
          className={isAdvisorButtonActive && "active"}
          icon={<IconAskAdvisor />}
          onClick={() => handleButtonClick("advisor")}
          shape="round"
          style={{ width: 173 }}
        >
          Ask an Advisor
        </Button>
      </StylesContainer>
    </Flex>
  );
};

export default ContainerHelpButtons;
