import HighStock from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import ChartSkeleton from "../../../../../../atom/ChartSkeleton";

import StylesContainer from "./style";

const SectionHistorical = ({ etfData, loading, product }) => {
  const options = {
    credits: {
      enabled: false,
    },
    series: [
      {
        data: etfData
          ? Object.keys(etfData.historical_prices[etfData.fund_symbol]).map(
              date => [
                new Date(date).getTime(),
                Math.round(
                  etfData.historical_prices[etfData.fund_symbol][date]
                ),
              ]
            )
          : [],
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, HighStock.getOptions().colors[0]],
            [
              1,
              HighStock.color(HighStock.getOptions().colors[0])
                .setOpacity(0)
                .get("rgba"),
            ],
          ],
        },
        name: product?.ticker ?? "SPY",
        threshold: null,
        type: "area",
      },
    ],
  };

  return (
    <StylesContainer>
      <ChartSkeleton loading={loading}>
        <HighchartsReact
          constructorType="stockChart"
          highcharts={HighStock}
          options={options}
        />
        <h3>About</h3>
        <p
          dangerouslySetInnerHTML={{
            __html: product?.objective ?? product?.Description,
          }}
        />
      </ChartSkeleton>
    </StylesContainer>
  );
};

export default SectionHistorical;
