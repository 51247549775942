import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  background: #f4f4f5;
  background-size: contain;
  box-sizing: border-box;
  font-family: Inter, sans-serif;
  height: 100vh;

  header {
    width: 100%;

    .copy-container {
      box-sizing: border-box;
      border-bottom: 1px solid #c9c8c8;
      padding: 20px 32px;

      h4 {
        color: #215054;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
        margin: 0;
      }

      .ant-btn {
        height: 36px;
      }
    }

    .ant-progress-line {
      display: block;
      margin-top: -8px;
    }
  }

  main {
    overflow: auto;
    padding: 56px;
  }

  .container-form {
    margin: 13px 0 32px;
    max-width: 400px;
    color: #1e1919;
    letter-spacing: -0.64px;

    h2 {
      font-size: 32px;
      font-weight: 700;
      line-height: 130%;
      margin: 0 0 16px;
    }

    p {
      font-size: 16px;
      line-height: 160%;
      letter-spacing: -0.32px;
      margin: 0 0 32px;
    }
  }

  .ant-input-number {
    width: 100%;
  }

  .aim-container {
    padding: 10px 0;
    width: 100%;

    .ant-radio-button-wrapper {
      align-items: center;
      background: #d8ebe4;
      border-color: #97cab2;
      border-bottom-width: 3px;
      border-radius: 32px;
      color: #1e1919;
      display: flex;
      font-size: 19px;
      justify-content: center;
      height: 63px;
      line-height: 26.4px;
      padding: 18px;
      text-align: center;
      width: 100%;

      span {
        display: flex;
        gap: 24px;
        min-width: 190px;
      }

      &.ant-radio-button-wrapper-checked:not(
          .ant-radio-button-wrapper-disabled
        ) {
        font-weight: 600;
      }

      &::before {
        display: none;
      }
    }
  }

  .risk-container {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 6px 19px 0 rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    padding: 32px;
    position: relative;
    width: 253px;

    &:before {
      background: linear-gradient(180deg, #a0bfc1 0%, #124f54 100%);
      border-radius: 23px;
      content: "";
      height: 209px;
      position: absolute;
      width: 16px;
    }

    .ant-divider-horizontal {
      margin: 0;
    }

    .ant-radio-wrapper {
      color: #052c2b;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 26.4px;
      padding-left: 50px;
    }
  }

  footer {
    align-items: center;
    backdrop-filter: blur(9px);
    background: rgba(255, 255, 255, 0.17);
    border-radius: 45px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-between;
    padding: 8px;

    .ant-btn {
      font-size: 16px;
      height: 40px;
    }
  }

  @media screen and (max-width: ${({ token }) => token.screenMDMax}px) {
    height: 100vh;
    overflow: auto;
    padding: 42px 25px 28px;

    header {
      padding-bottom: 16px;

      .logo {
        height: 38px;
        width: 123px;
      }
    }

    main {
      padding: 28px;
    }

    .container-form {
      margin: unset;
    }

    .image-promo {
      display: none;
    }

    footer {
      gap: 24px;
    }
  }
`;

export default StylesContainer;
