import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { theme } from "antd";
import ChartSkeleton from "../../atom/ChartSkeleton";

import { DEFAULT_BIRTH_YEAR } from "../../utils/constant";

import target_marker from "../../icon/target_marker.svg";
import {
  convertDecimalIntoDate,
  getCurrencyValue,
  getCurrencyValueWithRoundSign,
} from "../../utils/helper/general";

const ChartComparisonGrowth = ({
  chartRef,
  chartRender,
  handleLineHover,
  handleLineMouseOut,
  height,
  isStressorMode,
  loading,
  productsList,
  productsLiveAssessment,
  proposalData,
  stressorCalculations,
  viewMode,
}) => {
  const { token } = theme.useToken();
  const [growthChartSeries, setGrowthChartSeries] = useState([]);
  const localChartRef = useRef(null);

  useEffect(() => {
    if (viewMode === "pdf") {
      // required for Astor & Rivershares pdf generators
      const storedData = localStorage.getItem("growthChartSvg");
      if (!storedData) {
        const interval = setInterval(() => {
          const growthChartSvg = (
            chartRef ?? localChartRef
          )?.current?.chart?.getSVG();

          growthChartSvg &&
            localStorage.setItem("growthChartSvg", growthChartSvg);
        }, 1000);
        return () => clearInterval(interval);
      }
    }
  }, [viewMode]);

  useEffect(() => {
    if (productsLiveAssessment) {
      const series = [];

      productsLiveAssessment.map((it, index) => {
        series.push({
          color: it.color || token.comparison_products_colors[index],
          dashStyle: it.shape,
          data: it.grandTotalColumn,
          lineWidth: 4,
          marker: {
            enabled: false,
          },
          name: productsList.find(product => product._id === it.productId)
            ?.name,
          zoneAxis: "x",
        });

        if (it.gFutureProgress?.length) {
          series.push({
            color: "grey",
            dashStyle: "Dash",
            data: it.gFutureProgress,
            lineWidth: 4,
            name: "Future progress",
          });
        }
      });

      if (proposalData?.targetAmount && productsLiveAssessment?.length) {
        const currentYear = dayjs().format("YYYY");

        const targetAmountYear =
          proposalData.investmentObjective === "growth"
            ? productsLiveAssessment[0].grandTotalColumn[
                productsLiveAssessment[0].grandTotalColumn.length - 1
              ][0]
            : currentYear -
              (proposalData.birthYear ??
                currentYear - proposalData.currentAge) +
              +proposalData.yearToStartWithdrawals +
              1;

        series.push({
          name: "Target Amount",
          color: "#E4A428",
          data: [[targetAmountYear, proposalData.targetAmount]],
          marker: {
            enabled: true,
            symbol: `url(${target_marker})`,
          },
        });
      }

      setGrowthChartSeries(series);
    }
  }, [proposalData, productsLiveAssessment]);

  const generateChartData = () => {
    const series = [...growthChartSeries];

    if (stressorCalculations?.stressor?.length) {
      // Add Bear Market line if it exists
      const bearMarket = stressorCalculations.stressor.find(
        it => it.name === "Bear Market"
      );
      if (bearMarket) {
        series.push({
          name: "Bear Market",
          lineWidth: 4,
          color: "#1E1919",
          dashStyle: "Dot",
          marker: {
            enabled: false,
          },
          data: bearMarket.data,
        });
      }

      // Add Bull Market line if it exists
      const bullMarket = stressorCalculations.stressor.find(
        it => it.name === "Bull Market"
      );
      if (bullMarket) {
        series.push({
          name: "Bull Market",
          lineWidth: 4,
          color: "#1E1919",
          dashStyle: "Dot",
          marker: {
            enabled: false,
          },
          data: bullMarket.data,
        });
      }

      // Add Inflation line if it exists
      const inflation = stressorCalculations.stressor.find(
        it => it.name === "Inflation"
      );
      if (inflation) {
        series.push({
          name: "Inflation",
          lineWidth: 4,
          color: "#1E1919",
          dashStyle: "Dot",
          marker: {
            enabled: false,
          },
          data: inflation.data,
        });
      }
    }

    return series;
  };

  const birthYear =
    proposalData?.birthYear ??
    (proposalData?.currentAge
      ? dayjs().format("YYYY") - proposalData.currentAge
      : DEFAULT_BIRTH_YEAR);

  const chartConfig = {
    chart: {
      events: {
        render: function () {
          chartRender && chartRender(this);
        },
      },
      type: "spline",
      height,
      borderWidth: 0,
      spacingTop: 25,
      spacingRight: 25,
      spacingBottom: 24,
      backgroundColor: "transparent",
    },
    credits: false,
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
    legend: {
      enabled: !!stressorCalculations,
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
        events: {
          mouseOver: handleLineHover,
          mouseOut: handleLineMouseOut,
        },
      },
    },
    series: generateChartData(),
    title: {
      text: "",
    },
    tooltip: {
      enabled: !isStressorMode,
      useHTML: true,
      formatter: function () {
        return `
        <p><b>Date:</b> ${convertDecimalIntoDate(birthYear + this.x)}</p>
        <p><b>${this.series.name}:</b> ${getCurrencyValue(this.y)}</p>
      `;
      },
    },
    xAxis: {
      lineColor: "transparent",
      tickWidth: 0,
      labels: {
        style: {
          fontSize: 14,
          fontFamily: "Roboto, sans-serif",
          color: "#516474",
        },
        formatter: function () {
          return `'${(this.value + birthYear).toString().slice(2)}`;
        },
      },
    },
    yAxis: {
      min: 0,
      opposite: true,
      title: {
        text: null,
      },
      labels: {
        style: {
          fontSize: 14,
          fontFamily: "Roboto, sans-serif",
          color: "#354A5F",
        },
        formatter: data => getCurrencyValueWithRoundSign(data.value),
      },
    },
  };

  return (
    <ChartSkeleton loading={loading} height={height}>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartConfig}
        ref={chartRef ?? localChartRef}
      />
    </ChartSkeleton>
  );
};

export default ChartComparisonGrowth;
