import Dashboard from "../../../icon/Dashboard";
import Lead from "../../../icon/Lead";
import Client from "../../../icon/Client";
import AskPenny from "../../../icon/AskPenny";
import Lock from "../../../icon/Lock";
import Product from "../../../icon/Product";
import Marketing from "../../../icon/Marketing";
import User from "../../../icon/User";
import Proposal from "../../../icon/Proposal";
import Users from "../../../icon/Users";
import GoalsDashboard from "../../../icon/GoalsDashboard";
import Goals from "../../../icon/Goals";

export const SIDEBAR_TABS = {
  access: {
    affiliate: [
      {
        Icon: Dashboard,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: Lead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: Client,
        path: "/next-advisor-portal/clients",
        text: "Clients",
      },
      {
        Icon: AskPenny,
        key: "askPenny",
        path: "/next-advisor-portal/ask-penny",
        text: "AskPenny",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    affiliateNext: [
      {
        Icon: Dashboard,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: Lead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: Client,
        path: "/next-advisor-portal/clients",
        text: "Clients",
      },
      {
        Icon: AskPenny,
        key: "askPenny",
        path: "/next-advisor-portal/ask-penny",
        text: "AskPenny",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    affiliateNextProportal: [
      {
        Icon: Dashboard,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: Lead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: Client,
        path: "/next-advisor-portal/clients",
        text: "Clients",
      },
      {
        Icon: AskPenny,
        key: "askPenny",
        path: "/next-advisor-portal/ask-penny",
        text: "AskPenny",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    affiliateProportal: [
      {
        Icon: Dashboard,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: Lead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: Client,
        path: "/next-advisor-portal/clients",
        text: "Clients",
      },
      {
        Icon: AskPenny,
        key: "askPenny",
        path: "/next-advisor-portal/ask-penny",
        text: "AskPenny",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    level: [
      {
        Icon: Dashboard,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: Lead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: Client,
        path: "/next-advisor-portal/proposals",
        text: "Clients",
      },
      {
        Icon: Product,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        Icon: Marketing,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    levelNext: [
      {
        Icon: Dashboard,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: Lead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: Client,
        path: "/next-advisor-portal/proposals",
        text: "Clients",
      },
      {
        Icon: Product,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        Icon: Marketing,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    next: [
      {
        Icon: Dashboard,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: Lead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/proposals",
        text: "Clients",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    nextProportal: [
      {
        Icon: Dashboard,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: Lead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: Client,
        path: "/next-advisor-portal/proposals",
        text: "Clients",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    proportal: [
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: Lead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: Client,
        path: "/next-advisor-portal/clients",
        text: "Clients",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
  },
  astor: {
    admin: [
      {
        path: "/astor-admin-portal/advisors",
        Icon: User,
        text: "Advisors",
      },
      {
        path: "/astor-admin-portal/products",
        Icon: Product,
        text: "Products",
      },
      {
        path: "/astor-admin-portal/proposal",
        Icon: Proposal,
        text: "Proposal",
      },
      {
        path: "/astor-admin-portal/marketing",
        Icon: Marketing,
        text: "Marketing",
      },
      {
        path: "/astor-admin-portal/clients",
        Icon: Client,
        text: "Clients",
      },
    ],
    advisor: [
      {
        path: "/astor-advisor-portal/dashboard",
        Icon: User,
        text: "Dashboard",
      },
      {
        path: "/astor-advisor-portal/leads",
        Icon: Users,
        text: "Leads",
      },
      {
        path: "/astor-advisor-portal/clients",
        Icon: Client,
        text: "Clients",
      },
      {
        path: "/astor-advisor-portal/products",
        Icon: Product,
        text: "Products",
      },
      {
        path: "/astor-advisor-portal/marketing",
        Icon: Marketing,
        text: "Marketing",
      },
    ],
    proposal: [
      {
        Icon: Goals,
        path: "/astor-proposal",
        text: "Goals",
      },
    ],
  },
  disabled: [
    {
      disabled: true,
      Icon: Lock,
      path: "/next-advisor-portal/dashboard",
      text: "Dashboard",
    },
    {
      disabled: true,
      Icon: Lock,
      path: "/next-advisor-portal/leads",
      text: "Leads",
    },
    {
      disabled: true,
      Icon: Lock,
      path: "/next-advisor-portal/proposals",
      text: "Clients",
    },
    {
      disabled: true,
      Icon: Lock,
      path: "/next-advisor-portal/products",
      text: "Products",
    },
    {
      disabled: true,
      Icon: Lock,
      path: "/next-advisor-portal/marketing",
      text: "Marketing",
    },
  ],
  oneascent: {
    proposal: [
      {
        Icon: GoalsDashboard,
        path: "/oneascent-proposal/dashboard",
        text: "Dash",
      },
      {
        Icon: Goals,
        path: "/oneascent-proposal/goals",
        text: "Investments",
      },
    ],
  },
  rivershares: {
    proposal: [
      {
        Icon: Goals,
        path: "/rivershares-proposal",
        text: "Goals",
      },
    ],
  },
};
