import StyledDropdown from "./style";

import { ReactComponent as Ellipsis } from "./image/ellipsis_vertical.svg";

const MenuActions = ({ items }) => (
  <StyledDropdown
    menu={{
      items,
    }}
    trigger={["click"]}
  >
    <div className="icon-container">
      <Ellipsis />
    </div>
  </StyledDropdown>
);

export default MenuActions;
