import { Flex, Image } from "antd";

import StylesContainer from "./style";

import four_uses_money from "../../images/four_uses_money.png";
import four_uses_money_table from "../../images/four_uses_money_table.svg";

const TabForUsesMoney = () => (
  <StylesContainer className="content-container" gap={25} vertical>
    <h1>The Four Uses of Money</h1>
    <Flex gap={24} justify="space-between">
      <Flex style={{ width: 417 }} vertical>
        <p>
          Thank you for taking the <b>Perspectives</b> assessment!
        </p>
        <p>
          Every day, everyone interacts with the Four Uses of Money:{" "}
          <b>Spending, Saving, Giving,</b> and <b>Investing</b>.
        </p>
        <p>
          No one Use is better or worse than another. All are important, and
          it’s equally important to know which are most natural for us.
        </p>
        <p>
          After scoring your answers in our proprietary algorithm, we have
          produced your top Perspectives Profile matches.{" "}
          <b>
            On the next page, please select the Perspectives Profile that you
            believe is most true of you today.
          </b>
        </p>
        <p>
          This assessment is the beginning of the conversation, not the end!
          Vocalize the parts of your results that do and don’t align with your
          relationship with money. Conversations bring clarity, and clarity
          brings confidence!
        </p>
      </Flex>
      <Image preview={false} src={four_uses_money} />
    </Flex>
    <Image preview={false} src={four_uses_money_table} />
  </StylesContainer>
);

export default TabForUsesMoney;
