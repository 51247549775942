import dayjs from "dayjs";
import { Flex, Rate } from "antd";

import StylesContainer from "./style";

import { getLastSavedProposalProps } from "../../../../../../../../utils/helper/specialized";
import {
  getCurrencyValue,
  setPhoneMask,
} from "../../../../../../../../utils/helper/general";

const RecentLeadCard = ({ leadData }) => {
  const isAssignedLead = leadData?.entryPoint === "addedByOrgManager";

  let latestGoalData;

  if (!isAssignedLead) {
    latestGoalData = getLastSavedProposalProps(leadData?.valueMap?.productMap);
  }

  return isAssignedLead ? (
    // assigned by organization manager lead
    <StylesContainer justify="space-between">
      <Flex gap={3} vertical>
        <div className="name-label">
          {leadData?.firstName} {leadData?.lastName}
        </div>
        <div>
          Received:{" "}
          {dayjs(leadData?.valueMap?.leadInitial?.createdAt).format(
            "MMM D, YYYY"
          )}
        </div>
      </Flex>

      <Flex align="end" className="phone-label">
        {leadData?.phoneNumber ? setPhoneMask(leadData.phoneNumber) : " "}
      </Flex>

      <Flex align="end">
        <u className="email-label">{leadData?.email}</u>
      </Flex>

      <Rate disabled value={leadData?.valueMap?.leadInitial?.rate} />
    </StylesContainer>
  ) : (
    // created by user manager lead
    <StylesContainer justify="space-between">
      <Flex gap={3} vertical>
        <div className="name-label">
          {leadData?.firstName} {leadData?.lastName}
        </div>
        <div>
          Saved:{" "}
          {latestGoalData?.timestamp &&
            dayjs(latestGoalData.timestamp).format("MMM D, YYYY")}
        </div>
      </Flex>
      <Flex align="end">
        {getCurrencyValue(leadData?.valueMap?.leadInitial?.assets)}
      </Flex>
      <Flex align="end" className="product-label">
        {latestGoalData?.productName}
      </Flex>
      <Rate disabled value={leadData?.valueMap?.leadInitial?.rate} />
    </StylesContainer>
  );
};

export default RecentLeadCard;
