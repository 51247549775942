import { Typography } from "antd";

import StylesContainer from "./style";

const SeeDisclosures = ({ openModal }) => (
  <StylesContainer>
    * See disclosures for{" "}
    <Typography.Link
      style={{ textDecoration: "underline" }}
      onClick={openModal}
    >
      definition
    </Typography.Link>
  </StylesContainer>
);

export default SeeDisclosures;
