import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  &.ant-modal {
    .ant-modal-content {
      padding: 24px;
    }

    .ant-modal-header {
      margin-bottom: 40px;
    }

    .ant-modal-footer {
      margin-top: 40px;
    }
  }
`;

export default StyledModal;
