import { Button } from "antd";
import PageLevelProducts from "../../pages/PageLevelProducts";

import StyledModal from "./style";

const ModalLevelRelatedProducts = ({
  handleClose,
  handleSelectFinancialProduct,
  open,
  proposalData,
}) => (
  <StyledModal
    closeIcon={
      <Button shape="round" style={{ height: 36 }} onClick={handleClose}>
        Close
      </Button>
    }
    footer={false}
    onCancel={handleClose}
    open={open}
    title="Related Products"
    width={1151}
  >
    <PageLevelProducts
      handleActionClick={handleSelectFinancialProduct}
      proposalData={proposalData}
    />
  </StyledModal>
);

export default ModalLevelRelatedProducts;
