import { Flex } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import StyledBreadcrumb from "./style";

import {
  faCheck,
  faHourglassStart,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";

const BreadcrumbProposalStep = ({ activeStep }) => (
  <StyledBreadcrumb
    items={[
      {
        className: activeStep === "start" && "active",
        title: (
          <Flex align="center" gap={4}>
            {activeStep === "start" ? (
              <FontAwesomeIcon icon={faHourglassStart} />
            ) : (
              <FontAwesomeIcon icon={faCheck} />
            )}
            Start Planning
          </Flex>
        ),
      },
      {
        className: activeStep === "select" && "active",
        title: (
          <Flex align="center" gap={4}>
            {activeStep === "select" ? (
              <FontAwesomeIcon icon={faHourglassStart} />
            ) : activeStep === "review" ? (
              <FontAwesomeIcon icon={faCheck} />
            ) : (
              ""
            )}
            Select Investment
          </Flex>
        ),
      },
      {
        className: activeStep === "review" && "active",
        title: (
          <Flex align="center" gap={4}>
            {activeStep === "review" && (
              <FontAwesomeIcon icon={faHourglassStart} />
            )}
            Review Proposal
          </Flex>
        ),
      },
    ]}
    separator={<FontAwesomeIcon icon={faAngleRight} />}
  />
);

export default BreadcrumbProposalStep;
