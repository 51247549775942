import { useContext, useState } from "react";
import dayjs from "dayjs";
import State from "../../../../context";
import { Button, Flex, Input, theme, Tooltip } from "antd";
import MenuActions from "../../../../atom/MenuActions";
import ModalDeleteAdvisor from "../../../manager/component/ModalDeleteAdvisor";
import ModalDeleteUser from "../../component/ModalDeleteUser";
import ModalNextAdvisorSettings from "../../component/ModalNextAdvisorSettings";
import ModalResetUserPassword from "../../component/ModalResetUserPassword";

import { StyledTable } from "./style";

import {
  addAdvisor,
  deleteAdvisor,
  updateAdvisor,
} from "../../../../utils/request/manager";

import { ReactComponent as CheckedIcon } from "../../image/checked_circled.svg";

const CheckedItem = ({ isChecked, tooltip }) => (
  // do not use Fragment for correct alignment with unchecked items
  <div>
    {isChecked && (
      <Tooltip arrow={false} placement="bottom" title={tooltip}>
        <CheckedIcon />
      </Tooltip>
    )}
  </div>
);

const getColumns = ({
  searchedText,
  handleDeleteAction,
  handleEditAction,
  handleResetPasswordAction,
}) => [
  {
    dataIndex: "firstName",
    filteredValue: [searchedText],
    key: "advisorName",
    render: (value, restValues) =>
      `${restValues.firstName} ${restValues.lastName}`,
    onFilter: (value, record) =>
      String(record.firstName).toLowerCase().includes(value.toLowerCase()) ||
      String(record.lastName).toLowerCase().includes(value.toLowerCase()) ||
      String(record.email).toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => a.firstName?.localeCompare(b.firstName),
    title: "Advisor Name",
  },
  {
    dataIndex: "email",
    key: "email",
    render: value => <a href={`mailto:${value}`}>{value}</a>,
    sorter: (a, b) => a.email?.localeCompare(b.email),
    title: "Email",
  },
  {
    align: "center",
    dataIndex: "access",
    key: "next",
    render: access => <CheckedItem isChecked={access?.next} tooltip="Next" />,
    title: "Next",
    width: 80,
  },
  {
    align: "center",
    dataIndex: "access",
    key: "proportal",
    render: access => (
      <CheckedItem isChecked={access?.proportal} tooltip="ProPortal" />
    ),
    title: "ProPortal",
    width: 100,
  },
  {
    align: "center",
    dataIndex: "access",
    key: "affiliate",
    render: access => (
      <CheckedItem isChecked={access?.affiliate} tooltip="Affiliate" />
    ),
    title: "Affiliate",
    width: 90,
  },
  {
    align: "center",
    dataIndex: "access",
    key: "penny",
    render: access => <CheckedItem isChecked={access?.penny} tooltip="Penny" />,
    title: "Penny",
    width: 80,
  },
  {
    align: "left",
    dataIndex: "access",
    key: "level",
    render: access => <CheckedItem isChecked={access?.level} tooltip="Level" />,
    title: (
      <div>
        Level <i style={{ fontSize: 12 }}>(Demo only)</i>
      </div>
    ),
    width: 150,
  },
  {
    align: "right",
    dataIndex: "loginCount",
    key: "loginCount",
    sorter: (a, b) => a.loginCount - b.loginCount,
    title: "Logins",
    width: 80,
  },

  {
    align: "right",
    dataIndex: "lastLoginTime",
    key: "lastLogin",
    render: (lastLoginTime, allValues) =>
      allValues.loginCount
        ? dayjs(lastLoginTime?.$date).format("MM/DD/YYYY h:mma")
        : "",
    sorter: (a, b) =>
      dayjs(a.lastLoginTime?.$date) - dayjs(b.lastLoginTime?.$date),
    title: "Last Login",
    width: 150,
  },
  {
    dataIndex: "actionsMenu",
    className: "actions-menu-table-cell",
    fixed: "right",
    key: "actionsMenu",
    render: (value, restValues) => (
      <MenuActions
        items={getItems({
          props: restValues,
          handleDeleteAction,
          handleEditAction,
          handleResetPasswordAction,
        })}
      />
    ),
    title: "",
    width: 40,
  },
];

const getItems = ({
  props,
  handleDeleteAction,
  handleEditAction,
  handleResetPasswordAction,
}) =>
  [
    {
      key: "edit",
      label: "Edit",
      onClick: () => handleEditAction(props.email),
    },
    {
      key: "resetPass",
      label: "Reset Password",
      onClick: () => handleResetPasswordAction(props.email),
    },
    {
      type: "divider",
    },
    {
      key: "3",
      label: <span style={{ color: "red" }}>Delete User</span>,
      onClick: () => handleDeleteAction(props.email),
    },
  ].filter(it => !it.hidden);

const PageNextAdvisors = () => {
  const { token } = theme.useToken();
  const [searchedText, setSearchedText] = useState("");
  const [state] = useContext(State);

  const handleDeleteAction = email => {
    state.setKeyValue("activeAdvisorEmail", email);
    state.openModal("deleteAdvisorModal");
  };

  const handleEditAction = email => {
    state.setKeyValue("activeAdvisorEmail", email);
    state.openModal("nextAdvisorSettingsModal");
  };

  const handleResetPasswordAction = email => {
    state.setKeyValue("activeAdvisorEmail", email);
    state.openModal("resetUserPasswordModal");
  };

  const handleDeleteUser = () => {
    state.showSuccess(`Handle ${state.activeAdvisorEmail} delete`);
    state.closeModal("deleteUserModal");
  };

  const handleResetUserPassword = () => {
    state.showSuccess(`Handle ${state.activeAdvisorEmail} reset password`);
    state.closeModal("resetUserPasswordModal");
  };

  const handleNextAdvisorDataSave = values => {
    const isUpdateAdvisor = !!state.activeAdvisorEmail;

    const requestBody = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      access: {
        next: values.next,
        proportal: values.proportal,
        affiliate: values.affiliate,
        penny: values.penny,
        level: values.level,
      },
      firmName: values.firmName ?? "",
      brokerDealerName: "",
      userType: "Next",
      folders: JSON.stringify([]),
      onboardingQuestionnaireType: "",
    };

    const requestFeature = isUpdateAdvisor ? updateAdvisor : addAdvisor;

    requestFeature(requestBody)
      .then(() =>
        state.setAdminData().then(() => {
          state.closeModal("nextAdvisorSettingsModal");
          state.setKeyValue("activeAdvisorEmail", null);
          state.showSuccess(
            isUpdateAdvisor ? "Advisor updated" : "Advisor added"
          );
        })
      )
      .catch(error => state.showError(error));
  };

  const handleDeleteAdvisor = () => {
    state.setKeyValue("loading", true);

    deleteAdvisor(state.activeAdvisorEmail)
      .then(response => {
        state
          .setAdminData()
          .then(() => {
            state.showSuccess(response.data);
            state.setKeyValue("activeAdvisorEmail", null);
            state.closeModal("deleteAdvisorModal");
          })
          .catch(console.log);
      })
      .catch(error => {
        state.showError(error);
        state.setKeyValue("loading", false);
      });
  };

  return (
    <>
      <div
        style={{
          fontSize: 20,
          fontWeight: 800,
          color: token.colorPrimary,
          padding: "31px 46px",
          borderBottom: "1px solid #C9C8C8",
        }}
      >
        Next Advisors
      </div>
      <Flex gap={12} style={{ padding: "30px 109px 30px 46px" }} vertical>
        <Flex justify="space-between">
          <Input
            style={{
              maxWidth: 228,
              height: 37,
              borderRadius: 25,
            }}
            placeholder="Search..."
            onChange={event => setSearchedText(event.target.value)}
          />
          <Button
            onClick={() => state.openModal("nextAdvisorSettingsModal")}
            shape="round"
            size="small"
            style={{ background: "#03494E", color: "#FFFFFF" }}
            type="text"
          >
            + Add Advisor
          </Button>
        </Flex>
        <StyledTable
          columns={getColumns({
            searchedText,
            handleDeleteAction,
            handleEditAction,
            handleResetPasswordAction,
          })}
          dataSource={state.managedUserManagers?.map((it, index) => ({
            ...it,
            ...it.userManager,
            key: index,
          }))}
          loading={state.loading}
          scroll={{
            x: 1300,
            y: "calc(100vh - 320px)",
          }}
          token={token}
        />
      </Flex>

      <ModalDeleteAdvisor
        email={state.activeAdvisorEmail}
        handleOk={handleDeleteAdvisor}
        loading={state.loading}
        onCancel={() => state.closeModal("deleteAdvisorModal")}
        open={state.deleteAdvisorModal}
      />
      <ModalDeleteUser
        handleDeleteUser={handleDeleteUser}
        open={state.deleteUserModal}
        onCancel={() => {
          state.closeModal("deleteUserModal");
          state.setKeyValue("activeAdvisorEmail", null);
        }}
      />
      <ModalNextAdvisorSettings
        activeAdvisorData={state.managedUserManagers?.find(
          it => it.email === state.activeAdvisorEmail
        )}
        handleSave={handleNextAdvisorDataSave}
        open={state.nextAdvisorSettingsModal}
        onCancel={() => {
          state.closeModal("nextAdvisorSettingsModal");
          state.setKeyValue("activeAdvisorEmail", null);
        }}
      />
      <ModalResetUserPassword
        handleResetUserPassword={handleResetUserPassword}
        open={state.resetUserPasswordModal}
        onCancel={() => {
          state.closeModal("resetUserPasswordModal");
          state.setKeyValue("activeAdvisorEmail", null);
        }}
      />
    </>
  );
};

export default PageNextAdvisors;
