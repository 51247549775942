import axios from "axios";

export const addVbiFile = request_body =>
  axios.post(
    `${process.env.REACT_APP_API_GATEWAY}/oneascent/vbi/file`,
    request_body,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
    }
  );

export const downloadVbiFile = ({ advisor, client }) => {
  const fileUrl = `${process.env.REACT_APP_API_GATEWAY}/oneascent/vbi/downloadFile?advisor=${encodeURIComponent(advisor)}&client=${encodeURIComponent(client)}`;

  window.open(fileUrl, "_blank");
};

export const getQuizLink = requestBody =>
  axios
    .post(
      `${process.env.REACT_APP_API_GATEWAY}/oneascent/questionary/generate-url`,
      requestBody
    )
    .then(({ data }) => data?.url);

export const sendQuizLinkOnEmail = requestBody =>
  axios.post(
    `${process.env.REACT_APP_API_GATEWAY}/oneascent/questionary/send-email`,
    requestBody
  );

export const getPreferencesByJwt = ({ jwt }) =>
  axios.get(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/validateSurveyAnswered?jwt=${jwt}>`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );

export const saveQuiz = ({ jwt, requestPayload }) =>
  axios.patch(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/userPreferencesValueMap`,
    requestPayload,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
