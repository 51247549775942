import { useEffect } from "react";
import * as Sentry from "@sentry/react";

const LIST_DISABLED_ENVIRONMENTS = ["localhost"];

const SentryProvider = ({ children }) => {
  const isSentryDisabled = LIST_DISABLED_ENVIRONMENTS.includes(
    location.hostname
  );

  useEffect(() => {
    // disable Sentry initialization for some domains
    if (!isSentryDisabled) {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          }),
        ],
        tracesSampleRate: 0.3, //  Capture 30% of the transactions
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
    }
  }, []);

  return isSentryDisabled ? (
    children
  ) : (
    <Sentry.ErrorBoundary showDialog>{children}</Sentry.ErrorBoundary>
  );
};

export default SentryProvider;
