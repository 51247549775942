import styled from "styled-components";

const StyledContainer = styled.div`
  h4 {
    color: #215054;
    font-size: 18px;
    margin: 0 0 26px;
  }

  .ant-form-item {
    margin: unset;

    .ant-form-item-label > label {
      color: #1e1919;
      line-height: 22px;
    }
  }

  .ant-form-vertical
    .ant-form-item:not(.ant-form-item-horizontal)
    .ant-form-item-label {
    padding: unset;
  }

  .ant-select {
    background: #fff;
    border-radius: 5px;
  }

  .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    background: #fff;
    border: 1px solid #c9c8c8;
    border-radius: 5px;
  }
`;

export default StyledContainer;
