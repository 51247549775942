import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  &.ant-modal {
    .ant-modal-content {
      padding: 25px 32px 38px 28px;
    }

    .ant-modal-header {
      margin-bottom: 40px;
    }

    .ant-modal-footer {
      margin-top: 26px;

      .ant-btn {
        background: #d9f3f7;
        height: 40px;
      }
    }
  }

  .cell-proposed-aum,
  .ant-table-wrapper .ant-table-thead > tr > .cell-proposed-aum {
    background: #6dc1c730;
  }
`;

export default StyledModal;
