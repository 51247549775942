import styled from "styled-components";

const StyledContainer = styled.div`
  header {
    padding: 16px 32px;

    h4 {
      color: #1e1919;
      font-size: 15px;
      line-height: 24px;
      margin: 0;
    }
  }

  main {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px 32px 64px;
    width: 100%;

    .ant-table-wrapper .ant-table-thead > tr > th {
      background: #fff;
      font-weight: 700;

      &::before {
        display: none;
      }
    }

    .ant-table-wrapper .ant-table-thead > tr > .cell-last-login,
    .ant-table-wrapper .ant-table-tbody > tr > .cell-last-login {
      background: #f6f7f8;
    }
  }
`;

export default StyledContainer;
