import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  .header-container {
    align-items: center;
    border-bottom: 1px solid #a5a3a3;
    color: #4b4747;
    display: flex;
    font-size: 14px;
    justify-content: space-between;

    .ant-btn {
      color: #a5a3a3;
    }
  }

  .ant-checkbox-group {
    display: block;
  }

  .ant-checkbox-wrapper {
    margin: 8px 0;
    width: 100%;

    .ant-checkbox + span {
      width: 100%;
    }
  }
`;

export default StylesContainer;
