import { DEFAULT_WITHDRAWAL_START_YEAR } from "../../../../../utils/constant";

export const DEFAULT_PROPOSAL_DATA = {
  birthYear: 1972,
  contributions: 12000,
  investmentAmount: 100000,
  investmentDuration: 20,
  investmentObjective: "income",
  organization: "astor",
  riskTolerance: "",
  yearToStartWithdrawals: DEFAULT_WITHDRAWAL_START_YEAR,
};

export const PORTFOLIO_LIST = [
  {
    label: "20/80<br/>Income",
    series: [
      {
        color: "#1ABC82",
        y: 20,
      },
      {
        color: "#D9D9D9",
        y: 80,
      },
    ],
    value: "income",
  },
  {
    label: "40/60<br/>Conservative",
    series: [
      {
        color: "#1ABC82",
        y: 40,
      },
      {
        color: "#D9D9D9",
        y: 60,
      },
    ],
    value: "conservative",
  },
  {
    label: "60/40<br/>Moderate",
    series: [
      {
        color: "#1ABC82",
        y: 60,
      },
      {
        color: "#D9D9D9",
        y: 40,
      },
    ],
    value: "moderate",
  },
  {
    label: "80/20<br/>Aggressive",
    series: [
      {
        color: "#1ABC82",
        y: 80,
      },
      {
        color: "#D9D9D9",
        y: 20,
      },
    ],
    value: "aggressive",
  },
  {
    label: "100<br/>Equity",
    series: [
      {
        color: "#1ABC82",
        y: 100,
      },
    ],
    value: "equity",
  },
];

export const PRODUCT_LIST = [
  {
    allocation: {
      income: [
        {
          value: 40,
          series: [
            {
              color: "#0B2A46",
              y: 40,
              name: "Active Income",
            },
            {
              color: "#1ABC82",
              y: 40,
              name: "Fixed Income",
            },
            {
              color: "#D9D9D9",
              y: 20,
              name: "Equity",
            },
          ],
        },
        {
          value: 50,
          series: [
            {
              color: "#0B2A46",
              y: 50,
              name: "Active Income",
            },
            {
              color: "#1ABC82",
              y: 30,
              name: "Fixed Income",
            },
            {
              color: "#D9D9D9",
              y: 20,
              name: "Equity",
            },
          ],
        },
        {
          value: 60,
          series: [
            {
              color: "#0B2A46",
              y: 60,
              name: "Active Income",
            },
            {
              color: "#1ABC82",
              y: 20,
              name: "Fixed Income",
            },
            {
              color: "#D9D9D9",
              y: 20,
              name: "Equity",
            },
          ],
        },
        {
          value: 70,
          series: [
            {
              color: "#0B2A46",
              y: 70,
              name: "Active Income",
            },
            {
              color: "#1ABC82",
              y: 10,
              name: "Fixed Income",
            },
            {
              color: "#D9D9D9",
              y: 20,
              name: "Equity",
            },
          ],
        },
        {
          value: 80,
          series: [
            {
              color: "#0B2A46",
              y: 80,
              name: "Active Income",
            },
            {
              color: "#D9D9D9",
              y: 20,
              name: "Equity",
            },
          ],
        },
      ],
      conservative: [
        {
          value: 20,
          series: [
            {
              color: "#0B2A46",
              y: 20,
              name: "Active Income",
            },
            {
              color: "#1ABC82",
              y: 40,
              name: "Fixed Income",
            },
            {
              color: "#D9D9D9",
              y: 40,
              name: "Equity",
            },
          ],
        },
        {
          value: 30,
          series: [
            {
              color: "#0B2A46",
              y: 30,
              name: "Active Income",
            },
            {
              color: "#1ABC82",
              y: 30,
              name: "Fixed Income",
            },
            {
              color: "#D9D9D9",
              y: 40,
              name: "Equity",
            },
          ],
        },
        {
          value: 40,
          series: [
            {
              color: "#0B2A46",
              y: 40,
              name: "Active Income",
            },
            {
              color: "#1ABC82",
              y: 20,
              name: "Fixed Income",
            },
            {
              color: "#D9D9D9",
              y: 40,
              name: "Equity",
            },
          ],
        },
        {
          value: 50,
          series: [
            {
              color: "#0B2A46",
              y: 50,
              name: "Active Income",
            },
            {
              color: "#1ABC82",
              y: 10,
              name: "Fixed Income",
            },
            {
              color: "#D9D9D9",
              y: 40,
              name: "Equity",
            },
          ],
        },
        {
          value: 60,
          series: [
            {
              color: "#0B2A46",
              y: 60,
              name: "Active Income",
            },
            {
              color: "#D9D9D9",
              y: 40,
              name: "Equity",
            },
          ],
        },
      ],
      moderate: [
        {
          value: 10,
          series: [
            {
              color: "#0B2A46",
              y: 10,
              name: "Active Income",
            },
            {
              color: "#1ABC82",
              y: 30,
              name: "Fixed Income",
            },
            {
              color: "#D9D9D9",
              y: 60,
              name: "Equity",
            },
          ],
        },
        {
          value: 20,
          series: [
            {
              color: "#0B2A46",
              y: 20,
              name: "Active Income",
            },
            {
              color: "#1ABC82",
              y: 20,
              name: "Fixed Income",
            },
            {
              color: "#D9D9D9",
              y: 60,
              name: "Equity",
            },
          ],
        },
        {
          value: 30,
          series: [
            {
              color: "#0B2A46",
              y: 30,
              name: "Active Income",
            },
            {
              color: "#1ABC82",
              y: 10,
              name: "Fixed Income",
            },
            {
              color: "#D9D9D9",
              y: 60,
              name: "Equity",
            },
          ],
        },
        {
          value: 40,
          series: [
            {
              color: "#0B2A46",
              y: 40,
              name: "Active Income",
            },
            {
              color: "#D9D9D9",
              y: 60,
              name: "Equity",
            },
          ],
        },
      ],
      aggressive: [
        {
          value: 10,
          series: [
            {
              color: "#0B2A46",
              y: 20,
              name: "Active Income",
            },
            {
              color: "#D9D9D9",
              y: 80,
              name: "Equity",
            },
          ],
        },
        {
          value: 20,
          series: [
            {
              color: "#0B2A46",
              y: 10,
              name: "Active Income",
            },
            {
              color: "#1ABC82",
              y: 10,
              name: "Fixed Income",
            },
            {
              color: "#D9D9D9",
              y: 80,
              name: "Equity",
            },
          ],
        },
      ],
      equity: [],
    },
    color: "#02304F",
    description: `Certain active investment holdings serve specific roles within portfolios across diverse market conditions. When combined strategically, they act like a rudder, enabling intentional collaboration. Engineered to function actively, these holdings provide guidance and steering to portfolios through fluid market environments.With inflation and interest rate direction still uncertain, many investors seek guidance on the key factors for fixed income returns: duration and credit. Active Income focuses on these factors, having adjusted interest rate exposure in recent years to manage risks amid rising rates.<br/><br/><b><b>Best Pairing:</b> Conservative allocations or traditional Income exposures e.g.Barclays Aggregate Bond Index`,
    label: "Active Income",
    portfolios: ["income", "conservative"],
    productId: 70,
  },
  {
    allocation: {
      income: [
        {
          value: 10,
          series: [
            {
              color: "#0B2A46",
              y: 10,
              name: "Sector Allocation",
            },
            {
              color: "#D9D9D9",
              y: 10,
              name: "Equity",
            },
            {
              color: "#1ABC82",
              y: 80,
              name: "Fixed Income",
            },
          ],
        },
        {
          value: 20,
          series: [
            {
              color: "#0B2A46",
              y: 20,
              name: "Sector Allocation",
            },
            {
              color: "#1ABC82",
              y: 80,
              name: "Fixed Income",
            },
          ],
        },
      ],
      conservative: [
        {
          value: 10,
          series: [
            {
              color: "#0B2A46",
              y: 10,
              name: "Sector Allocation",
            },
            {
              color: "#D9D9D9",
              y: 30,
              name: "Equity",
            },
            {
              color: "#1ABC82",
              y: 60,
              name: "Fixed Income",
            },
          ],
        },
        {
          value: 20,
          series: [
            {
              color: "#0B2A46",
              y: 20,
              name: "Sector Allocation",
            },
            {
              color: "#D9D9D9",
              y: 20,
              name: "Equity",
            },
            {
              color: "#1ABC82",
              y: 60,
              name: "Fixed Income",
            },
          ],
        },
        {
          value: 30,
          series: [
            {
              color: "#0B2A46",
              y: 30,
              name: "Sector Allocation",
            },
            {
              color: "#D9D9D9",
              y: 10,
              name: "Equity",
            },
            {
              color: "#1ABC82",
              y: 60,
              name: "Fixed Income",
            },
          ],
        },
        {
          value: 40,
          series: [
            {
              color: "#0B2A46",
              y: 40,
              name: "Sector Allocation",
            },
            {
              color: "#1ABC82",
              y: 60,
              name: "Fixed Income",
            },
          ],
        },
      ],
      moderate: [
        {
          value: 20,
          series: [
            {
              color: "#0B2A46",
              y: 20,
              name: "Sector Allocation",
            },
            {
              color: "#D9D9D9",
              y: 40,
              name: "Equity",
            },
            {
              color: "#1ABC82",
              y: 40,
              name: "Fixed Income",
            },
          ],
        },
        {
          value: 30,
          series: [
            {
              color: "#0B2A46",
              y: 30,
              name: "Sector Allocation",
            },
            {
              color: "#D9D9D9",
              y: 30,
              name: "Equity",
            },
            {
              color: "#1ABC82",
              y: 40,
              name: "Fixed Income",
            },
          ],
        },
        {
          value: 40,
          series: [
            {
              color: "#0B2A46",
              y: 40,
              name: "Sector Allocation",
            },
            {
              color: "#D9D9D9",
              y: 20,
              name: "Equity",
            },
            {
              color: "#1ABC82",
              y: 40,
              name: "Fixed Income",
            },
          ],
        },
        {
          value: 50,
          series: [
            {
              color: "#0B2A46",
              y: 50,
              name: "Sector Allocation",
            },
            {
              color: "#D9D9D9",
              y: 10,
              name: "Equity",
            },
            {
              color: "#1ABC82",
              y: 40,
              name: "Fixed Income",
            },
          ],
        },
        {
          value: 60,
          series: [
            {
              color: "#0B2A46",
              y: 60,
              name: "Sector Allocation",
            },
            {
              color: "#1ABC82",
              y: 40,
              name: "Fixed Income",
            },
          ],
        },
      ],
      aggressive: [
        {
          value: 40,
          series: [
            {
              color: "#0B2A46",
              y: 40,
              name: "Sector Allocation",
            },
            {
              color: "#D9D9D9",
              y: 40,
              name: "Equity",
            },
            {
              color: "#1ABC82",
              y: 20,
              name: "Fixed Income",
            },
          ],
        },
        {
          value: 50,
          series: [
            {
              color: "#0B2A46",
              y: 50,
              name: "Sector Allocation",
            },
            {
              color: "#D9D9D9",
              y: 30,
              name: "Equity",
            },
            {
              color: "#1ABC82",
              y: 20,
              name: "Fixed Income",
            },
          ],
        },
        {
          value: 60,
          series: [
            {
              color: "#0B2A46",
              y: 60,
              name: "Sector Allocation",
            },
            {
              color: "#D9D9D9",
              y: 20,
              name: "Equity",
            },
            {
              color: "#1ABC82",
              y: 20,
              name: "Fixed Income",
            },
          ],
        },
        {
          value: 70,
          series: [
            {
              color: "#0B2A46",
              y: 70,
              name: "Sector Allocation",
            },
            {
              color: "#D9D9D9",
              y: 10,
              name: "Equity",
            },
            {
              color: "#1ABC82",
              y: 20,
              name: "Fixed Income",
            },
          ],
        },
        {
          value: 80,
          series: [
            {
              color: "#0B2A46",
              y: 80,
              name: "Sector Allocation",
            },
            {
              color: "#1ABC82",
              y: 20,
              name: "Fixed Income",
            },
          ],
        },
      ],
      equity: [
        {
          value: 60,
          series: [
            {
              color: "#0B2A46",
              y: 60,
              name: "Sector Allocation",
            },
            {
              color: "#D9D9D9",
              y: 40,
              name: "Equity",
            },
          ],
        },
        {
          value: 70,
          series: [
            {
              color: "#0B2A46",
              y: 70,
              name: "Sector Allocation",
            },
            {
              color: "#D9D9D9",
              y: 30,
              name: "Equity",
            },
          ],
        },
        {
          value: 80,
          series: [
            {
              color: "#0B2A46",
              y: 80,
              name: "Sector Allocation",
            },
            {
              color: "#D9D9D9",
              y: 20,
              name: "Equity",
            },
          ],
        },
        {
          value: 90,
          series: [
            {
              color: "#0B2A46",
              y: 90,
              name: "Sector Allocation",
            },
            {
              color: "#D9D9D9",
              y: 10,
              name: "Equity",
            },
          ],
        },
        {
          value: 100,
          series: [
            {
              color: "#0B2A46",
              y: 100,
              name: "Sector Allocation",
            },
          ],
        },
      ],
    },
    color: "#1971B9",
    description: `Certain active investment holdings serve specific roles within portfolios across diverse market conditions. When combined strategically, they act like a rudder, enabling intentional collaboration. Engineered to function actively, these holdings provide guidance and steering to portfolios through fluid market environments.The US stocks are typically categorized into industry groups that best define their business operations. Sector Allocation employs various indicators to switch between these sectors based on their growth expectations relative to the overall U.S. economic growth.<br/><br/><b><b>Best Pairing:<b/> Equity allocations or traditional equity exposures e.g. S&P 500`,
    label: "Sector Allocation",
    portfolios: ["moderate", "aggressive", "equity"],
    productId: 64,
  },
  {
    allocation: {
      income: [
        {
          value: 10,
          series: [
            {
              color: "#0B2A46",
              y: 10,
              name: "Dynamic Allocation",
            },
            {
              color: "#D9D9D9",
              y: 15,
              name: "Equity",
            },
            {
              color: "#1ABC82",
              y: 75,
              name: "Fixed Income",
            },
          ],
        },
        {
          value: 20,
          series: [
            {
              color: "#0B2A46",
              y: 20,
              name: "Dynamic Allocation",
            },
            {
              color: "#D9D9D9",
              y: 10,
              name: "Equity",
            },
            {
              color: "#1ABC82",
              y: 70,
              name: "Fixed Income",
            },
          ],
        },
        {
          value: 30,
          series: [
            {
              color: "#0B2A46",
              y: 30,
              name: "Dynamic Allocation",
            },
            {
              color: "#D9D9D9",
              y: 5,
              name: "Equity",
            },
            {
              color: "#1ABC82",
              y: 65,
              name: "Fixed Income",
            },
          ],
        },
        {
          value: 40,
          series: [
            {
              color: "#0B2A46",
              y: 40,
              name: "Dynamic Allocation",
            },
            {
              color: "#1ABC82",
              y: 60,
              name: "Fixed Income",
            },
          ],
        },
      ],
      conservative: [
        {
          value: 10,
          series: [
            {
              color: "#0B2A46",
              y: 10,
              name: "Dynamic Allocation",
            },
            {
              color: "#D9D9D9",
              y: 35,
              name: "Equity",
            },
            {
              color: "#1ABC82",
              y: 55,
              name: "Fixed Income",
            },
          ],
        },
        {
          value: 20,
          series: [
            {
              color: "#0B2A46",
              y: 20,
              name: "Dynamic Allocation",
            },
            {
              color: "#D9D9D9",
              y: 30,
              name: "Equity",
            },
            {
              color: "#1ABC82",
              y: 50,
              name: "Fixed Income",
            },
          ],
        },
        {
          value: 30,
          series: [
            {
              color: "#0B2A46",
              y: 30,
              name: "Dynamic Allocation",
            },
            {
              color: "#D9D9D9",
              y: 25,
              name: "Equity",
            },
            {
              color: "#1ABC82",
              y: 45,
              name: "Fixed Income",
            },
          ],
        },
        {
          value: 40,
          series: [
            {
              color: "#0B2A46",
              y: 40,
              name: "Dynamic Allocation",
            },
            {
              color: "#D9D9D9",
              y: 20,
              name: "Equity",
            },
            {
              color: "#1ABC82",
              y: 40,
              name: "Fixed Income",
            },
          ],
        },
      ],
      moderate: [
        {
          value: 30,
          series: [
            {
              color: "#0B2A46",
              y: 30,
              name: "Dynamic Allocation",
            },
            {
              color: "#D9D9D9",
              y: 45,
              name: "Equity",
            },
            {
              color: "#1ABC82",
              y: 25,
              name: "Fixed Income",
            },
          ],
        },
        {
          value: 40,
          series: [
            {
              color: "#0B2A46",
              y: 40,
              name: "Dynamic Allocation",
            },
            {
              color: "#D9D9D9",
              y: 40,
              name: "Equity",
            },
            {
              color: "#1ABC82",
              y: 20,
              name: "Fixed Income",
            },
          ],
        },
        {
          value: 50,
          series: [
            {
              color: "#0B2A46",
              y: 50,
              name: "Dynamic Allocation",
            },
            {
              color: "#D9D9D9",
              y: 35,
              name: "Equity",
            },
            {
              color: "#1ABC82",
              y: 15,
              name: "Fixed Income",
            },
          ],
        },
        {
          value: 60,
          series: [
            {
              color: "#0B2A46",
              y: 60,
              name: "Dynamic Allocation",
            },
            {
              color: "#D9D9D9",
              y: 30,
              name: "Equity",
            },
            {
              color: "#1ABC82",
              y: 10,
              name: "Fixed Income",
            },
          ],
        },
      ],
      aggressive: [
        {
          value: 10,
          series: [
            {
              color: "#0B2A46",
              y: 10,
              name: "Dynamic Allocation",
            },
            {
              color: "#D9D9D9",
              y: 75,
              name: "Equity",
            },
            {
              color: "#1ABC82",
              y: 15,
              name: "Fixed Income",
            },
          ],
        },
        {
          value: 20,
          series: [
            {
              color: "#0B2A46",
              y: 20,
              name: "Dynamic Allocation",
            },
            {
              color: "#D9D9D9",
              y: 70,
              name: "Equity",
            },
            {
              color: "#1ABC82",
              y: 10,
              name: "Fixed Income",
            },
          ],
        },
        {
          value: 30,
          series: [
            {
              color: "#0B2A46",
              y: 30,
              name: "Dynamic Allocation",
            },
            {
              color: "#D9D9D9",
              y: 65,
              name: "Equity",
            },
            {
              color: "#1ABC82",
              y: 5,
              name: "Fixed Income",
            },
          ],
        },
        {
          value: 40,
          series: [
            {
              color: "#0B2A46",
              y: 40,
              name: "Dynamic Allocation",
            },
            {
              color: "#D9D9D9",
              y: 60,
              name: "Equity",
            },
          ],
        },
      ],
      equity: [],
    },
    color: "#2AACE2",
    description: `Certain active investment holdings serve specific roles within portfolios across diverse market conditions. When combined strategically, they act like a rudder, enabling intentional collaboration. Engineered to function actively, these holdings provide guidance and steering to portfolios through fluid market environments. It serves as a risk-aware tool for higher-risk clients, adjusting equity exposure during economic shifts or overvalued markets. Low-risk clients can benefit from a modest allocation to manage market risk, adjustable based on prevailing conditions.<br/><br/><b>Best Pairing:</b> Growth, Moderate`,
    label: "Dynamic Allocation",
    portfolios: ["conservative", "moderate", "aggressive"],
    productId: 63,
  },
];

export const STEPS_COPY = {
  changeRisk: {
    description:
      "See Astor Products inside of commonly used portfolio strategies. Our products are engineered to keep your investments on track through periods of economic strength and weakness.",
    subTitle: "See Astor in a portfolio",
  },
  checkout: {
    description: "Just a few pieces of data to build your plan.",
    subTitle: "Let’s Power your plan",
  },
  portfolioDescription: {
    description:
      "See Astor Products inside of commonly used portfolio strategies. Our products are engineered to keep your investments on track through periods of economic strength and weakness.",
    subTitle: "See Astor in a portfolio",
  },
  selectAllocation: {
    description:
      "See Astor Products inside of commonly used portfolio strategies. Our products are engineered to keep your investments on track through periods of economic strength and weakness.",
    subTitle: "See Astor in a portfolio",
  },
  init: {
    description:
      "Explore our investment solutions or build a sample portfolio and see how our products can help you navigate the current and future market cycles.",
    title: "Plan with Astor",
  },
  recommendedPortfolios: {
    description:
      "Explore our investment solutions or build a sample portfolio and see how our products can help you navigate the current and future market cycles.",
    title: "Plan with Astor",
  },
};
