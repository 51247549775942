import logo from "../images/logo_main.png";

export default {
  adminHeaderBorderBottomColor: "#C6C6C6",
  admin_pie_chart_color_primary: "#F36835",
  admin_pie_chart_color_secondary: "#BEC8CE",
  admin_pie_chart_color_tertiary: "#354A5F",
  admin_pie_chart_color_rest: "#72C0C7",
  chart_colors: ["#4190D0", "#18355E", "#64C981", "#90D04D", "#00BDC4"],
  color_admin_analytic_value: "#131523",
  button_primary_background: "#04223A",
  radio_button_color: "#5DCA83",
  color_secondary_button: "#04223A",
  color_secondary_button_text: "#FFFFFF",
  color_tertiary_button: "#E7EBEE",
  color_tertiary_button_text: "#516474",
  logo_src: logo,
  modal_financial_product_details_header_background: "#E7EBEE",
  product_card_background: "linear-gradient(173deg, #FFFFFF 0%, #DEE8EF 100%)",
  card_financial_product_border: "1px solid #BFC8CE",
  proposal_guide_font_primary: "Museo Sans, sans-serif",
  proposal_guide_checkbox: {
    colorBorder: "#5F6060",
    colorText: "#234744",
  },
  proposal_guide_radio: {
    colorBorder: "#8396A6",
    colorPrimary: "#5DCA83",
    colorText: "#15284B",
    fontSize: 16,
    lineWidth: 2,
  },
  quarterly_goal_aum_color: "#4190D0",
  quarterly_goal_client_color: "#90D04D",
  quarterly_goal_proposal_color: "#18355E",
  sidebar_menu_item_hover_background: "#152A4E",
  sidebar_background: "#215783",
  sidebar_icon_color: "#BEC8CE",
  tenant_color_primary: "#4897D1",
};
