import { Flex, theme } from "antd";
import CardPortfolio from "../../../CardPortfolio";
import CompareCardPlaceholder from "./component/CompareCardPlaceholder";

import { MAX_COMPARE_PRODUCTS } from "../../../../../../../../utils/constant";

const SectionCompare = ({
  handleButtonProductOptions,
  handleCardClick,
  loading,
  openProductsListModal,
  productsList,
  selectedProductId,
  state,
}) => {
  const { token } = theme.useToken();

  const ListPlaceholders = () => {
    const freeSlotsAmount =
      MAX_COMPARE_PRODUCTS - state.compareProducts?.length;

    return Array.apply(null, Array(freeSlotsAmount)).map((ignore, index) => (
      <CompareCardPlaceholder
        handleClick={openProductsListModal}
        loading={loading}
        key={index}
      />
    ));
  };

  return (
    <div>
      <h5 className="card-portfolio-title">Similar Investments</h5>
      <Flex gap={20} style={{ overflowX: "auto", width: "100%" }}>
        {state.compareProducts
          ?.filter(id => id !== selectedProductId)
          ?.map(id => productsList?.find(it => it._id === id))
          ?.map((product, index) => (
            <CardPortfolio
              chartLineColor={token.comparison_products_colors[index + 1]}
              chartLineShow
              chartLineType="dash"
              handleButtonDetails={() => handleCardClick(product._id)}
              handleButtonOptions={() =>
                handleButtonProductOptions(product._id)
              }
              loading={loading}
              key={product._id}
              riskScore={product.riskScore}
              riskTolerance={product.riskTolerance}
              title={product.name}
            />
          ))}
        <ListPlaceholders />
      </Flex>
    </div>
  );
};

export default SectionCompare;
