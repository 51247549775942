import { useContext } from "react";
import State from "../../../../context/admin/State";
import ModalPrimary from "../../components/ModalPrimary";
import { Button, Flex } from "antd";

import IdentityManager from "../../../../requests/aws/IdentityManager";

const ModalUserResetPassword = ({ open }) => {
  const [state] = useContext(State);

  const handleClose = () => {
    state.setKeyValue("activeUserId", null);
    state.closeModal("userResetPasswordModal");
  };

  const handleReset = () => {
    IdentityManager.forgotPassword(
      state.user?.joined?.find(it => it._id === state.activeUserId).email
    )
      .then(() => {
        state.showSuccess(
          "Change password instruction has been sent to asset manager via email"
        );
        handleClose();
      })
      .catch(error => state.showError(error));
  };

  return (
    <ModalPrimary
      footer={
        <Flex justify="space-between">
          <Button onClick={handleClose} shape="round" size="large" type="text">
            Cancel
          </Button>
          <Button
            onClick={handleReset}
            shape="round"
            size="large"
            style={{ width: 206, background: "#001141", color: "#FFFFFF" }}
          >
            Reset
          </Button>
        </Flex>
      }
      open={open}
      onCancel={handleClose}
      title="Reset Password"
    >
      Are you sure you want to reset this password?
    </ModalPrimary>
  );
};

export default ModalUserResetPassword;
