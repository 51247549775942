import { useEffect } from "react";
import { Button, Flex } from "antd";

import StyledModal from "./style";

import Close from "../../../../icon/CloseOutlined";

const ModalSupport = ({ onCancel, open }) => {
  useEffect(() => {
    if (open) {
      hbspt.forms.create({
        region: "na1",
        portalId: "22416949",
        formId: "b746939d-3273-45bf-af37-674609842b32",
        target: "#formRoot",
      });
    }
  }, [open]);

  const handleGuideButtonClick = () => {
    window.open("/guide", "_blank");
  };

  return (
    <StyledModal
      closeIcon={<Close />}
      footer={
        <Flex justify="start">
          <Button onClick={onCancel} shape="round" type="text">
            Cancel
          </Button>
        </Flex>
      }
      onCancel={onCancel}
      open={open}
      title={
        <Flex
          align="center"
          justify="space-between"
          style={{ paddingRight: 40 }}
        >
          <b style={{ fontSize: 22 }}>Support</b>

          <Button onClick={handleGuideButtonClick} shape="round" type="primary">
            Help Guide
          </Button>
        </Flex>
      }
      width={550}
    >
      <div id="formRoot"></div>
    </StyledModal>
  );
};

export default ModalSupport;
