import { useContext, useState } from "react";
import dayjs from "dayjs";
import State from "../../../../context";
import { Flex, Input, Table } from "antd";
import AnalyticValue from "../../../../atom/AnalyticValue";
import MenuActions from "../../../../atom/MenuActions";
import ModalVbi from "../../../oneascent/component/ModalVbi";
import ModalViewIpq from "../../../oneascent/component/ModalViewIpq";

import StyledContainer from "./style";

import {
  getCurrencyValue,
  getCurrencyValueWithRoundSign,
} from "../../../../utils/helper/general";
import { openProposal } from "../../../../utils/request/manager";
import { getAllManagedProspectsOfAdvisors } from "../../../../utils/helper/specialized";
import { downloadVbiFile } from "../../../oneascent/request";
import { getManagedUserAssets } from "../../../oneascent/utils";

const PageAdminProspects = () => {
  const [state] = useContext(State);
  const [searched_text, setSearchedText] = useState("");

  const getTableData = () => {
    return getAllManagedProspectsOfAdvisors(state.managedUserManagers).map(
      it => ({
        ...it,
        assets: getManagedUserAssets(it),
        clientName: it.lastName + ", " + it.firstName,
        firmName: it.firmName ?? it.brokerDealerName,
        key: it.email,
      })
    );
  };

  const handleActionViewActiveProposal = ({ modeGoals, userEmail }) => {
    openProposal({
      modeGoals,
      organization: state.organization?.name,
      userEmail,
      windowName: "adminView",
    });
  };

  const handleActionViewIpq = email => {
    state.setKeyValue("activeEmail", email);
    state.setKeyValue("openModalViewIpq", true);
  };

  const columns = [
    {
      dataIndex: "clientName",
      filteredValue: [searched_text],
      onFilter: (value, record) =>
        String(record.clientName).toLowerCase().includes(value.toLowerCase()) ||
        String(record.email).toLowerCase().includes(value.toLowerCase()),
      render: (clientName, allValues) => (
        <a
          onClick={() =>
            openProposal({
              modeGoals: !!allValues.valueMap?.productMap,
              organization: state.organization?.name,
              userEmail: allValues.email,
            })
          }
        >
          {clientName}
        </a>
      ),
      sorter: (a, b) => a.clientName.localeCompare(b.clientName),
      title: "Prospect Name",
    },
    {
      dataIndex: "email",
      render: value => <a href={`mailto:${value}`}>{value}</a>,
      sorter: (a, b) => a.email.localeCompare(b.email),
      title: "Email",
    },
    {
      dataIndex: "advisorEmail",
      sorter: (a, b) => a.advisorEmail.localeCompare(b.advisorEmail),
      title: "Advisor",
    },
    {
      dataIndex: "firmName",
      sorter: (a, b) => a.firmName?.localeCompare(b.firmName),
      title: "Firm Name",
    },
    {
      align: "left",
      render: allValues =>
        allValues.onboardingQuestionnaireType === "IPQ" &&
        allValues.valueMap?.prospectObjective && (
          <a onClick={() => handleActionViewIpq(allValues.email)}>
            <u>View</u>
          </a>
        ),
      sorter: (a, b) =>
        (a.onboardingQuestionnaireType === "IPQ" &&
          !!a.valueMap?.prospectObjective) -
        (b.onboardingQuestionnaireType === "IPQ" &&
          !!b.valueMap?.prospectObjective),
      title: "IPQ",
    },
    {
      hidden: true,
      render: allValues => {
        const vbiStatus = allValues.vbi?.statusAdminView;

        if (vbiStatus === "Completed") {
          return (
            <a
              onClick={() =>
                downloadVbiFile({
                  advisor: allValues.advisorEmail,
                  client: allValues.email,
                })
              }
            >
              <u>Completed</u>
            </a>
          );
        } else {
          return vbiStatus;
        }
      },
      sorter: (a, b) =>
        (a.vbi?.statusAdminView ?? "")?.localeCompare(
          b.vbi?.statusAdminView ?? ""
        ),
      title: "VBI Audit",
    },
    {
      align: "right",
      render: allValues =>
        allValues.valueMap?.productMap && (
          <a
            onClick={() =>
              handleActionViewActiveProposal({
                modeGoals: true,
                userEmail: allValues.email,
              })
            }
          >
            <u>View</u>
          </a>
        ),
      sorter: (a, b) => !!a.valueMap?.productMap - !!b.valueMap?.productMap,
      title: "Active Proposal",
    },
    {
      align: "right",
      dataIndex: "assets",
      render: value => getCurrencyValue(value),
      sorter: (a, b) => a.assets - b.assets,
      title: "Assets",
    },
    {
      align: "right",
      render: allValues =>
        allValues.user?.lastAdvisorLoginTime?.$date &&
        dayjs(allValues.user.lastAdvisorLoginTime.$date).format(
          "MM/DD/YYYY h:mma"
        ),
      sorter: (a, b) =>
        dayjs(a.user?.lastAdvisorLoginTime?.$date) -
        dayjs(b.user?.lastAdvisorLoginTime?.$date),
      title: "Last Accessed",
    },
    {
      className: "actions-menu-table-cell",
      render: allValues =>
        allValues.vbi?.statusAdminView && (
          <MenuActions
            items={[
              {
                className: "action-download-vbi",
                disabled: allValues.vbi.statusAdminView === "Completed",
                key: "vbi_download",
                label: "Download VBI Audit",
                onClick: () => {
                  downloadVbiFile({
                    advisor: allValues.advisorEmail,
                    client: allValues.email,
                  });
                  setTimeout(() => state.setAdminData(), [1000]);
                },
              },
              {
                className: "action-upload-vbi",
                disabled: allValues.vbi.status === "Completed",
                key: "vbi_upload",
                label: "Upload VBI Audit Summary",
                onClick: () => {
                  state.setKeyValue(
                    "active_advisor_email",
                    allValues.advisorEmail
                  );
                  state.setKeyValue("activeEmail", allValues.email);
                  state.setKeyValue("open_modal_vbi", true);
                },
              },
            ]}
          />
        ),
      width: 40,
    },
  ].filter(it => !it.hidden);

  return (
    <StyledContainer>
      <header>Prospects</header>
      <Flex
        gap={30}
        style={{
          marginBottom: 16,
        }}
      >
        <Input
          style={{
            maxWidth: 228,
            height: 40,
            borderRadius: 25,
          }}
          placeholder="Search..."
          onChange={event => setSearchedText(event.target.value)}
        />
        <AnalyticValue label="Total Prospects" value={getTableData().length} />
        <AnalyticValue
          label="Total Proposed AUM"
          value={getCurrencyValueWithRoundSign(
            getTableData().reduce((acc, it) => acc + it.assets, 0)
          )}
        />
      </Flex>
      <Table
        columns={columns}
        dataSource={getTableData()}
        loading={state.loading}
        pagination={false}
      />

      <ModalVbi
        admin_view
        onCancel={() => state.setKeyValue("open_modal_vbi", false)}
        open={state.open_modal_vbi}
      />
      <ModalViewIpq
        activeUserData={getAllManagedProspectsOfAdvisors(
          state.managedUserManagers
        )?.find(it => it.email === state.activeEmail)}
        onCancel={() => state.setKeyValue("openModalViewIpq", false)}
        open={state.openModalViewIpq}
      />
    </StyledContainer>
  );
};

export default PageAdminProspects;
