import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Button, Flex, Image, theme } from "antd";
import QuestionnaireContentMovable from "../QuestionnaireContentMovable";
import StepInit from "./components/StepInit";

import StyledModal from "./style";

import { PERSPECTIVES_DEFAULT_STATE } from "./constant";

import logo from "../../image/logo_small_screen_view.svg";
import { ReactComponent as ArrowRight } from "./images/arrow_right.svg";
import Close from "../../../../../../icon/Close";

const ModalPerspectives = ({
  handleComplete,
  hideButtonCancel,
  hideButtonClose,
  onCancel,
  open,
}) => {
  const { token } = theme.useToken();
  const isSmallScreen = useMediaQuery({ maxWidth: token.screenSM });

  const [perspectivesProps, setPerspectivesProps] = useState(
    PERSPECTIVES_DEFAULT_STATE
  );
  const [currentStep, setCurrentStep] = useState("init");

  const handleButtonBack = () => {
    switch (currentStep) {
      case "moneyPrimarily":
        setCurrentStep("init");
        break;
      case "moreMoney":
        setCurrentStep("moneyPrimarily");
        break;
      case "lessMoney":
        setCurrentStep("moreMoney");
        break;
      case "describeMe":
        setCurrentStep("lessMoney");
        break;
      case "unexpectedlyReceived":
        setCurrentStep("describeMe");
        break;
    }
  };

  const handleButtonContinue = () => {
    switch (currentStep) {
      case "moneyPrimarily":
        setCurrentStep("moreMoney");
        break;
      case "moreMoney":
        setCurrentStep("lessMoney");
        break;
      case "lessMoney":
        setCurrentStep("describeMe");
        break;
      case "describeMe":
        setCurrentStep("unexpectedlyReceived");
        break;
      case "unexpectedlyReceived":
        handleComplete(perspectivesProps);
        onCancel && onCancel();
        setCurrentStep("init");
        setPerspectivesProps(PERSPECTIVES_DEFAULT_STATE);
        break;
    }
  };

  const reorderValue = ({ newIndex, value }) => {
    setPerspectivesProps(lastState => ({
      ...lastState,
      [currentStep]: {
        ...lastState[currentStep],
        customOrder: true,
        values: lastState[currentStep].values
          .filter(it => it.label !== value.label)
          .toSpliced(newIndex, 0, value),
      },
    }));
  };

  return (
    <StyledModal
      closable={!hideButtonClose && !isSmallScreen}
      closeIcon={<Close />}
      footer={
        currentStep === "init" ? (
          <Flex justify="space-between">
            <div>
              {!hideButtonCancel && (
                <Button onClick={onCancel} shape="round" size="large">
                  Cancel
                </Button>
              )}
            </div>
            <Button
              onClick={() => setCurrentStep("moneyPrimarily")}
              shape="round"
              size="large"
              style={{ background: "#428916", color: "#fff" }}
            >
              <Flex align="center" gap={8}>
                <span>Get Started</span>
                <ArrowRight />
              </Flex>
            </Button>
          </Flex>
        ) : (
          <Flex justify="space-between">
            <Button onClick={handleButtonBack} shape="round" size="large">
              Back
            </Button>
            <Button
              onClick={handleButtonContinue}
              shape="round"
              size="large"
              style={{ background: "#428916", color: "#fff" }}
            >
              {currentStep === "unexpectedlyReceived" ? (
                "Complete"
              ) : (
                <Flex align="center" gap={8}>
                  <span>Continue</span>
                  <ArrowRight />
                </Flex>
              )}
            </Button>
          </Flex>
        )
      }
      maskClosable={false}
      onCancel={onCancel}
      open={open}
      title={
        <Flex vertical>
          {isSmallScreen && (
            <Flex className="mobile-logo-container" justify="center">
              <Image src={logo} preview={false} />
            </Flex>
          )}
          Perspectives
        </Flex>
      }
      width={661}
    >
      {currentStep === "init" ? (
        <StepInit />
      ) : (
        <QuestionnaireContentMovable
          props={perspectivesProps[currentStep]}
          reorderValue={reorderValue}
          totalSteps={perspectivesProps.totalSteps}
        />
      )}
    </StyledModal>
  );
};

export default ModalPerspectives;
