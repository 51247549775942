import { Button, Flex } from "antd";

import StylesContainer from "./style";

import { ReactComponent as BlueArrow } from "../../../../images/blue_arrow_down.svg";

const CarouselSponsors = ({ handleClick, openModal, sponsors }) => (
  <StylesContainer gap={30} vertical>
    <Flex align="center" justify="space-between">
      <span style={{ fontSize: 20, fontWeight: 500, color: "#FFFFFF" }}>
        SPONSORS
      </span>
      <Button
        onClick={() => openModal("sponsorsListModal")}
        size="small"
        style={{ color: "#4EABE9" }}
        type="text"
      >
        VIEW ALL <BlueArrow style={{ rotate: "270deg", marginLeft: 6 }} />
      </Button>
    </Flex>
    <Flex className="carousel" gap={32}>
      {sponsors?.map(
        (it, index) =>
          it.logo?.url && (
            <img
              alt={it.label}
              className="pointer"
              key={index}
              onClick={() => handleClick(it)}
              src={it.logo.url}
              style={{ height: 60 }}
            />
          )
      )}
    </Flex>
  </StylesContainer>
);

export default CarouselSponsors;
