import styled from "styled-components";

const StylesContainer = styled.div`
  header {
    padding: 16px 32px;

    h4 {
      color: #1e1919;
      font-size: 15px;
      line-height: 24px;
      margin: 0;
    }
  }

  main {
    display: flex;
    gap: 36px;
    padding: 16px 32px 64px;
    width: 100%;

    .filters-container {
      background: #f1f5f6;
      border: 1px solid #d7d7d7;
      border-radius: 12px;
      height: fit-content;
      padding: 24px;
      min-width: 261px;
      width: 261px;
    }

    .ant-table-wrapper .ant-table-thead > tr > th {
      background: #fff;
      font-weight: 700;

      &::before {
        display: none;
      }
    }
  }
`;

export default StylesContainer;
