import styled from "styled-components";
import { Breadcrumb } from "antd";

const StyledBreadcrumb = styled(Breadcrumb)`
  background: #e6f4ef;
  border: 1px solid #e2e86c;
  border-radius: 8px;
  padding: 8px 16px;

  .ant-breadcrumb-link {
    color: #a5a3a3;

    &.active {
      color: #1e1919;
    }
  }
`;

export default StyledBreadcrumb;
