import { useEffect } from "react";
import { Button, Flex, Form, InputNumber, Select, Switch, Tabs } from "antd";

import StyledModal from "./style";

import {
  cleanCurrencyValue,
  cleanPercentValue,
  getCurrencyValue,
  getPercentValue,
  selectValue,
} from "../../../../../../utils/helper/general";

const ModalAdvancedSettings = ({
  handleClose,
  loading,
  onFinish,
  open,
  proposalData,
}) => {
  const [form] = Form.useForm();

  const isAnnuitiesOn = Form.useWatch("annuities_isOn", form);
  const isOtherIncomeOn = Form.useWatch("otherIncome_isOn", form);
  const isSocialSecurityOn = Form.useWatch("socialSecurity_isOn", form);

  useEffect(() => {
    if (open && proposalData) {
      form.setFieldsValue({
        ...proposalData,
        annuities_isOn: !!proposalData.annuities_isOn,
        otherIncome_isOn: !!proposalData.otherIncome_isOn,
        socialSecurity_isOn: !!proposalData.socialSecurity_isOn,
        annuitiesFrequency: "yearly",
        otherFrequency: "yearly",
        socialSecurityFrequency: "monthly",
      });
    }
  }, [open]);

  const GeneralTab = () => (
    <Flex
      align="center"
      gap={20}
      style={{ margin: "0 auto", width: 176 }}
      vertical
    >
      <Form.Item label="Advisor Fee" name="advisorFee">
        <InputNumber
          formatter={getPercentValue}
          max={20}
          min={0.25}
          onClick={selectValue}
          parser={cleanPercentValue}
          step={0.25}
          style={{ width: "100%" }}
        />
      </Form.Item>

      <Flex align="center" gap={8}>
        <Form.Item
          name="viewWithInflation"
          valuePropName="checked"
          style={{ margin: 0 }}
        >
          <Switch />
        </Form.Item>
        <span>View with Inflation</span>
      </Flex>
    </Flex>
  );

  const OtherIncomeTab = () => (
    <Flex className="container-tab-other" gap={16} vertical>
      <Flex gap={16}>
        <Form.Item name="socialSecurity_isOn" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item label="Social Security" name="socialSecurity">
          <InputNumber
            addonAfter={
              <Form.Item name="socialSecurityFrequency">
                <Select
                  disabled={true}
                  options={[
                    {
                      label: "Monthly",
                      value: "monthly",
                    },
                  ]}
                  style={{ width: 141 }}
                />
              </Form.Item>
            }
            disabled={!isSocialSecurityOn}
            formatter={getCurrencyValue}
            parser={cleanCurrencyValue}
          />
        </Form.Item>
      </Flex>

      <Flex gap={16}>
        <Form.Item name="annuities_isOn" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item label="Annuities" name="annuities">
          <InputNumber
            addonAfter={
              <Form.Item name="annuitiesFrequency">
                <Select
                  disabled={true}
                  options={[
                    {
                      label: "Yearly",
                      value: "yearly",
                    },
                  ]}
                  style={{ width: 141 }}
                />
              </Form.Item>
            }
            disabled={!isAnnuitiesOn}
            formatter={getCurrencyValue}
            parser={cleanCurrencyValue}
          />
        </Form.Item>
      </Flex>

      <Flex gap={16}>
        <Form.Item name="otherIncome_isOn" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item label="Other" name="otherIncome">
          <InputNumber
            addonAfter={
              <Form.Item name="otherFrequency">
                <Select
                  disabled={true}
                  options={[
                    {
                      label: "Yearly",
                      value: "yearly",
                    },
                  ]}
                  style={{ width: 141 }}
                />
              </Form.Item>
            }
            disabled={!isOtherIncomeOn}
            formatter={getCurrencyValue}
            parser={cleanCurrencyValue}
          />
        </Form.Item>
      </Flex>
    </Flex>
  );

  return (
    <StyledModal
      closeIcon={false}
      footer={
        <Flex justify="space-between">
          <Button onClick={handleClose} shape="round">
            Cancel
          </Button>
          <Button
            disabled={loading}
            form="advancedSettingsForm"
            htmlType="submit"
            shape="round"
            type="primary"
          >
            Apply
          </Button>
        </Flex>
      }
      onCancel={handleClose}
      open={open}
      title="Advanced Settings"
      width={512}
    >
      <Form
        form={form}
        id="advancedSettingsForm"
        layout="vertical"
        onFinish={onFinish}
      >
        <Tabs
          defaultActiveKey="general"
          items={[
            {
              children: <GeneralTab />,
              key: "general",
              label: "General",
            },
            {
              children: <OtherIncomeTab />,
              disabled: proposalData?.investmentObjective === "growth",
              key: "otherIncome",
              label: "Other Income",
            },
          ]}
        />
      </Form>
    </StyledModal>
  );
};

export default ModalAdvancedSettings;
