import { Flex, Image } from "antd";
import ReportHeader from "../../component/ReportHeader";

import StylesContainer from "./style";

import demo from "./image/demo.png";
import dayjs from "dayjs";

const ReportPageIntroduction = ({
  managerEmail,
  managerName,
  managerPhone,
  personaName,
}) => (
  <StylesContainer id="reportPageIntroduction">
    <div className="page-container">
      <ReportHeader personaName={personaName} />

      <h3>About Level Investment Group</h3>

      <main>
        <Flex flex="1 0 470px" gap={32} vertical>
          <p>
            For over 20 years, our Chicago-based asset management firm has been
            recognized for delivering customized investment solutions, backed by
            a strong foundation in quantitative analysis and innovative
            research. Our strategies span from generating consistent income to
            maximizing growth through active diversification in global equity
            markets. The firm’s dedicated investment team brings together a
            diversity of thought, comprised of both academicians and seasoned
            practitioners. This blend of academic insight and real-world
            experience ensures a dynamic approach to managing client portfolios.
            With a focus on data-driven decision-making, we deliver consistent,
            personalized results, aligning with each client's unique financial
            goals and risk tolerance.
          </p>
          <p>
            Customized investment strategies, coupled with the expertise of a
            seasoned financial advisor, provide a solid foundation for meeting
            financial objectives, whether the aim is to grow assets or generate
            steady income. Our active management approach involves ongoing
            portfolio adjustments to capitalize on growth opportunities or
            maintain consistent income streams, all while carefully managing
            risk to align with your financial goals.
          </p>
        </Flex>

        <Flex align="center" vertical>
          <Image preview={false} src={demo} />

          <div className="demo-description-container">
            <h3>Omnes Simul</h3>
            <p>
              Our phrase “all together” signifies that any success we achieve is
              a direct result of our collaboration with, and commitment to, the
              customer. Their involvement and satisfaction drive everything we
              do.
            </p>
          </div>
        </Flex>
      </main>

      <footer>
        <div>
          <p>Generated by:</p>
          <h4>{managerName}</h4>
          <p>{dayjs().format("MM/DD/YYYY")}</p>
        </div>

        <div>
          <p>421 Granger Place</p>
          <p>Suite 400</p>
          <p>Charlotte, NC 28307</p>
        </div>

        <div>
          <p>{managerEmail}</p>
          {managerPhone && <p>{managerPhone}</p>}
        </div>
      </footer>
    </div>
  </StylesContainer>
);

export default ReportPageIntroduction;
