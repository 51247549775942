import styled from "styled-components";
import { Flex } from "antd";

const StyledContainer = styled(Flex)`
  border-radius: 12px;
  box-shadow: 0 9px 25px 0 rgba(0, 0, 0, 0.14);
  color: #4b4747;
  font-size: 14px;
  font-style: normal;
  line-height: 105%;
  min-width: 471px;
  padding: 32px;
  width: 471px;

  .container-chart {
    width: 79px;
  }

  h2 {
    color: #052c2b;
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: 105%;
    margin: 5px 0;
  }

  .container-portfolio-values {
    background: #fbfbfb;
    border: 1px solid #c8e4ff;
    border-radius: 12px;
    padding: 11px 12px;

    .value {
      font-size: 24px;
      font-weight: 300;
      line-height: 110%;
      margin: unset;
    }
  }

  .button-download {
    background: #e6f4ef;
    height: 40px;
    width: 40px;
  }
`;

export default StyledContainer;
