import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Flex, Button, Switch, InputNumber, Form } from "antd";

import StyledTabs from "./style";

import {
  cleanPercentValue,
  getPercentValue,
  selectValue,
} from "../../../../../../../../../../utils/helper/general";

const FormStressTest = ({ onStressTestChange, tabsList }) => {
  const [form] = Form.useForm();
  const [activeScenarios, setActiveScenarios] = useState({
    bear: "2008",
    bull: "dotcom",
    inflation: "seventies",
    monteCarlo: "default",
  });

  const startYear = +dayjs().format("YYYY");

  const [customSettings, setCustomSettings] = useState({
    bear: { startYear, durationYears: 5, percent: -20 },
    bull: { startYear, durationYears: 5, percent: 20 },
    inflation: { startYear, durationYears: 5, percent: 8 },
    monteCarlo: {
      name: "Monte Carlo",
      label: "Monte Carlo",
      startYear,
      durationYears: 5,
      iterations: 1000,
    },
  });

  useEffect(() => {
    if (tabsList.find(it => it.key === "monteCarlo")) {
      form.setFieldsValue(customSettings.monteCarlo);
      runStressTesting({ key: "monteCarlo" });
    } else {
      form.setFieldsValue(customSettings.bear);
      runStressTesting({ key: "bear" });
    }
  }, []);

  const getScenariosByType = (type, startYear) => {
    const scenarios = {
      bear: [
        {
          key: "2008",
          name: <b>2008 Financial Crisis</b>,
          durationYears: 0.5,
          startYear,
          percent: -45.9,
        },
        {
          key: "dotcom",
          name: (
            <span>
              <b>‘00 - ‘02 Dot.com bubble</b> (March ‘00 - October ‘02)
            </span>
          ),
          durationYears: 2,
          startYear,
          percent: -49.1,
        },
        {
          key: "covid",
          name: (
            <span>
              <b>2020 Covid Pandemic</b> (February 20th / March 20th)
            </span>
          ),
          durationYears: 0.17,
          startYear,
          percent: -34,
        },
        {
          key: "custom",
          name: "Custom",
          durationYears: 5,
          startYear,
          percent: -20,
        },
      ],
      bull: [
        {
          key: "dotcom",
          name: (
            <span>
              <b>90s Tech Boom</b> (August 1995 - March 2000)
            </span>
          ),
          durationYears: 1,
          startYear,
          percent: 31,
        },
        {
          key: "postwar",
          name: (
            <span>
              <b>1950’s Post-War Boom</b> (1950 - 1973)
            </span>
          ),
          durationYears: 1,
          startYear,
          percent: 21.6,
        },
        {
          key: "custom",
          name: "Custom",
          durationYears: 5,
          startYear,
          percent: 20,
        },
      ],
      inflation: [
        {
          key: "seventies",
          name: (
            <span>
              <b>1970s: Mar ‘79 – Mar 80</b> (Oil Shocks Purchasing Power
              decline)
            </span>
          ),
          durationYears: 1,
          startYear,
          percent: 14.8,
        },
        {
          key: "postwar",
          name: (
            <span>
              <b>2022: Jun 21 – Jun 22</b> (Pandemic Shock Purchasing Power
              Decline)
            </span>
          ),
          durationYears: 1,
          startYear,
          percent: 21.6,
        },
        {
          key: "custom",
          name: "Custom",
          durationYears: 5,
          startYear,
          percent: 8,
        },
      ],
      monteCarlo: [
        {
          key: "default",
          name: "Default Settings",
          durationYears: 30,
          startYear,
          iterations: 1000,
        },
        {
          key: "custom",
          name: "Custom",
          durationYears: 5,
          startYear,
          iterations: 1000,
        },
      ],
    };

    return scenarios[type] || [];
  };

  const CustomInputs = ({ type }) => {
    const inputs = {
      monteCarlo: (
        <Form.Item label="Iterations" name="iterations">
          <InputNumber max={10000} min={100} onClick={selectValue} step={100} />
        </Form.Item>
      ),
      default: (
        <Form.Item
          label={type === "inflation" ? "Inflation Rate" : "Market Performance"}
          name="percent"
        >
          <InputNumber
            formatter={getPercentValue}
            max={type === "bear" ? 0 : 1000}
            min={type === "bear" ? -100 : 0}
            onClick={selectValue}
            parser={cleanPercentValue}
          />
        </Form.Item>
      ),
    };

    return (
      <Form form={form} id="formStessTestingCustomInputs" layout="vertical">
        <Flex align="end" gap={16}>
          {type !== "monteCarlo" && inputs.default}

          <Form.Item label="Start Year" name="startYear">
            <InputNumber min={+dayjs().format("YYYY")} onClick={selectValue} />
          </Form.Item>
          <Form.Item label="Duration" name="durationYears">
            <InputNumber
              formatter={value => (value ? `${value} years` : "")}
              max={30}
              min={1}
              onClick={selectValue}
              parser={value => value?.replace(" years", "")}
              step={1}
            />
          </Form.Item>

          {type === "monteCarlo" && inputs.monteCarlo}
        </Flex>
      </Form>
    );
  };

  const ScenarioList = ({ type }) =>
    getScenariosByType(type, startYear).map(scenario => (
      <Flex
        gap={13}
        key={scenario.key}
        style={{ borderBottom: "1px solid #E9E9E9", padding: "13px 0" }}
        vertical
      >
        <Flex align="center" gap={16}>
          <Switch
            checked={activeScenarios[type] === scenario.key}
            onChange={checked => {
              if (checked) {
                setActiveScenarios(prev => ({
                  ...prev,
                  [type]: scenario.key,
                }));
                if (scenario.key === "custom") {
                  setCustomSettings(prev => ({
                    ...prev,
                    [type]: { ...prev[type], startYear },
                  }));
                }
              }
            }}
          />
          <Flex vertical>
            {scenario.name}
            {scenario.key !== "custom" && (
              <i>
                {scenario.iterations
                  ? `${scenario.iterations} iterations over ${formatDuration(scenario.durationYears)}`
                  : `${scenario.percent}% over ${formatDuration(scenario.durationYears)}`}
              </i>
            )}
          </Flex>
        </Flex>
        {activeScenarios[type] === "custom" && scenario.key === "custom" && (
          <CustomInputs type={type} />
        )}
      </Flex>
    ));

  const formatDuration = years => {
    const months = Math.round(years * 12);
    return `${months} Months`;
  };

  const handleButtonApply = key => {
    setCustomSettings(lastState => ({
      ...lastState,
      [key]: {
        ...form.getFieldsValue(),
      },
    }));

    runStressTesting({ key, settings: form.getFieldsValue() });
  };

  const getTabContent = optionType => (
    <>
      <ScenarioList type={optionType} />

      <Flex justify="space-between" style={{ marginTop: 24 }}>
        <Button
          onClick={() => handleButtonApply(optionType)}
          shape="round"
          type="primary"
        >
          Apply
        </Button>
      </Flex>
    </>
  );

  const runStressTesting = ({ key, settings }) => {
    const activeOpt = tabsList.find(o => o.key === key);
    let activeObj = {
      name: activeOpt?.label,
      label: activeOpt?.label,
    };
    const activeScenarioKey = activeScenarios[key];
    if (activeScenarioKey) {
      if (activeScenarioKey === "custom") {
        activeObj = {
          ...(settings ?? customSettings[key]),
          ...activeObj,
          active: true,
          isCustomScenario: true,
        };
      } else {
        const scenario = getScenariosByType(key, startYear).find(
          s => s.key === activeScenarioKey
        );
        activeObj = {
          ...scenario,
          ...activeObj,
          active: true,
          isCustomScenario: false,
        };
      }
    }
    onStressTestChange?.(activeObj);
  };

  const onTabChange = key => form.setFieldsValue(customSettings[key]);

  return (
    <StyledTabs
      onChange={onTabChange}
      rootClassName={tabsList.length === 1 && "tabs-hidden-navbar"}
      items={tabsList.map(({ key, label }) => ({
        children: getTabContent(key),
        label,
        key,
      }))}
    />
  );
};

export default FormStressTest;
