import { useEffect } from "react";
import { Flex, Form, InputNumber, Select, theme } from "antd";

import StyledModal from "./style";

import {
  isOrganizationOneascent,
  isPortalAdmin,
} from "../../../../../../utils/helper/specialized";

import Close from "../../../../../../icon/Close";

const DefaultProductAssumptionsModal = ({
  handleClose,
  investmentAssumptions,
  open,
  organization,
}) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();

  useEffect(() => {
    if (!open || !form) return;

    form.setFieldsValue(investmentAssumptions);
  }, [open]);

  return (
    <StyledModal
      centered
      closeIcon={<Close />}
      footer={false}
      onCancel={handleClose}
      open={open}
      title="Default Product Assumptions"
      width={415}
    >
      <p className="hero-section">
        {isPortalAdmin()
          ? "All products shown use the following defaults. The ability to change the defaults will be made available in the near future."
          : "All products shown use the following defaults."}
      </p>
      <Flex gap={16} vertical>
        <Form form={form} id="formDefaultProductAssumptions" layout="vertical">
          <Form.Item
            label={token.proposal_copy_investment_amount}
            name="investmentAmount"
          >
            <InputNumber readOnly />
          </Form.Item>
          <Form.Item label="Length of Investment" name="investmentDuration">
            {isOrganizationOneascent(organization) ? (
              <InputNumber
                formatter={value => (value ? `${value} years` : "")}
                min={0}
                max={80}
                parser={value => value?.replace(" years", "")}
                readOnly
                style={{ width: "100%" }}
              />
            ) : (
              <InputNumber
                addonAfter={
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Select
                      options={[
                        {
                          label: "Years",
                          value: "years",
                        },
                        {
                          disabled: true,
                          label: "Quarters",
                          value: "quarters",
                        },
                        {
                          disabled: true,
                          label: "Months",
                          value: "months",
                        },
                      ]}
                      style={{ width: 120 }}
                      value="years"
                    />
                  </Form.Item>
                }
                readOnly
              />
            )}
          </Form.Item>
          <Form.Item label="Annual Target Income" name="withdrawalLevel">
            <InputNumber
              formatter={value => (value?.includes("%") ? value : `${value}%`)}
              max={100}
              min={1}
              parser={value => value.replace("%", "")}
              readOnly
            />
          </Form.Item>
          <Form.Item
            label="Begin Withdrawal after"
            name="yearToStartWithdrawals"
          >
            <InputNumber placeholder="N/A" readOnly />
          </Form.Item>
        </Form>
      </Flex>
    </StyledModal>
  );
};

export default DefaultProductAssumptionsModal;
