import { useContext, useMemo, useState } from "react";
import dayjs from "dayjs";
import State from "../../../../../../context";
import { Button, Input, Row, Space, Table, theme } from "antd";
import ButtonDemoMode from "../../../../../../atom/ButtonDemoMode";
import MenuActions from "../../../../../../atom/MenuActions";
import ModalAddAdvisor from "../ModalAddAdvisor";
import ModalDeleteAdvisor from "../../../../component/ModalDeleteAdvisor";
import ModalLatestProposals from "../ModalLatestProposals";
import SectionAdvisorsAnalytic from "../SectionAdvisorsAnalytic";

import StylesContainer from "./style";

import {
  deleteAdvisor,
  openAdvisorView,
} from "../../../../../../utils/request/manager";
import { getCurrencyValue } from "../../../../../../utils/helper/general";
import {
  getAllManagedUsersOfAdvisors,
  getLatestSavedProposalProps,
  getManagedUsers,
  getManagedUsersTotalProposedAmount,
  getProspectUsers,
  isAdminView,
} from "../../../../../../utils/helper/specialized";

import OpenInNew from "../../../../../../icon/OpenInNew";

const getItems = ({
  email,
  handleEditAction,
  handleDeleteAction,
  organization,
}) => [
  {
    key: "1",
    label: "Edit Advisor",
    onClick: () => handleEditAction(email),
  },
  {
    key: "2",
    label: (
      <Space>
        Open Advisor View
        <OpenInNew />
      </Space>
    ),
    onClick: () => openAdvisorView(email, organization),
  },
  {
    type: "divider",
  },
  {
    key: "3",
    label: <span style={{ color: "red" }}>Delete Advisor</span>,
    onClick: () => handleDeleteAction(email),
  },
];

const ViewAdvisors = ({ setActiveAdvisorEmail }) => {
  const { token } = theme.useToken();
  const [state] = useContext(State);
  const [searchedText, setSearchedText] = useState("");
  const [deletedAdvisorEmail, setDeletedAdvisorEmail] = useState();
  const [editedAdvisorEmail, setEditedAdvisorEmail] = useState();

  const organization = state.organization?.name;

  const countSavedProposals = users =>
    users
      ?.filter(it => it.proposalSavedDate || it.valueMap?.productMap)
      ?.reduce((acc, it) => {
        if (it.valueMap?.productMap) {
          // accumulate quantity of saved goals
          return acc + Object.keys(it.valueMap.productMap).length;
        } else {
          // goal is only one, add it
          return ++acc;
        }
      }, 0) ?? 0;

  const advisorsTableData = useMemo(
    () =>
      state.managedUserManagers?.map(
        ({
          userManager: advisorData = {},
          firstName,
          lastName = " ",
          email,
        }) => {
          let latestProposalDate;

          advisorData.managedUsers?.map(it => {
            if (it.proposalSavedDate || it.valueMap?.productMap) {
              if (!latestProposalDate) {
                latestProposalDate =
                  it.proposalSavedDate?.$date ||
                  getLatestSavedProposalProps(it.valueMap?.productMap)
                    ?.timestamp;
              } else if (
                dayjs(
                  it.proposalSavedDate?.$date ||
                    getLatestSavedProposalProps(it.valueMap?.productMap)
                      ?.timestamp
                ).isAfter(latestProposalDate)
              ) {
                latestProposalDate =
                  it.proposalSavedDate?.$date ||
                  getLatestSavedProposalProps(it.valueMap?.productMap)
                    ?.timestamp;
              }
            }
          });

          return {
            advisor: firstName ? firstName + " " + lastName : lastName,
            brokerName: advisorData.brokerDealerName ?? "",
            clients: getManagedUsers(advisorData)?.length ?? 0,
            clientsProposals: countSavedProposals(getManagedUsers(advisorData)),
            email,
            firmName: advisorData.firmName ?? "",
            key: email,
            latestProposalDate,
            proposedAum: getManagedUsersTotalProposedAmount(
              advisorData.managedUsers?.filter(
                it => it.proposalSavedDate || it.valueMap?.productMap
              )
            ),
            prospects: getProspectUsers(advisorData)?.length ?? 0,
            prospectsProposals: countSavedProposals(
              getProspectUsers(advisorData)
            ),
            type: advisorData.userType ?? "",
          };
        }
      ),
    [state.managedUserManagers]
  );

  const handleEditAction = advisorEmail => {
    setEditedAdvisorEmail(advisorEmail);
    state.openModal("addAdvisorModal");
  };

  const handleDeleteAction = advisorEmail => {
    setDeletedAdvisorEmail(advisorEmail);
    state.openModal("deleteAdvisorModal");
  };

  const handleOpenAddAdvisor = () => {
    setEditedAdvisorEmail(null);
    state.openModal("addAdvisorModal");
  };

  const handleDeleteAdvisor = () => {
    state.setKeyValue("loading", true);

    deleteAdvisor(deletedAdvisorEmail)
      .then(response => {
        state.setAdminData().then(() => {
          state.showSuccess(response.data);
          state.closeModal("deleteAdvisorModal");
          setDeletedAdvisorEmail(null);
        });
      })
      .catch(error => {
        state.setKeyValue("loading", false);
        state.showError(error);
      });
  };

  const getListLatestProposals = () => {
    const latestProposals = [];

    getAllManagedUsersOfAdvisors(state.managedUserManagers).map(it => {
      if (it.valueMap?.productMap) {
        Object.keys(it.valueMap.productMap).map(proposalId => {
          const timestamp =
            it.valueMap.productMap[proposalId]?.at(-1)?.timestamp;

          if (timestamp && dayjs(timestamp).isAfter(dayjs().subtract(7, "day")))
            latestProposals.push({
              ...it,
              latestProposalData: it.valueMap.productMap[proposalId].at(-1),
            });
        });
      }
    });

    return latestProposals.sort((a, b) => {
      const aProposalCreated = a.latestProposalData?.timestamp;
      const bProposalCreated = b.latestProposalData?.timestamp;

      return dayjs(bProposalCreated) - dayjs(aProposalCreated); // latest first
    });
  };

  const columns = [
    {
      dataIndex: "advisor",
      filteredValue: [searchedText],
      onFilter: (value, record) =>
        String(record.advisor).toLowerCase().includes(value.toLowerCase()) ||
        String(record.email).toLowerCase().includes(value.toLowerCase()),
      render: (text, restValues) => (
        <a onClick={() => setActiveAdvisorEmail(restValues.email)}>
          <b>{text}</b>
        </a>
      ),
      sorter: (a, b) => a.advisor?.localeCompare(b.advisor),
      title: "Advisor",
    },
    {
      dataIndex: "email",
      render: value => <a href={`mailto:${value}`}>{value}</a>,
      sorter: (a, b) => a.email?.localeCompare(b.email),
      title: "Email",
    },
    {
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.type?.localeCompare(b.type),
      title: "Type",
    },
    {
      dataIndex: "firmName",
      render: (_, allValues) => allValues.brokerName || allValues.firmName,
      sorter: (a, b) =>
        (a.brokerName || a.firmName)?.localeCompare(b.brokerName || b.firmName),
      title: "Firm Name",
    },
    {
      className: "cell-proposed-aum",
      dataIndex: "proposedAum",
      render: value => getCurrencyValue(value),
      sorter: (a, b) => a.proposedAum - b.proposedAum,
      title: "Proposed AUM",
    },
    {
      dataIndex: "clients",
      sorter: (a, b) => a.clients - b.clients,
      title: "Clients",
    },
    {
      dataIndex: "clientsProposals",
      sorter: (a, b) => a.clientsProposals - b.clientsProposals,
      title: "Client Proposals",
    },
    {
      dataIndex: "prospects",
      sorter: (a, b) => a.prospects - b.prospects,
      title: "Prospects",
    },
    {
      dataIndex: "prospectsProposals",
      sorter: (a, b) => a.prospectsProposals - b.prospectsProposals,
      title: "Prospect Proposals",
    },
    {
      dataIndex: "latestProposalDate",
      render: value => (value ? dayjs(value).format("MM/DD/YYYY") : "-"),
      sorter: (a, b, type) => {
        const aDate = a.latestProposalDate;
        const bDate = b.latestProposalDate;

        if (type === "descend") {
          // to put empty values at the end of the list
          if (!aDate) return -1;
          if (!bDate) return 1;
        }

        return dayjs(aDate) - dayjs(bDate);
      },
      title: "Latest Proposal",
    },
    {
      className: "actions-menu-table-cell",
      fixed: "right",
      render: (_, allValues) => (
        <MenuActions
          items={getItems({
            email: allValues.email,
            handleEditAction,
            handleDeleteAction,
            organization,
          })}
        />
      ),
      width: 40,
    },
  ];

  return (
    <StylesContainer>
      <SectionAdvisorsAnalytic />
      <Row justify="space-between" align="middle" style={{ margin: "28px 0" }}>
        <Input
          style={{
            maxWidth: 290,
            height: 37,
            borderRadius: 25,
          }}
          placeholder="Search Advisors..."
          onChange={event => setSearchedText(event.target.value)}
        />
        <Space size={15}>
          <ButtonDemoMode
            organization={state.organization?.name}
            userManagerEmail={state._id}
          />
          <Button
            disabled={state.loading || isAdminView()}
            onClick={handleOpenAddAdvisor}
            shape="round"
            size="small"
            style={{
              width: 118,
              color: token.button_primary_text_color,
              backgroundColor: token.button_primary_background,
            }}
          >
            + Add Advisor
          </Button>
        </Space>
      </Row>
      <Table
        columns={columns}
        dataSource={advisorsTableData}
        pagination={false}
        loading={state.loading}
        scroll={{
          x: 1450,
        }}
      />

      <ModalAddAdvisor
        open={state.addAdvisorModal}
        handleClose={() => state.closeModal("addAdvisorModal")}
        editedAdvisorEmail={editedAdvisorEmail}
      />
      <ModalDeleteAdvisor
        email={deletedAdvisorEmail}
        handleOk={handleDeleteAdvisor}
        loading={state.loading}
        onCancel={() => state.closeModal("deleteAdvisorModal")}
        open={state.deleteAdvisorModal}
      />
      <ModalLatestProposals
        listLatestProposals={getListLatestProposals()}
        onCancel={() => state.setKeyValue("openModalLatestProposals", false)}
        open={state.openModalLatestProposals}
        organizationName={state.organization?.name}
      />
    </StylesContainer>
  );
};

export default ViewAdvisors;
