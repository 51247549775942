import { useContext, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import State from "../../../../context";
import Overlay from "../../../../atom/Overlay";
import OverlayPdf from "../../../oneascent/pdf/component/OverlayPdf";
import ReportPdfContentHolder from "../../../oneascent/pdf/template/ReportPdfContentHolder";
import ViewProposalGoals from "../../component/ViewProposalGoals";
import ViewProposalGoal from "../../component/ViewProposalGoal";

import StylesContainer from "./style";

import { LIST_PDF_PAGE_PROPOSAL_ONEASCENT } from "../../../oneascent/constant";

import { isOrganizationOneascent } from "../../../../utils/helper/specialized";
import { getHtml2pdfPromiseList } from "../../../../utils/pdf";
import { getLatestSavedProposalId } from "../../helper";

const PageProposalGoals = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [state] = useContext(State);

  const modeDownloadPdf = searchParams.get("modeDownloadPdf");

  useEffect(() => {
    if (state.loading || state.showOverlay) return;

    if (modeDownloadPdf) {
      const productsMap = state.preferences?.valueMap?.productMap;

      if (!productsMap) {
        state.showWarning("No saved goals");
        return;
      }

      const latestSavedGoalId = getLatestSavedProposalId(productsMap);

      if (!latestSavedGoalId) {
        state.showWarning("No saved goals");
        return;
      }

      state.setKeyValue("activeGoal", latestSavedGoalId);
      handleGeneratePdf();
    }
  }, [state.loading, state.showOverlay, searchParams]);

  useEffect(() => {
    state.setKeyValue("viewMode", "goals");
  }, [location]);

  useEffect(() => {
    if (state.runPdfGenerator && !state.loadingPdfContent) {
      state.setKeyValue("runPdfGenerator", false);

      // extra time, to render all the charts and images, before making snapshots
      setTimeout(() => {
        getHtml2pdfPromiseList(LIST_PDF_PAGE_PROPOSAL_ONEASCENT)
          .output("bloburl")
          .then(function (pdf) {
            let pdfWindow = window.open("", "_blank");
            pdfWindow.document.write(
              "<iframe  width='100%' height='100%' src='" + pdf + "'></iframe>"
            );
            pdfWindow.document.title = "OneAscent PDF";

            state.setKeyValue("showPdfGeneratorOverlay", false);

            modeDownloadPdf && window.close();
          });
      }, 3000);
    }
  }, [state]);

  const handleAddGoal = () => {
    state.setKeyValue("activeGoal", undefined);
    state.setKeyValue("viewMode", "goal");
  };

  const handleCloseGoal = () => {
    state.setKeyValue("activeGoal", undefined);
    state.setKeyValue("viewMode", "goals");
  };

  const handleCopyGoal = activeGoal => {
    state.setKeyValue("activeGoal", activeGoal);
    state.setKeyValue("viewMode", "goal");

    setTimeout(() => {
      state.setKeyValue("activeGoal", undefined);
    }, 1000);
  };

  const handleGeneratePdf = () => {
    state.setKeyValue("showPdfGeneratorOverlay", true);
    state.setKeyValue("runPdfGenerator", true);
  };

  const handleViewGoal = goalId => {
    state.setKeyValue("activeGoal", goalId);
    state.setKeyValue("viewMode", "goal");
  };

  return (
    <Overlay loading={state.loading || state.viewMode === "pdf"}>
      <StylesContainer>
        {state.getPreferenceValue("productMap") &&
        state.viewMode === "goals" ? (
          <ViewProposalGoals
            handleAddGoal={handleAddGoal}
            handleCopyGoal={handleCopyGoal}
            handleGeneratePdf={handleGeneratePdf}
            handleViewGoal={handleViewGoal}
            productsList={state.productsList}
          />
        ) : (
          <ViewProposalGoal
            activeGoal={state.activeGoal}
            handleCloseGoal={handleCloseGoal}
            handleGeneratePdf={handleGeneratePdf}
            productsList={state.productsList}
          />
        )}

        {isOrganizationOneascent(state.organization?.name) && (
          <>
            <ReportPdfContentHolder />
            <OverlayPdf show={state.showPdfGeneratorOverlay} />
          </>
        )}
      </StylesContainer>
    </Overlay>
  );
};

export default PageProposalGoals;
