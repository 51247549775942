import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import Highcharts from "highcharts";
import exporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";
import { theme } from "antd";
import ChartSkeleton from "../../../../../../atom/ChartSkeleton";

import {
  ChartTypeButtonsContainer,
  NoContributionsContainer,
  StyledButton,
} from "./style";

import {
  cleanPercentValue,
  getCurrencyValue,
  getCurrencyValueWithRoundSign,
} from "../../../../../../utils/helper/general";
import { isGrowthTypeRiskTolerance } from "../../../../../../utils/helper/specialized";
import { chartRender } from "../ChartProjection/chart-addons";

exporting(Highcharts);

const ChartIncomeWithTypesLevel = ({
  activeStressTestData,
  chartType = "",
  height,
  hideChartTypeButtons,
  isCompareMode,
  isStressorMode,
  loading,
  setLoading,
  productsLiveAssessment,
  productsList,
  productData,
  stressorCalculations,
  viewMode,
}) => {
  const { token } = theme.useToken();
  const [incomeChartType, setIncomeChartType] = useState(chartType);
  const chartRef = useRef(null);

  // data represented by month, and withdrawals are made each year in current date
  // search current date in each year
  const searchDate = dayjs().format("MM-DD");

  useEffect(() => {
    if (chartType !== incomeChartType) {
      setIncomeChartType(chartType);

      if (setLoading) {
        // required to re-initialize highcharts chart and render new series
        //
        // https://stackoverflow.com/questions/6604291/proper-way-to-remove-all-series-data-from-a-highcharts-chart
        //  while (chart.series.length > 0) {
        //   chart.series[0].remove(false);
        //  }
        //
        // do not works for us
        // because series with "line" type do not have "remove" method
        setLoading(true);

        setTimeout(() => setLoading(false), 500);
      }
    }
  }, [chartType]);

  useEffect(() => {
    if (viewMode === "pdf") {
      // required for Astor & Rivershares pdf generators
      const storedData = localStorage.getItem("barsChartSvg");

      const interval = setInterval(() => {
        if (!storedData) {
          const barsChartSvg = chartRef?.current?.chart?.getSVG();

          barsChartSvg && localStorage.setItem("barsChartSvg", barsChartSvg);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [viewMode]);

  useEffect(() => {
    const investmentObjectiveChartType = isGrowthTypeRiskTolerance(
      productData?.investmentObjective
    )
      ? "contributions"
      : "income";

    if (incomeChartType !== investmentObjectiveChartType && chartType === "")
      setIncomeChartType(investmentObjectiveChartType);
  }, [productData]);

  const getSeries = () => {
    if (productsLiveAssessment?.length) {
      if (incomeChartType === "contributions") {
        // contribution chart
        return [
          {
            name: "Contributions",
            color: token.income_chart_with_types_color_contributions,
            data: productsLiveAssessment[0].main
              .filter(it => it.Date.includes(searchDate))
              .map(it => [
                +dayjs(it.Date).format("YYYY"),
                Math.round(it.Contributions),
              ]),
            visible: productData?.contributions !== 0,
            borderRadius: "6px",
            groupPadding: 0.05,
            pointPadding: 0.05,
          },
        ];
      } else if (isCompareMode) {
        // income chart in the compare product mode
        const series = productsLiveAssessment.map(it => {
          const name = productsList.find(
            product => product._id === (it.productId ?? it._id)
          )?.name;

          return {
            borderRadius: "6px",
            color: it.color,
            data: it.main
              .filter(it => it.Date.includes(searchDate))
              .map(it => [
                +dayjs(it.Date).format("YYYY"),
                Math.round(it.Withdrawals),
              ]),
            groupPadding: 0.05,
            name: `Withdrawals (${name})`,
            pointPadding: 0.05,
            showInLegend: false,
            stack: name,
          };
        });

        addSeriesAnnualIncomeTarget(series);

        return series;
      } else {
        // income chart in the default view proposal mode
        return structuredClone(productsLiveAssessment)
          .map(it => {
            const series = [];

            addSeriesAnnualIncomeTarget(series);

            const hideDetailedNames = productsLiveAssessment.length === 1;
            const name = productsList.find(
              product => product._id === (it.productId ?? it._id)
            )?.name;

            const listData = it.main;

            series.push({
              borderRadius: "6px",
              color: token.income_chart_with_types_color_withdrawal,
              data: listData
                .filter(it => it.Date.includes(searchDate))
                .map(it => [+dayjs(it.Date).format("YYYY"), it.Withdrawals]),
              groupPadding: 0.05,
              name:
                name && !hideDetailedNames
                  ? `Withdrawals (${name})`
                  : "Withdrawals",
              pointPadding: 0.05,
              stack: name,
              visible: true,
            });

            productData?.annuities_isOn &&
              series.push({
                borderRadius: "6px",
                color: token.income_chart_with_types_color_annuities,
                data: listData
                  .filter(it => it.Date.includes(searchDate))
                  .map(it => [+dayjs(it.Date).format("YYYY"), it.Annuities]),
                groupPadding: 0.05,
                name:
                  name && !hideDetailedNames
                    ? `Annuities (${name})`
                    : "Annuities",
                pointPadding: 0.05,
                stack: name,
                visible: true,
              });

            productData?.otherIncome_isOn &&
              series.push({
                borderRadius: "6px",
                color: token.income_chart_with_types_color_other_income,
                data: listData
                  .filter(it => it.Date.includes(searchDate))
                  .map(it => [+dayjs(it.Date).format("YYYY"), it.OtherIncome]),
                groupPadding: 0.05,
                name:
                  name && !hideDetailedNames
                    ? `Other Income (${name})`
                    : "Other Income",
                pointPadding: 0.05,
                stack: name,
                visible: true,
              });

            productData?.socialSecurity_isOn &&
              series.push({
                borderRadius: "6px",
                color: token.income_chart_with_types_color_social_security,
                data: listData
                  .filter(it => it.Date.includes(searchDate))
                  .map(it => [
                    +dayjs(it.Date).format("YYYY"),
                    it.SocialSecurity,
                  ]),
                groupPadding: 0.05,
                name:
                  name && !hideDetailedNames
                    ? `Social Security (${name})`
                    : "Social Security",
                pointPadding: 0.05,
                stack: name,
                visible: true,
              });

            addSeriesStressTest(series);

            return series;
          })
          .flat();
      }
    }

    return [];
  };

  const addSeriesAnnualIncomeTarget = series => {
    if (!productData?.withdrawalLevel) return;

    let incomeTargetValue = 0;

    if (isNaN(productData.withdrawalLevel)) {
      // percent amount
      const yearCurrent = +dayjs().format("YYYY");
      const yearWithdrawalStart =
        yearCurrent + productData.yearToStartWithdrawals - 1;
      const withdrawalPercent =
        cleanPercentValue(productData.withdrawalLevel) / 100;

      incomeTargetValue =
        productsLiveAssessment
          // to be sure, that used selected product data in case of compare mode
          ?.find(it => it.productId === productData.productId)
          ?.main.find(
            it =>
              it.Date.includes(yearWithdrawalStart + "") &&
              it.Date.includes("-12-") // last month value
          )?.Value * withdrawalPercent;
    } else {
      // dollar amount
      incomeTargetValue = +productData.withdrawalLevel;
    }

    series.push({
      color: "#1E1919",
      data: productsLiveAssessment[0].main
        .filter(it => it.Date.includes(searchDate))
        .map(it => [+dayjs(it.Date).format("YYYY"), incomeTargetValue]),
      lineWidth: 2,
      name: "Annual Income Target",
      type: "line",
      marker: {
        enabled: false,
      },
    });
  };

  const addSeriesStressTest = series => {
    if (!isStressorMode || !stressorCalculations?.event) return;

    structuredClone(productsLiveAssessment).map(it => {
      const name =
        productsList.find(product => product._id === (it.productId ?? it._id))
          ?.name + "__stress_test";

      const listData = stressorCalculations.event[0].data;

      series.push({
        borderRadius: "6px",
        color: token.income_chart_with_types_color_withdrawal,
        data: listData
          .filter(it => it.Date.includes(searchDate))
          .map(it => [+dayjs(it.Date).format("YYYY"), it.Withdrawals]),
        groupPadding: 0.05,
        name: "Withdrawals",
        pointPadding: 0.05,
        showInLegend: false,
        stack: name,
        visible: true,
      });

      productData?.annuities_isOn &&
        series.push({
          borderRadius: "6px",
          color: token.income_chart_with_types_color_annuities,
          data: listData
            .filter(it => it.Date.includes(searchDate))
            .map(it => [+dayjs(it.Date).format("YYYY"), it.Annuities]),
          groupPadding: 0.05,
          name: "Annuities",
          pointPadding: 0.05,
          showInLegend: false,
          stack: name,
          visible: true,
        });

      productData?.otherIncome_isOn &&
        series.push({
          borderRadius: "6px",
          color: token.income_chart_with_types_color_other_income,
          data: listData
            .filter(it => it.Date.includes(searchDate))
            .map(it => [+dayjs(it.Date).format("YYYY"), it.OtherIncome]),
          groupPadding: 0.05,
          name: "Other Income",
          pointPadding: 0.05,
          showInLegend: false,
          stack: name,
          visible: true,
        });

      productData?.socialSecurity_isOn &&
        series.push({
          borderRadius: "6px",
          color: token.income_chart_with_types_color_social_security,
          data: listData
            .filter(it => it.Date.includes(searchDate))
            .map(it => [+dayjs(it.Date).format("YYYY"), it.SocialSecurity]),
          groupPadding: 0.05,
          name: "Social Security",
          pointPadding: 0.05,
          showInLegend: false,
          stack: name,
          visible: true,
        });
    });
  };

  const incomeChartConfig = {
    chart: {
      backgroundColor: "transparent",
      borderWidth: 1,
      borderRadius: 8,
      borderColor: "transparent",
      events: {
        render: function () {
          chartRender(this, activeStressTestData);
        },
      },
      height:
        height ??
        (incomeChartType === "contributions" && productData?.contributions === 0
          ? 50
          : 200),
      spacingTop: 20,
      spacingRight: 25,
      spacingLeft: 20,
      styledMode: false,
      type: "column",
    },
    credits: {
      enabled: false,
    },
    dataLabels: {
      enabled: false,
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
    legend: {
      align: "right",
      enabled: incomeChartType === "income" || isStressorMode,
    },
    plotOptions: {
      column: {
        borderRadiusTopLeft: 16,
        borderRadiusTopRight: 16,
        stacking: "normal",
        maxPointWidth: 25,
      },
    },
    series: getSeries(),
    title: {
      text: "",
    },
    tooltip: {
      enabled: !isStressorMode,
      formatter: function () {
        return `${this.series.name}: ${getCurrencyValue(Math.round(this.y))}`;
      },
      outside: true,
    },
    xAxis: {
      reserveSpace: true,
      showFirstLabel: true,
      allowDecimals: false,
      lineColor: "transparent",
      tickWidth: 0,
      tickPixelInterval: 30,
      minRange: 1,
      labels: {
        step: 1,
        showFirstLabel: true,
        style: {
          fontSize: 14,
          fontFamily: "Roboto, sans-serif",
          color: "#516474",
        },
        formatter: data => `'${data.value.toString().slice(2)}`,
      },
    },
    yAxis: {
      opposite: true,
      lineWidth: 0,
      labels: {
        step: 1,
        formatter: data => getCurrencyValueWithRoundSign(data.value),
      },
      title: {
        text: null,
      },
    },
  };

  return (
    <ChartSkeleton active loading={loading} height={height}>
      <div style={{ position: "relative" }}>
        {!hideChartTypeButtons && (
          <ChartTypeButtonsContainer
            align="center"
            background={
              token.income_chart_with_types_navbar_container_background
            }
            className="chart-navbar-container"
          >
            <StyledButton
              activebackground={
                token.income_chart_with_types_navbar_button_background
              }
              activecolor={token.income_chart_with_types_navbar_button_color}
              className={incomeChartType === "contributions" && "active"}
              onClick={() => setIncomeChartType("contributions")}
              type="text"
            >
              Annual Contributions
            </StyledButton>
            <StyledButton
              activebackground={
                token.income_chart_with_types_navbar_button_background
              }
              activecolor={token.income_chart_with_types_navbar_button_color}
              className={incomeChartType === "income" && "active"}
              disabled={isGrowthTypeRiskTolerance(
                productData?.investmentObjective
              )}
              onClick={() => setIncomeChartType("income")}
              type="text"
            >
              Annual Income
            </StyledButton>
          </ChartTypeButtonsContainer>
        )}

        {incomeChartType === "contributions" &&
        productData?.contributions === 0 ? (
          <div style={{ paddingTop: 40 }}>
            <NoContributionsContainer align="center" justify="center">
              No contributions selected
            </NoContributionsContainer>
          </div>
        ) : (
          <HighchartsReact
            highcharts={Highcharts}
            options={incomeChartConfig}
            ref={chartRef}
          />
        )}
      </div>
    </ChartSkeleton>
  );
};

export default ChartIncomeWithTypesLevel;
