import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import State from "../../context/admin/State";
import { Button, Flex, Image } from "antd";
import ModalCompany from "./components/ModalCompany";
import ModalDeleteCompany from "./components/ModalDeleteCompany";
import ModalDeleteEvent from "./components/ModalDeleteEvent";
import ModalDeleteSponsor from "./components/ModalDeleteSponsor";
import ModalDeleteUser from "./components/ModalDeleteUser";
import ModalEvent from "./components/ModalEvent";
import ModalSponsor from "./components/ModalSponsor";
import ModalUser from "./components/ModalUser";
import ModalUserResetPassword from "./components/ModalUserResetPassword";
import TabsAdmin from "./components/TabsAdmin";

import { StyledContent, StyledLayout } from "./style";

import IdentityManager from "../../requests/aws/IdentityManager";

import logo from "../../images/logo.png";
import { ReactComponent as Slogan } from "../../images/slogan.svg";
import { ReactComponent as LogOutIcon } from "../../images/log_out_icon.svg";

const PortalAdmin = () => {
  const navigate = useNavigate();
  const [state] = useContext(State);

  useEffect(() => {
    IdentityManager.getSessionValidUser().then(console.log);
  }, []);

  const handleLogOut = () => {
    Cookies.remove("mmi-token");
    IdentityManager.logOut();
    navigate("/login-mmi");
  };

  return (
    <StyledLayout justify="center">
      <Flex gap={30} style={{ width: "100%", maxWidth: 2000 }} vertical>
        <Flex align="end" justify="space-between">
          <Flex align="end" gap={7}>
            <Image preview={false} src={logo} width={120} />
            <div style={{ marginBottom: 4 }}>
              <Slogan />
            </div>
          </Flex>
          <Button
            onClick={handleLogOut}
            shape="round"
            size="small"
            style={{ width: 118, background: "#FFFFFF", color: "#105DAE" }}
            type="text"
          >
            <Flex align="center" gap={8} justify="center">
              <span>Log out</span>
              <LogOutIcon />
            </Flex>
          </Button>
        </Flex>
        <StyledContent vertical>
          <TabsAdmin
            openModal={state.openModal}
            showWarning={state.showWarning}
          />
        </StyledContent>
      </Flex>

      <ModalCompany open={state.companyModal} />
      <ModalDeleteCompany open={state.deleteCompanyModal} />
      <ModalDeleteEvent open={state.deleteEventModal} />
      <ModalDeleteSponsor open={state.deleteSponsorModal} />
      <ModalDeleteUser open={state.deleteUserModal} />
      <ModalEvent open={state.eventModal} />
      <ModalSponsor open={state.sponsorModal} />
      <ModalUser open={state.userModal} />
      <ModalUserResetPassword open={state.userResetPasswordModal} />
    </StyledLayout>
  );
};

export default PortalAdmin;
