import styled from "styled-components";

const StyledContainer = styled.div`
  .ant-menu {
    display: flex;
    flex-direction: column;
    gap: 2px;

    &::before {
      display: none;
    }

    .ant-menu-item {
      margin: 2px 0;
      width: 100%;

      .ant-menu-title-content {
        align-items: center;
        display: flex;
        gap: 12px;
      }
    }
  }

  .container-sidebar-icon {
    display: flex;
    gap: 12px;
    justify-content: center;
    width: 22px;
  }
`;

export default StyledContainer;
