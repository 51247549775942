import { Flex } from "antd";
import ChartPersonality from "../../../page/PageProposalDashboard/components/ChartPersonality";
import ReportHeader from "../../component/ReportHeader";

import StylesContainer from "./style";

const ReportPagePersonalityCouple = ({ personaName, spouseName, userName }) => (
  <StylesContainer id="reportPagePersonalityCouple">
    <div className="page-container">
      <ReportHeader personaName={personaName} />

      <main>
        <Flex vertical>
          <h2>{personaName}</h2>
          <h4>Marriage Map Overview</h4>
          <p>
            Marriage is a high calling and an immeasurable gift. As individuals,
            our Perspectives have some natural tendencies, as well as some blind
            spots, when it comes to the Four Uses of Money. And when the two
            become one in marriage, our Perspectives on money can either be a
            force for division or unity. If we can learn to embrace
            interdependence over independence—leveraging and compounding upon
            one another's strengths while admitting where we'll need some
            help—we can truly live well and finish well. This is where the magic
            happens!
          </p>
        </Flex>
        <Flex gap={46} vertical>
          <Flex className="legend-container" gap={24} justify="space-between">
            <span>{userName}</span>
            <div className="marker" style={{ background: "#88D19D" }} />
          </Flex>

          <Flex className="legend-container" gap={24} justify="space-between">
            <span>{spouseName}</span>
            <div className="marker" style={{ background: "#4B82B1" }} />
          </Flex>
        </Flex>
      </main>

      <Flex align="center" className="chart-container" justify="center">
        <ChartPersonality height={800} hideLegend width={1000} />
      </Flex>
    </div>
  </StylesContainer>
);

export default ReportPagePersonalityCouple;
