import { Button, Flex } from "antd";

import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const ChartToggle = ({ activeView, setActiveView, views }) => (
  <Flex align="center" gap="2rem">
    <b
      style={{
        fontSize: 16,
        textTransform: "capitalize",
      }}
    >
      {activeView}
    </b>
    <Flex
      gap=".5rem"
      style={{
        background: "#e6f4ef",
        padding: ".25rem",
        borderRadius: "40px",
      }}
    >
      <Button
        icon={<LeftOutlined />}
        onClick={() => {
          const currentIndex = views.indexOf(activeView);
          const prevIndex = (currentIndex - 1 + views.length) % views.length;
          setActiveView(views[prevIndex]);
        }}
        shape="circle"
        size="small"
        style={{
          background: "#fff",
          width: 32,
        }}
        type="text"
      />
      <Button
        icon={<RightOutlined />}
        onClick={() => {
          const currentIndex = views.indexOf(activeView);
          const nextIndex = (currentIndex + 1) % views.length;
          setActiveView(views[nextIndex]);
        }}
        shape="circle"
        size="small"
        style={{
          background: "#fff",
          width: 32,
        }}
        type="text"
      />
    </Flex>
  </Flex>
);

export default ChartToggle;
