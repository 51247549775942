import { Flex, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProposalGoalPreviewGrowth from "../../../ProposalGoalPreviewGrowth";
import ProposalGoalPreviewIncome from "../../../ProposalGoalPreviewIncome";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const SectionReviewProposal = ({
  handleClose,
  handleDownloadProposal,
  productLiveAssessment,
  productsLiveSimulation,
  proposalData,
  selectedProductData,
}) => {
  const isGrowthTypeProposal = proposalData?.investmentObjective === "growth";

  const Footer = () => (
    <Flex justify="space-between">
      <Button
        icon={<FontAwesomeIcon icon={faArrowLeft} />}
        onClick={handleClose}
        shape="round"
      >
        Back to Product Selection
      </Button>
      <Button onClick={handleDownloadProposal} shape="round">
        Send for signature
      </Button>
    </Flex>
  );

  if (isGrowthTypeProposal)
    // growth objective
    return (
      <ProposalGoalPreviewGrowth
        Footer={Footer}
        productsLiveSimulation={productsLiveSimulation}
        proposalData={proposalData}
        productData={selectedProductData}
      />
    );

  // income or income & growth objective
  return (
    <ProposalGoalPreviewIncome
      Footer={Footer}
      productLiveAssessment={productLiveAssessment}
      productsLiveSimulation={productsLiveSimulation}
      proposalData={proposalData}
      productData={selectedProductData}
    />
  );
};

export default SectionReviewProposal;
