import ReportHeader from "../../component/ReportHeader";

import StylesContainer from "./style";

const ReportPageProposalDisclosure = ({ personaName }) => (
  <StylesContainer id="reportPageProposalDisclosure">
    <div className="page-container">
      <ReportHeader personaName={personaName} />

      <main>
        <h4>Level Investments Disclosure</h4>

        <p>
          Investing involves risks, including the possible loss of principal.
          Any investment decision should be made based on an investor’s
          objectives, risk tolerance, and financial situation. The past
          performance of any security, strategy, or index is not indicative of
          future results. Level Investments makes no guarantees or
          representations as to the future performance of any investment. Market
          conditions, economic factors, and investment objectives can
          significantly impact the results of any strategy.
        </p>
        <p>
          All investment strategies, including asset allocation and
          diversification, do not ensure a profit or guarantee against losses in
          declining markets. Investors should be aware that investments in
          securities, including equities, fixed income, and alternatives,
          involve varying degrees of risk, and fluctuations in the market may
          cause the value of your investments to decline.
        </p>
        <p>
          Before investing, clients should carefully consider the information
          provided in the relevant offering documents, such as prospectuses or
          investment management agreements. Level Investments and its advisors
          do not provide legal, tax, or accounting advice. Clients should
          consult their own legal or tax professionals for advice tailored to
          their specific circumstances.
        </p>
        <p>
          The information provided by Level Investments is for informational
          purposes only and should not be construed as an offer to buy or sell
          any securities. All financial projections, analyses, or
          recommendations are subject to change based on market conditions and
          individual client needs.
        </p>
        <p>
          Securities and investment advisory services offered by Level
          Investments are registered with the appropriate regulatory
          authorities. Please refer to our Form ADV and other regulatory filings
          for more detailed information about the services we provide.
        </p>
      </main>
    </div>
  </StylesContainer>
);

export default ReportPageProposalDisclosure;
