import styled from "styled-components";

const StyledContainer = styled.div`
  section {
    g.highcharts-series path {
      stroke-linecap: round;
    }
  }

  footer {
    background: #f1f5f6;
    border-radius: 12px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    padding: 24px;
    overflow: auto;

    h4 {
      color: #4b4747;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 105%;
      margin: unset;
    }

    .ant-tabs {
      width: 100%;

      .ant-tabs-content-holder {
        background: #fff;
        border-radius: 12px;
        padding: 16px;
      }

      .ant-tabs-tab {
        padding-top: 0;
      }

      .ant-tabs-tab-btn {
        color: #516474;
      }

      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #052c2b;
      }

      .ant-tabs-tab + .ant-tabs-tab {
        margin-left: 24px;
      }
    }

    .ant-tabs-top > .ant-tabs-nav {
      margin: unset;
      padding-left: 16px;

      &::before {
        display: none;
      }

      .ant-tabs-ink-bar {
        background: #052c2b;
        border-radius: 4px 4px 0 0;
        height: 6px;
      }
    }

    .card-portfolio-title {
      display: none;
    }

    .tabs-hidden-navbar .ant-tabs-nav {
      display: none;
    }
  }

  .container-hero-section.active {
    background: #fbfcfc;
    border: 1px solid #d3d3d3;
    border-radius: 12px;
    box-shadow: 0 34px 32px 0 rgba(0, 0, 0, 0.24);

    header {
      padding: 16px 24px;

      h5 {
        color: #4b4747;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 105%;
        margin: unset;
      }
    }

    section {
      .container-chart {
        padding: 0 24px;
      }

      .menu-view-mode {
        display: none;
      }
    }

    footer {
      border-radius: 0 0 12px 12px;

      .header {
        display: none;
      }

      .card-portfolio-title {
        color: #4b4747;
        display: block;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 105%;
        margin: 4px 0 12px;
      }
    }
  }
`;

export default StyledContainer;
