import { useEffect, useState } from "react";
import {
  Button,
  ConfigProvider,
  Flex,
  Form,
  InputNumber,
  Select,
  theme,
} from "antd";

import StyledContainer from "./style";

import {
  PROPOSAL_GOAL_TYPE_LEVEL,
  RISK_TYPES_LEVEL,
} from "../../../../../../../../constants";
import {
  PROPOSAL_GOAL_TYPE_RIVERSHARES,
  RISK_TYPES_RIVERSHARES,
} from "../../../../../../../../../rivershares/constants";
import { RISK_TYPES_ASTOR } from "../../../../../../../../../astor/constants";

import {
  cleanCurrencyValue,
  cleanPercentValue,
  getCurrencyValue,
  getPercentValue,
  selectValue,
} from "../../../../../../../../../../utils/helper/general";
import {
  isGrowthTypeRiskTolerance,
  isLockedByAdminView,
  isOrganizationAstor,
  isOrganizationGoals,
  isOrganizationRivershares,
} from "../../../../../../../../../../utils/helper/specialized";

const InvestmentAssumptions = ({
  handleProposalDataChange,
  loading,
  managerAccess,
  openAdvancedSettings,
  organization,
  proposalData,
}) => {
  const [form] = Form.useForm();
  const { token } = theme.useToken();
  const [isWithdrawalLevelPercentType, setIsWithdrawalLevelPercentType] =
    useState(false);

  useEffect(() => {
    if (form && proposalData) {
      if (isNaN(proposalData.withdrawalLevel)) {
        setIsWithdrawalLevelPercentType(true);
      }

      form.setFieldsValue({
        ...proposalData,
        contributions: proposalData.contributions / 12,
        withdrawalLevel: cleanPercentValue(proposalData.withdrawalLevel),
      });
    }
  }, [proposalData]);

  const getOptionsObjective = () => {
    if (isOrganizationRivershares(organization))
      return PROPOSAL_GOAL_TYPE_RIVERSHARES;
    else return PROPOSAL_GOAL_TYPE_LEVEL;
  };

  const getOptionsRiskType = () => {
    if (isOrganizationRivershares(organization)) return RISK_TYPES_RIVERSHARES;
    else if (isOrganizationAstor(organization)) return RISK_TYPES_ASTOR;
    else return RISK_TYPES_LEVEL;
  };

  const handleInitialInvestmentChange = e => {
    const cleanValue = cleanCurrencyValue(e.target?.value);
    const validatedValue =
      cleanValue > 10000000 ? 10000000 : cleanValue < 0 ? 0 : cleanValue;

    if (!isNaN(validatedValue)) {
      handleProposalDataChange({
        ...proposalData,
        investmentAmount: +validatedValue,
      });

      e.target.value = getCurrencyValue(validatedValue);
    }
  };

  const handleFormChange = (_, allValues) => {
    handleProposalDataChange({
      ...allValues,
      contributions: allValues.contributions * 12,
      withdrawalLevel: isWithdrawalLevelPercentType
        ? getPercentValue(allValues.withdrawalLevel)
        : allValues.withdrawalLevel,
    });
  };

  return (
    <StyledContainer>
      <ConfigProvider
        theme={{
          components: {
            Select: {
              colorBgContainer: "#ebf1f3",
              colorBorder: "#ebf1f3",
              colorText: "#354a5f",
              optionSelectedBg: "#ebf1f3",
            },
          },
        }}
      >
        <Form
          disabled={loading}
          form={form}
          layout="vertical"
          onValuesChange={handleFormChange}
        >
          <h4>Plan Settings</h4>

          <Flex gap={24} vertical>
            <Form.Item
              hidden={isOrganizationGoals(organization)}
              label="Objective"
              name="investmentObjective"
            >
              <Select options={getOptionsObjective()} />
            </Form.Item>

            <Flex gap={16} vertical>
              <Form.Item
                hidden={!isOrganizationAstor(organization)}
                label="Portfolio Template"
                name="riskTolerance"
              >
                <Select
                  options={[
                    {
                      label: "20/80 Income",
                      value: "income",
                    },
                    {
                      label: "40/60 Conservative",
                      value: "conservative",
                    },
                    {
                      label: "60/40 Moderate",
                      value: "moderate",
                    },
                    {
                      label: "80/20 Aggressive",
                      value: "aggressive",
                    },
                    {
                      label: "100 Equity",
                      value: "equity",
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                hidden={!isOrganizationAstor(organization)}
                label="Product Allocation"
                name="allocation"
              >
                <Select
                  options={[
                    {
                      label: "100%",
                      value: 100,
                    },
                    {
                      label: "90%",
                      value: 90,
                    },
                    {
                      label: "80%",
                      value: 80,
                    },
                    {
                      label: "70%",
                      value: 70,
                    },
                    {
                      label: "60%",
                      value: 60,
                    },
                    {
                      label: "50%",
                      value: 50,
                    },
                    {
                      label: "40%",
                      value: 40,
                    },
                    {
                      label: "30%",
                      value: 30,
                    },
                    {
                      label: "20%",
                      value: 20,
                    },
                    {
                      label: "10%",
                      value: 10,
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label={token.proposal_copy_investment_amount}
                name="investmentAmount"
              >
                <InputNumber
                  step={1000}
                  min={0}
                  max={10000000}
                  style={{ width: "100%" }}
                  formatter={getCurrencyValue}
                  parser={cleanCurrencyValue}
                  onClick={selectValue}
                  onKeyUp={handleInitialInvestmentChange}
                />
              </Form.Item>
              <Form.Item label="Length of Investment" name="investmentDuration">
                <InputNumber
                  addonAfter={
                    <Form.Item style={{ marginBottom: 0 }}>
                      <Select
                        options={[
                          {
                            label: "Years",
                            value: "years",
                          },
                          {
                            disabled: true,
                            label: "Quarters",
                            value: "quarters",
                          },
                          {
                            disabled: true,
                            label: "Months",
                            value: "months",
                          },
                        ]}
                        style={{ width: 106 }}
                        value="years"
                      />
                    </Form.Item>
                  }
                  max={80}
                  min={10}
                  onClick={selectValue}
                />
              </Form.Item>
              <Form.Item
                extra={
                  <Flex justify="end">
                    <a
                      onClick={() =>
                        setIsWithdrawalLevelPercentType(lastState => !lastState)
                      }
                      style={{ fontSize: 12 }}
                    >
                      Switch to {isWithdrawalLevelPercentType ? "$" : "%"}
                    </a>
                  </Flex>
                }
                hidden={isGrowthTypeRiskTolerance(
                  proposalData?.investmentObjective
                )}
                label="Annual Income Target"
                name="withdrawalLevel"
              >
                <InputNumber
                  min={0}
                  max={isWithdrawalLevelPercentType ? 100 : 10000000}
                  formatter={value =>
                    isWithdrawalLevelPercentType
                      ? getPercentValue(value)
                      : getCurrencyValue(value)
                  }
                  parser={value =>
                    isWithdrawalLevelPercentType
                      ? cleanPercentValue(value)
                      : cleanCurrencyValue(value)
                  }
                  style={{ width: "100%" }}
                  onClick={selectValue}
                />
              </Form.Item>
              <Form.Item
                hidden={isGrowthTypeRiskTolerance(
                  proposalData?.investmentObjective
                )}
                label="Begin Withdrawal after"
                name="yearToStartWithdrawals"
              >
                <InputNumber
                  min={1}
                  max={80}
                  formatter={value => (value ? `${value} years` : "")}
                  parser={value => value?.replace(" years", "")}
                  style={{ width: "100%" }}
                  onClick={selectValue}
                />
              </Form.Item>
            </Flex>

            <Form.Item
              hidden={
                !isGrowthTypeRiskTolerance(proposalData?.investmentObjective)
              }
              label="Target Amount"
              name="targetAmount"
            >
              <InputNumber
                step={1000}
                min={0}
                max={10000000}
                style={{ width: "100%" }}
                formatter={getCurrencyValue}
                parser={cleanCurrencyValue}
                onClick={selectValue}
              />
            </Form.Item>

            <Form.Item label="Contributions" name="contributions">
              <InputNumber
                addonAfter={
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Select
                      options={[
                        {
                          label: "monthly",
                          value: "monthly",
                        },
                      ]}
                      style={{ width: 106 }}
                      value="monthly"
                    />
                  </Form.Item>
                }
                step={1000}
                min={0}
                max={10000000}
                style={{ width: "100%" }}
                formatter={getCurrencyValue}
                parser={cleanCurrencyValue}
                onClick={selectValue}
              />
            </Form.Item>

            <Form.Item label="Risk Tolerance" name="riskTolerance">
              <Select options={getOptionsRiskType()} />
            </Form.Item>

            <Button
              block
              disabled={isLockedByAdminView({ managerAccess })}
              onClick={openAdvancedSettings}
              shape="round"
            >
              Advanced Settings
            </Button>
          </Flex>
        </Form>
      </ConfigProvider>
    </StyledContainer>
  );
};

export default InvestmentAssumptions;
